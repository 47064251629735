<div class="w-100 h-100 p-2 overflow-hidden d-flex flex-column">
  <div class="item-metadata scrollbar overflow-auto">
    <app-metadata-inputs
      *ngIf="!redrawing"
      [library]="library"
      [value]="initialValue"
      [edit]="true"
      [disabled]="formDisabled"
      (valueChanges)="metadataChanges($event)"
      (formValid)="updateFormValid($event)"
    ></app-metadata-inputs>
  </div>
  <div class="d-flex flex-row justify-content-end mt-1 mb-1 text-uppercase">
    <button
      mat-raised-button
      color="primary"
      (click)="updateItem()"
      class="me-2"
      *ngIf="{ formValid: formValid | async } as data"
      [disabled]="!dirty || formDisabled || updating || !data.formValid"
    >
      {{ getTranslation$("update") | async }}
    </button>
    <button
      mat-raised-button
      color="warn"
      (click)="deleteItem()"
      [disabled]="!canDelete || updating"
    >
      {{ getTranslation$("delete") | async }}
    </button>
  </div>

  <div
    *ngIf="previewData"
    class="p-1 border w-100 h-100 d-flex justify-content-center align-items-center min-height-0"
    #previewDiv
  >
    <ng-container [ngSwitch]="previewType">
      <ng-container *ngSwitchCase="PreviewTypes.Iframe">
        <iframe
          [src]="previewData"
          frameborder="0"
          class="w-100 h-100"
          role="presentation"
        ></iframe>
      </ng-container>
      <ng-container *ngSwitchCase="PreviewTypes.Image">
        <img
          [src]="previewData"
          alt="Image preview"
          class="p-1"
          [style.max-width.px]="previewDiv.clientWidth"
          [style.max-height.px]="previewDiv.clientHeight"
        />
      </ng-container>
      <ng-container *ngSwitchCase="PreviewTypes.Video">
        <video #videoPlayer class="w-100 h-100" controls>
          <source [src]="previewData" />
        </video>
      </ng-container>
      <ng-container *ngSwitchCase="PreviewTypes.Audio">
        <audio controls>
          <source [src]="previewData" />
        </audio>
      </ng-container>
      <ng-container *ngSwitchCase="PreviewTypes.Email">
        <div
          class="w-100 h-100 d-flex flex-column justify-content-center align-items-center overflow-auto scrollbar horizontal-scrollbar"
          [innerHTML]="previewData"
        ></div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ getTranslation$("previewUnavailable") | async }}
      </ng-container>
    </ng-container>
  </div>

  <div class="d-flex flex-column pt-2" *ngIf="item?.linkedItems?.length">
    <h5>{{ getTranslation$("linkedItems") | async }}</h5>
    <mat-action-list>
      <mat-list-item *ngFor="let i of item.linkedItems">
        {{ i.linkedItem?.title }}
      </mat-list-item>
    </mat-action-list>
  </div>
</div>
