import { BaseEntity, GroupUser } from 'processdelight-angular-components';

export class MetadataPermission extends BaseEntity {
  name?: string;
  groupUser!: GroupUser;
  metadataParameterId!: string;
  subValue?: string;
  isInheritanceEnabled?: boolean;

  constructor(obj: Partial<MetadataPermission>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.groupUser) this.groupUser = new GroupUser(obj.groupUser);
  }
}
