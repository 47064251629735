import { createAction, props } from '@ngrx/store';
import { MetadataChoiceTranslation } from '../../domain/models/metadata-choice-translation.model';
import { MetadataChoice } from '../../domain/models/metadata-choice.model';
import { MetadataParam } from '../../domain/models/metadata-param.model';
import { MetadataPermissionConfiguration } from '../../domain/models/metadata-permission-configuration.model';
import { ActionBase } from '../action-base.interface';
import { DMSWebpartPermission } from '../../domain/models/dms-webpart-permission.model';
import { DMSWebpartInterestGroup } from '../../domain/models/dms-webpart-interestgroup.model';
import { InterestGroup } from '../../domain/models/interest-group.model';

export const getMetadataParams = createAction(
  '[Metadata] Get metadata parameters',
  props<ActionBase<MetadataParam[]>>()
);
export const getMetadataParamsResolved = createAction(
  '[Metadata] Get metadata parameters resolved',
  props<{
    metadataParams: MetadataParam[];
  }>()
);
export const getAdminMetadataParams = createAction(
  '[Metadata] Get admin metadata parameters',
  props<ActionBase<MetadataParam[]>>()
);
export const getAdminMetadataParamsResolved = createAction(
  '[Metadata] Get admin metadata parameters resolved',
  props<{
    metadataParams: MetadataParam[];
  }>()
);
export const createMetadataParam = createAction(
  '[Metadata] Create metadata parameter',
  props<
    {
      metadataParam: MetadataParam;
    } & ActionBase<MetadataParam>
  >()
);
export const createMetadataParamResolved = createAction(
  '[Metadata] Create metadata parameter resolved',
  props<{
    metadataParam: MetadataParam;
  }>()
);
export const createMetadataParamsResolved = createAction(
  '[Metadata] Create metadata parameters resolved',
  props<{
    metadataParams: MetadataParam[];
  }>()
);
export const updateMetadataParam = createAction(
  '[Metadata] Update metadata parameter',
  props<
    {
      metadataParam: MetadataParam;
    } & ActionBase<MetadataParam>
  >()
);
export const updateMetadataParamResolved = createAction(
  '[Metadata] Update metadata parameter resolved',
  props<{
    metadataParams: MetadataParam[];
  }>()
);
export const deleteMetadataParam = createAction(
  '[Metadata] Delete metadata parameter',
  props<
    {
      metadataParamId: string;
    } & ActionBase<string>
  >()
);
export const deleteMetadataParamResolved = createAction(
  '[Metadata] Delete metadata parameter resolved',
  props<{
    metadataParamIds: string[];
  }>()
);
export const createMetadataChoice = createAction(
  '[Metadata] Create metadata choice',
  props<
    {
      metadataChoice: MetadataChoice;
    } & ActionBase<MetadataChoice>
  >()
);
export const createMetadataChoiceResolved = createAction(
  '[Metadata] Create metadata choice resolved',
  props<{
    metadataChoice: MetadataChoice;
  }>()
);
export const updateMetadataChoice = createAction(
  '[Metadata] Update metadata choice',
  props<
    {
      metadataChoice: MetadataChoice;
    } & ActionBase<MetadataChoice>
  >()
);
export const updateMetadataChoiceResolved = createAction(
  '[Metadata] Update metadata choice resolved',
  props<{
    metadataChoice: MetadataChoice;
  }>()
);
export const updateMetadataChoices = createAction(
  '[Metadata] Update metadata choices',
  props<
    {
      metadataChoices: MetadataChoice[];
    } & ActionBase<MetadataChoice[]>
  >()
);
export const updateMetadataChoicesResolved = createAction(
  '[Metadata] Update metadata choices resolved',
  props<{
    metadataChoices: MetadataChoice[];
  }>()
);
export const createMetadataChoiceTranslations = createAction(
  '[Metadata] Create metadata choice translations',
  props<
    {
      paramId: string;
      translations: MetadataChoiceTranslation[];
    } & ActionBase<MetadataChoiceTranslation[]>
  >()
);
export const createMetadataChoiceTranslationsResolved = createAction(
  '[Metadata] Create metadata choice translations resolved',
  props<{
    paramId: string;
    translations: MetadataChoiceTranslation[];
  }>()
);
export const deleteMetadataChoice = createAction(
  '[Metadata] Delete metadata choice',
  props<
    {
      metadataParamId: string;
      metadataChoiceId: string;
    } & ActionBase<string>
  >()
);
export const deleteMetadataChoiceResolved = createAction(
  '[Metadata] Delete metadata choice resolved',
  props<{
    metadataParamId: string;
    metadataChoiceId: string;
  }>()
);

export const getMetadataPermissionConfigurations = createAction(
  '[Metadata] Get metadata permission configurations',
  props<ActionBase<MetadataPermissionConfiguration[]>>()
);
export const getMetadataPermissionConfigurationsResolved = createAction(
  '[Metadata] Get metadata permission configurations resolved',
  props<{
    configurations: MetadataPermissionConfiguration[];
  }>()
);

export const updateMetadataPermissionConfiguration = createAction(
  '[Metadata] Update metadata permission configuration',
  props<
    {
      configuration: MetadataPermissionConfiguration;
    } & ActionBase<MetadataPermissionConfiguration>
  >()
);
export const updateMetadataPermissionConfigurationResolved = createAction(
  '[Metadata] Update metadata permission configuration resolved',
  props<{
    configuration: MetadataPermissionConfiguration;
  }>()
);

export const updateMetadataView = createAction(
  '[Metadata] Update metadata view',
  props<{
    configuration: MetadataPermissionConfiguration;
    hasAccess: boolean;
  }>()
);

export const getDMSWebpartPermissions = createAction(
  '[Metadata] Get DMS webpart permissions',
  props<ActionBase<DMSWebpartPermission[]>>()
);
export const getDMSWebpartPermissionsResolved = createAction(
  '[Metadata] Get DMS webpart permissions resolved',
  props<{
    permissions: DMSWebpartPermission[];
  }>()
);

export const createDMSWebpartPermissions = createAction(
  '[Metadata] Create DMS webpart permissions',
  props<
    {
      permissions: DMSWebpartPermission[];
    } & ActionBase<DMSWebpartPermission[]>
  >()
);
export const createDMSWebpartPermissionsResolved = createAction(
  '[Metadata] Create DMS webpart permissions resolved',
  props<{
    permissions: DMSWebpartPermission[];
  }>()
);
export const deleteDMSWebpartPermissions = createAction(
  '[Metadata] Delete DMS webpart permissions',
  props<
    {
      permissionIds: string[];
    } & ActionBase<string[]>
  >()
);
export const deleteDMSWebpartPermissionsResolved = createAction(
  '[Metadata] Delete DMS webpart permissions resolved',
  props<{
    permissionIds: string[];
  }>()
);
export const getDMSWebpartInterestGroups = createAction(
  '[Metadata] Get DMS webpart interest groups',
  props<ActionBase<DMSWebpartInterestGroup[]>>()
);
export const getDMSWebpartInterestGroupsResolved = createAction(
  '[Metadata] Get DMS webpart interest groups resolved',
  props<{
    interestGroups: DMSWebpartInterestGroup[];
  }>()
);
export const createDMSWebpartInterestGroups = createAction(
  '[Metadata] Create DMS webpart interest groups',
  props<
    {
      interestGroups: DMSWebpartInterestGroup[];
    } & ActionBase<DMSWebpartInterestGroup[]>
  >()
);
export const createDMSWebpartInterestGroupsResolved = createAction(
  '[Metadata] Create DMS webpart interest groups resolved',
  props<{
    interestGroups: DMSWebpartInterestGroup[];
  }>()
);
export const deleteDMSWebpartInterestGroups = createAction(
  '[Metadata] Delete DMS webpart interest groups',
  props<
    {
      interestGroupIds: string[];
    } & ActionBase<string[]>
  >()
);
export const deleteDMSWebpartInterestGroupsResolved = createAction(
  '[Metadata] Delete DMS webpart interest groups resolved',
  props<{
    interestGroupIds: string[];
  }>()
);

export const resetSlice = createAction('[Metadata] Reset slice');
