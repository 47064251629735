import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { MetadataParam } from '../../domain/models/metadata-param.model';

const metadataState = (state: AppState) => state.coreFeature.metadata;
const getMetadataParams = createSelector(
  metadataState,
  (state) => state.metadata
);
const getAdminMetadataParams = createSelector(
  metadataState,
  (state) => state.adminMetadata
);
const getFixedParams = createSelector(
  getMetadataParams,
  (params) =>
    [
      params.find((p) => p.fileNameParam),
      params.find((p) => p.modifiedOnParam),
      params.find((p) => p.modifiedByParam),
      params.find((p) => p.createdOnParam),
      params.find((p) => p.createdByParam),
    ].filter((p) => !!p) as MetadataParam[]
);
const getFileNameParam = createSelector(getMetadataParams, (params) =>
  params.find((p) => p.fileNameParam)
);

const getMetadataPermissionConfigurationsByParamId = createSelector(
  metadataState,
  (state) => (paramId: string) =>
    state.permissionConfigurations.filter(
      (p) => p.metadataParameterId === paramId
    )
);

const permissionCheckFn = createSelector(
  metadataState,
  (state) => (userId: string, paramId: string, choiceId?: string) => {
    const permissionConfig = state.permissionConfigurations.find(
      (p) => p.metadataParameterId === paramId && p.subValue === choiceId
    );
    if (!permissionConfig) {
      return true;
    }
    if (!permissionConfig.isPermissionsEnabled) {
      return true;
    }
    return permissionConfig.permissions.some((p) =>
      p.metadataPermission.subValue === choiceId &&
      p.metadataPermission.groupUser.group
        ? p.metadataPermission.groupUser.group.members?.some(
            (m) => m.id == userId
          )
        : p.metadataPermission.groupUser.user?.id == userId
    );
  }
);

const getDMSWebpartPermissions = createSelector(
  metadataState,
  (state) => state.dmsWebpartPermissions
);
const getDMSWebpartInterestGroups = createSelector(
  metadataState,
  (state) => state.dmsWebpartInterestGroups
);
export const metadataSelectors = {
  getMetadataParams,
  getAdminMetadataParams,
  getFixedParams,
  getFileNameParam,
  getMetadataPermissionConfigurationsByParamId,
  permissionCheckFn,
  getDMSWebpartPermissions,
  getDMSWebpartInterestGroups,
};
