export class AppConfig {
  lineSize = 10;
  onlyGroups = false;
  useOldFilter = false;
  sharing = false;
  externalSharing = false;
  sharingExpirationRequired = false;
  sharingExpirationDefault = 30;
  librarySharingOverrides: {
    [key: string]: {
      sharing: boolean;
      externalSharing: boolean;
      sharingExpirationRequired: boolean;
      sharingExpirationDefault: number;
    };
  } = {};

  constructor(obj: Partial<AppConfig>) {
    Object.assign(this, obj);
  }
}
