<div #container class="wrapper scrollbar">
  <div
    class="d-flex flex-column"
    *ngFor="let segment of segments; trackBy: entityTrackBy"
  >
    <div
      *ngIf="!segment.hideTitle"
      class="d-flex justify-content-center align-items-center mt-2"
    >
      {{ segment.title }}
    </div>
    <div class="d-flex flex-row justify-content-start flex-nowrap mt-2">
      <div
        class="w-{{ config.lineSize }} mb-2"
        *ngFor="let tile of getTiles(segment); trackBy: entityTrackBy"
      >
        <app-tile
          [tile]="tile"
          [size]="calcTileSize(container.clientWidth)"
        ></app-tile>
      </div>
    </div>
  </div>
</div>
