import { Lookup } from 'processdelight-angular-components';
import { BaseEntity } from 'processdelight-angular-components';
import { Library } from './library.model';

export class LibraryTrigger extends BaseEntity {
  library!: Library;
  template!: Lookup;

  constructor(data: Partial<LibraryTrigger>) {
    super(data);
    Object.assign(this, data);
    if (data.library) this.library = new Library(data.library);
  }
}
