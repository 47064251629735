import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';

const notificationState = (state: AppState) => state.coreFeature.notification;
const getNotifications = createSelector(
  notificationState,
  (state) => state.notifications
);

export const notificationSelectors = {
  getNotifications,
};
