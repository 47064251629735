import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import {
  Msg,
  PidTagAttachExtension,
  PidTagAttachLongFilename,
  PidTagAttachmentHidden,
} from 'msg-parser';
import PostalMime, { Email } from 'postal-mime';
import {
  BreadCrumbService,
  BreadcrumbItem,
  GroupUser,
  MicrosoftAuthenticationService,
  SidePaneComponent,
} from 'processdelight-angular-components';
import {
  Observable,
  Subject,
  combineLatest,
  defaultIfEmpty,
  first,
  forkJoin,
  from,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { AdaptableInputComponent } from '../core/components/metadata-inputs/adaptable-input/adaptable-input.component';
import { MetadataInputsComponent } from '../core/components/metadata-inputs/metadata-inputs.component';
import { CoreModule } from '../core/core.module';
import { config$, translations$ } from '../core/data/data.observables';
import { ColumnType } from '../core/domain/enums/column-type.enum';
import { LibraryParamConfig } from '../core/domain/models/library-param-config.model';
import { Library } from '../core/domain/models/library.model';
import { MetadataFilter } from '../core/domain/models/metadata-filter.model';
import { MetadataParam } from '../core/domain/models/metadata-param.model';
import { Permission } from '../core/domain/models/permission.model';
import { TilePage } from '../core/domain/models/tile-page.model';
import {
  DateStartEnd,
  MetadataFilterType,
  dateStartEndTypeGuard,
} from '../core/helper/metadata.functions';
import { LibraryFacade } from '../core/store/library/library.facade';
import { MetadataFacade } from '../core/store/metadata/metadata.facade';
import { TilePageFacade } from '../core/store/tilepage/tilepage.facade';
import {
  DropZoneComponent,
  DropZoneData,
} from './library/drop-zone/drop-zone.component';
import { ItemPreviewComponent } from './library/item-preview/item-preview.component';
import { LibraryComponent } from './library/library.component';
import { LibraryTileActionService } from './tile-page/services/library-tile-action.service';
import TilePageComponent from './tile-page/tile-page.component';

@Component({
  selector: 'app-page',
  standalone: true,
  imports: [
    CoreModule,
    TilePageComponent,
    MetadataInputsComponent,
    LibraryComponent,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    ItemPreviewComponent,
    MatSnackBarModule,
    AdaptableInputComponent,
    ReactiveFormsModule,
    FormsModule,
    MatButtonToggleModule,
    SidePaneComponent,
  ],
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit, OnDestroy {
  page?: TilePage;
  library?: Library;

  selectedView?: string;

  filterValue: { [key: string]: any } = {};

  @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;
  @ViewChild('filterValueElement') filterValueElement?: AdaptableInputComponent;
  @ViewChild(LibraryComponent) libraryComponent?: LibraryComponent;
  destroy$ = new Subject<void>();

  filtering = false;

  metadataParams$ = this.metadataFacade.metadataParams$.pipe(
    map((params) => [...params].sort((a, b) => a.title.localeCompare(b.title)))
  );

  paramTrackBy = (_i: number, param: MetadataParam) => param.id;
  filterTrackBy = (_i: number, param: { paramId: string }) => param.paramId;

  filterParamControl = new FormControl<MetadataParam | undefined>(undefined);
  filterValueControl = new FormControl<MetadataFilterType | undefined>(
    undefined,
    Validators.required
  );

  dockRight = JSON.parse(localStorage.getItem('dockRight') || 'false');

  get filterValueArray() {
    return Object.entries(this.filterValue).map(([key, value]) => ({
      paramId: key,
      value,
    }));
  }

  get bodyWidth() {
    return document.body.clientWidth;
  }

  permissions: Permission[] = [];

  get canUpload() {
    return (
      !this.permissions.length ||
      this.permissions.some(
        (p) =>
          (p.groupUser.user?.id == this.msal.userId ||
            p.groupUser.group?.members?.some(
              (m) => m.id == this.msal.userId
            )) &&
          p.uploadPermission
      )
    );
  }

  useOldFilter = false;

  oldFilterValue: { [key: string]: any } = {};

  oldFilterMaxHeight = 196;

  get oldFiltering() {
    return (
      this.useOldFilter &&
      (!!this.library ||
        Object.entries(this.filterValue).filter(([_, value]) => !!value)
          .length > 0)
    );
  }

  appLibrary = false;

  constructor(
    private metadataFacade: MetadataFacade,
    private tilePageFacade: TilePageFacade,
    private libraryFacade: LibraryFacade,
    private route: ActivatedRoute,
    private libraryTileActionService: LibraryTileActionService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private msal: MicrosoftAuthenticationService,
    private router: Router,
    private breadcrumbService: BreadCrumbService
  ) {}

  getTranslation(label: string): string {
    return translations$.value[label];
  }
  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  ngOnInit(): void {
    this.selectedView = 'tileView';

    combineLatest([this.route.data, this.route.queryParamMap])
      .pipe(
        takeUntil(this.destroy$),
        tap(([data, params]) => {
          if (params.has('tileId')) {
            const tileId = params.get('tileId')!;
            this.filterValue = data.filters
              ? Object.fromEntries(
                  data.filters[tileId]?.map((f: MetadataFilter) => [
                    f.metadataParameter.id,
                    f.value,
                  ]) ?? []
                )
              : {};
            this.oldFilterValue = this.filterValue;
            this.filtering = true;
            this.selectedView = 'documentView';
          } else if (!data.breadcrumbParent) {
            this.resetBreadcrumb();
            this.filterValue = {};
            this.oldFilterValue = this.filterValue;
            this.filtering = false;
            this.selectedView = 'tileView';
          }
          if (data.breadcrumbParent) {
            const routes = [];
            let parent = data.breadcrumbParent;
            while (parent?.data.breadcrumbParent) {
              routes.unshift(parent);
              parent = parent.data.breadcrumbParent;
            }
            this.resetBreadcrumb();
            routes.forEach((route) => {
              this.breadcrumbService.addBreadcrumb(
                new BreadcrumbItem({
                  label: route.data.breadcrumbTitle,
                  action: () => this.router.navigate([route.path]),
                })
              );
            });
            this.breadcrumbService.addBreadcrumb(
              new BreadcrumbItem({
                label: data.breadcrumbTitle,
                action: () =>
                  this.router.navigate([this.route.snapshot.routeConfig!.path]),
              })
            );
          }
        }),
        switchMap(([data]) =>
          data.tilePageId
            ? this.tilePageFacade.getTilePageByIdFunc$.pipe(
                takeUntil(this.destroy$),
                map((fn) => fn(data.tilePageId)),
                tap((page) => {
                  this.page = page;
                  this.tilePageFacade.homePage$.pipe(first()).subscribe((p) => {
                    this.permissions =
                      p?.permissions.map((p) => p.permission) ?? [];
                  });
                })
              )
            : this.libraryFacade.libraryById$(data.libraryId).pipe(
                takeUntil(this.destroy$),
                tap((library) => {
                  this.library = library
                    ? new Library({
                        ...library,
                        configuredParams: [...library.configuredParams]
                          .sort((a, b) => a.position - b.position)
                          .map(
                            (p) =>
                              new LibraryParamConfig({
                                ...p,
                                choices: [...p.choices],
                              })
                          ),
                      })
                    : undefined;
                  this.appLibrary =
                    !!this.library &&
                    !/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i.test(
                      this.library.id
                    );
                  this.permissions =
                    library?.permissions.map((p) => p.permission) ?? [];
                })
              )
        )
      )
      .subscribe(() => {
        if (this.page) this.tilePageFacade.updateCurrentPage(this.page);
        else
          this.tilePageFacade.currentPage$
            .pipe(first())
            .subscribe((page) => (this.page = page));
      });

    this.libraryTileActionService.actions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((tile) => {
        this.filtering = true;
        this.selectedView = 'documentView';
        this.filterValue = Object.fromEntries(
          tile.metadataFilters?.map((f) => [f.metadataParameter.id, f.value]) ??
            []
        );
        this.oldFilterValue = this.filterValue;
        if (tile.tileAction)
          this.libraryFacade
            .libraryById$(tile.tileAction)
            .pipe(first())
            .subscribe((library) => {
              this.library = library;
            });
      });

    this.filterParamControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filterValueControl.patchValue(undefined);
        setTimeout(() => {
          this.filterValueElement?.focus();
        }, 0);
      });

    config$.pipe(takeUntil(this.destroy$)).subscribe((config) => {
      this.useOldFilter = config.useOldFilter;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getPreviewWidth() {
    return Math.max(this.bodyWidth * 0.25, 400);
  }

  toggleFiltering() {
    if (this.filtering) {
      this.filterValue = {};
      if (this.library) {
        this.filterValue = {
          ...this.filterValue,
        };
        this.selectedView = 'documentView';
      }

      this.filtering = false;
      this.filterParamControl.patchValue(undefined);
      this.filterValueControl.patchValue(undefined);
      this.resetBreadcrumb();
      this.router.navigate(['page']);
      this.selectedView = 'tileView';
    } else {
      this.selectedView = 'documentView';
      this.filtering = !this.filtering;
    }
  }

  resetBreadcrumb(): void {
    while (this.breadcrumbService.breadcrumbs.value.length > 1) {
      this.breadcrumbService.removeBreadcrumb(1);
    }
  }

  getFilteredParams(params: MetadataParam[]) {
    return this.library
      ? params
          .filter(
            (p) =>
              p.createdByParam ||
              p.createdOnParam ||
              p.modifiedByParam ||
              p.modifiedOnParam ||
              p.fileNameParam ||
              this.library?.configuredParams.some((c) => c.paramId == p.id)
          )
          .sort(
            (a, b) =>
              (this.library?.configuredParams.find((c) => c.paramId == a.id)
                ?.position ?? 0) -
              (this.library?.configuredParams.find((c) => c.paramId == b.id)
                ?.position ?? 0)
          )
      : params;
  }

  getParamById(params: MetadataParam[], id: string) {
    return params.find((p) => p.id == id);
  }

  getDateStartEndStart(value: DateStartEnd) {
    if (!value.start) return undefined;
    return value.start instanceof DateTime
      ? value.start
      : DateTime.fromJSDate(value.start);
  }
  getDateStartEndEnd(value: DateStartEnd) {
    if (!value.end) return undefined;
    return value.end instanceof DateTime
      ? value.end
      : DateTime.fromJSDate(value.end);
  }

  checkDisabled(
    filterValueControl: FormControl<MetadataFilterType | null | undefined>
  ) {
    if (
      filterValueControl.value &&
      dateStartEndTypeGuard(filterValueControl.value)
    ) {
      return (
        !(
          this.getDateStartEndStart(filterValueControl.value as DateStartEnd) ||
          this.getDateStartEndEnd(filterValueControl.value as DateStartEnd)
        ) || filterValueControl.invalid
      );
    } else {
      return !filterValueControl.value;
    }
  }

  getParamValue(
    params: MetadataParam[],
    id: string,
    value: MetadataFilterType
  ) {
    const param = this.getParamById(params, id);
    if (param?.type == ColumnType.Choice)
      return Array.isArray(value)
        ? value
            .map((v) => param.choices?.find((c) => c.id == v)?.value)
            .join(', ')
        : param.choices?.find((c) => c.id == value)?.value;
    else if (param?.type == ColumnType.ConsolidatedChoice) {
      if (Array.isArray(value)) {
        return value
          .map((v) => {
            const [pId, cId] = (v as string).split('.');
            const p = this.getParamById(params, pId);
            return p?.choices?.find((c) => c.id == cId)?.value;
          })
          .join(', ');
      } else {
        const [pId, cId] = (value as string).split('.');
        const p = this.getParamById(params, pId);
        return p?.choices?.find((c) => c.id == cId)?.value;
      }
    } else if (param?.type == ColumnType.GroupUser)
      return Array.isArray(value)
        ? value.map((v) => (v as GroupUser).displayName).join(', ')
        : (value as GroupUser).displayName;
    else if (param?.type == ColumnType.DateTime) {
      if (typeof value == 'string') return value;
      else
        return `${
          this.getDateStartEndStart(value as DateStartEnd)?.toFormat(
            param.format ?? 'dd/MM/yyyy'
          ) ?? '...'
        } - ${
          this.getDateStartEndEnd(value as DateStartEnd)?.toFormat(
            param.format ?? 'dd/MM/yyyy'
          ) ?? '...'
        }`;
    } else if (Array.isArray(value)) return value.join(', ');
    else return value;
  }

  addFilter() {
    const param = this.filterParamControl.value;
    if (!param) return;
    if (this.filterValueControl.value)
      this.filterValue = {
        ...this.filterValue,
        [param.id]: this.filterValueControl.value,
      };
    else if (this.filterValue[param.id]) {
      delete this.filterValue[param.id];
      this.filterValue = { ...this.filterValue };
    }
  }

  removeFilter(paramId: string) {
    delete this.filterValue[paramId];
    this.filterValue = { ...this.filterValue };
  }

  selectFilter(
    params: MetadataParam[],
    paramId: string,
    value: MetadataFilterType
  ) {
    const param = this.getParamById(params, paramId);
    if (!param) return;
    this.filterParamControl.patchValue(param);
    setTimeout(() => {
      this.filterValueControl.patchValue(value);
    }, 0);
  }

  updateFilter(value: { [key: string]: any }) {
    this.filterValue = value;
    if (Object.keys(value).length) this.filtering = true;
    else this.filtering = false;
  }

  refreshFilter() {
    this.filterValue = { ...this.filterValue };
  }

  private removeSpecialCharacters(str: string) {
    // Add more characters if needed
    return str
      .replace(/[|:]/gi, '_')
      .replace(/\s+/g, ' ')
      .replace(/_+/g, '_')
      .trim();
  }

  openDropZone(files: FileList) {
    if (!this.canUpload) {
      this.snackbar.open(
        this.getTranslation('errorNoPermissionsToLibrary'),
        'Ok',
        { duration: 3000, panelClass: 'app-notification-error' }
      );
      if (this.fileInput) this.fileInput.nativeElement.value = '';
      return;
    }

    const fileArray = Array.from(files);
    const msgMap: { [key: string]: Observable<ArrayBuffer> } = {};
    const emlMap: { [key: string]: Observable<Email> } = {};
    fileArray.forEach((file) => {
      if (file.name.endsWith('.msg'))
        msgMap[file.name] = from(file.arrayBuffer());
      if (file.name.endsWith('.eml'))
        emlMap[file.name] = from(new PostalMime().parse(file));
    });
    forkJoin([
      ...Object.entries(msgMap).flatMap(([name, obs]) =>
        obs.pipe(
          map((buffer) => {
            const msg = Msg.fromUint8Array(new Uint8Array(buffer));
            const file = fileArray.find((f) => f.name == name)!;
            return {
              file,
              attachments: msg
                .attachments()
                .map((attachment) => {
                  const fileName = attachment.getProperty<string>(
                    PidTagAttachLongFilename
                  );
                  const inline =
                    attachment.getProperty<boolean>(PidTagAttachmentHidden) ??
                    false;
                  const extension =
                    attachment.getProperty<string>(PidTagAttachExtension) ?? '';
                  if (inline || !extension) return;
                  return new File(
                    [new Uint8Array(attachment.content())],
                    this.removeSpecialCharacters(fileName)
                  );
                })
                .filter((f) => f != undefined) as File[],
            };
          })
        )
      ),
      ...Object.entries(emlMap).flatMap(([name, obs]) =>
        obs.pipe(
          map((email) => {
            const file = fileArray.find((f) => f.name == name)!;
            return {
              file,
              attachments: email.attachments
                .map((attachment) => {
                  const fileName = attachment.filename;
                  const inline = attachment.disposition == 'inline';
                  if (inline) return;
                  return new File(
                    [attachment.content],
                    this.removeSpecialCharacters(fileName)
                  );
                })
                .filter((f) => f != undefined) as File[],
            };
          })
        )
      ),
    ])
      .pipe(defaultIfEmpty([]))
      .subscribe((msgMap) => {
        const linkedItems: { [key: string]: string[] } = {};
        msgMap.forEach(({ file, attachments }) => {
          const index = fileArray.indexOf(file);
          fileArray.splice(index + 1, 0, ...attachments);
          linkedItems[file.name] = attachments.map((f) => f.name);
        });
        const filterValue = { ...this.filterValue };
        if (this.library)
          this.library.configuredParams.forEach((c) =>
            !filterValue[c.paramId]
              ? (filterValue[c.paramId] = c.defaultValue)
              : undefined
          );
        const data: DropZoneData = {
          library: this.library,
          filterValue: filterValue,
          files: fileArray,
          linkedItems,
        };
        this.dialog
          .open(DropZoneComponent, {
            data,
            width: '98%',
            height: '98%',
            maxWidth: '100vw',
            maxHeight: '100vh',
            enterAnimationDuration: 0,
          })
          .afterClosed()
          .subscribe(() => {
            if (this.fileInput) this.fileInput.nativeElement.value = '';
          });
      });
  }

  dragging = false;

  dragStart(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dragging || !this.canUpload || this.appLibrary) return;
    this.dragging = true;
  }

  dragStop(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.dragging) return;
    this.dragging = false;
  }

  filesDropped(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.dragging = false;
    if (event.dataTransfer?.files && event.dataTransfer.files.length) {
      this.openDropZone(event.dataTransfer.files);
    } else {
      this.snackbar
        .open(this.getTranslation('unsupportedMedia'), 'Ok', {
          panelClass: 'app-notification-error',
        })
        ._dismissAfter(3000);
    }
  }

  onFileInputChange(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input?.files && input.files.length) {
      this.openDropZone(input.files);
    }
  }

  focusInput(input: HTMLInputElement) {
    setTimeout(() => {
      input.focus();
    }, 0);
  }

  // OLD FILTER RESIZE

  oldFilterDivStartY = 0;

  resizeStart(startY: number) {
    this.oldFilterDivStartY = startY;
    window.addEventListener('mousemove', this.mouseMoveBound);
    window.addEventListener('mouseup', this.mouseUpBound);
  }

  mouseMoveBound = this.mouseMove.bind(this);
  mouseUpBound = this.mouseUp.bind(this);

  mouseMove(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (event.pageY - this.oldFilterDivStartY < 64) return;
    this.oldFilterMaxHeight = event.pageY - this.oldFilterDivStartY;
  }

  mouseUp() {
    window.removeEventListener('mousemove', this.mouseMoveBound);
    window.removeEventListener('mouseup', this.mouseUpBound);
  }

  touchMoveBound = this.touchMove.bind(this);
  touchUpBound = this.touchUp.bind(this);

  startResizeTouch() {
    window.addEventListener('touchmove', this.touchMoveBound);
    window.addEventListener('touchend', this.touchUpBound);
  }

  touchMove(event: TouchEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (event.touches[0].pageY - this.oldFilterDivStartY < 64) return;
    this.oldFilterMaxHeight = event.touches[0].pageY - this.oldFilterDivStartY;
  }

  touchUp() {
    window.removeEventListener('touchmove', this.touchMoveBound);
    window.removeEventListener('touchup', this.touchUpBound);
  }

  markFormAsPristine(comp: MetadataInputsComponent) {
    setTimeout(() => {
      comp.markAsPristine();
    }, 0);
  }

  onDockRightChange(dockRight: boolean) {
    localStorage.setItem('dockRight', JSON.stringify(dockRight));
    this.dockRight = dockRight;
  }
}
