import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { Observable, first, map, of } from 'rxjs';
import { AppDateAdapter } from 'src/app/core/adapters/date.adapter';
import { CoreModule } from 'src/app/core/core.module';
import {
  groupUsers$,
  groups$,
  translations$,
  users$,
} from 'src/app/core/data/data.observables';
import { ColumnType } from 'src/app/core/domain/enums/column-type.enum';
import { Library } from 'src/app/core/domain/models/library.model';
import { MetadataChoice } from 'src/app/core/domain/models/metadata-choice.model';
import { MetadataParam } from 'src/app/core/domain/models/metadata-param.model';
import {
  DateStartEnd,
  MetadataFilterType,
  dateStartEndTypeGuard,
} from 'src/app/core/helper/metadata.functions';
import { ChoiceSelectComponent } from '../choice-select/choice-select.component';
import {
  DatePickerFormatDirective,
  GroupUser,
} from 'processdelight-angular-components';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateTime } from 'luxon';

const validValueValidator: (comp: AdaptableInputComponent) => ValidatorFn =
  (comp) => (control) => {
    const value = control.value;
    if (control.disabled) return null;
    if (dateStartEndTypeGuard(value)) {
      if (
        !(comp.dateStartEndStartInvalid || comp.dateStartEndEndInvalid) &&
        (value.start || value.end)
      )
        return null;
      return { required: true };
    }
    // if value is number, it should be less than 1000000000, otherwise it is invalid
    if (typeof value == 'number' && value >= 1000000000)
      return { required: true };
    return null;
  };

@Component({
  selector: 'app-adaptable-input',
  standalone: true,
  imports: [
    CoreModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatMenuModule,
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ChoiceSelectComponent,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    DatePickerFormatDirective,
  ],
  templateUrl: './adaptable-input.component.html',
  styleUrls: ['./adaptable-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AdaptableInputComponent,
    },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
    },
  ],
})
export class AdaptableInputComponent
  implements ControlValueAccessor, OnInit, OnChanges
{
  @Input() param!: MetadataParam;
  @Input() params!: MetadataParam[];

  @Input() filter = false;
  @Input() required?: string;
  @Input() library?: Library;

  @Input() class?: string;
  @Input() labelOverride?: string;

  @Input() formControl?: FormControl;
  @Input() formControlName?: string;

  @Output() valueChanges = new EventEmitter<MetadataFilterType | undefined>();

  @ViewChild('input') input?: ElementRef<HTMLInputElement>;
  @ViewChild('input2') input2?: ElementRef<HTMLInputElement>;
  @ViewChild('selectInput') selectInput?: MatSelect;

  private _value?: MetadataFilterType;
  get value() {
    return this._value;
  }
  @Input() set value(value: MetadataFilterType | undefined) {
    this._value = value;
    if (!this._beforeInit) {
      if (this.param.type == ColumnType.DateTime) {
        if (this.filter)
          this._value = (
            this.isDateStartEnd
              ? {
                  start: this.dateStartEnd.start,
                  end: this.dateStartEnd.end,
                }
              : { start: undefined, end: undefined }
          ) as DateStartEnd;
        else if (value instanceof DateTime) {
          this._value = value;
        } else if (typeof value == 'string')
          this._value = DateTime.fromISO(value, { zone: 'utc' });
      }
      this.markAsTouched();
      this.onChange(this._value);
      this.valueChanges.emit(this._value);
    }
  }

  @Input() disabled = false;
  touched = false;

  get isDateStartEnd() {
    return (
      this.param.type == ColumnType.DateTime &&
      this.filter &&
      this.value &&
      typeof this.value == 'object' &&
      'start' in this.value &&
      'end' in this.value
    );
  }

  dateStartEndStartInvalid = false;
  dateStartEndEndInvalid = false;

  get dateStartEnd() {
    return this.value as DateStartEnd;
  }
  get dateStartEndStart() {
    return this.dateStartEnd.start;
  }
  set dateStartEndStart(date: DateTime | undefined) {
    this.value = { ...this.dateStartEnd, start: date };
    setTimeout(() => {
      this.dateStartEndStartInvalid =
        !!this.input?.nativeElement.value && !date;
      this._control?.updateValueAndValidity();
    }, 0);
  }
  get dateStartEndEnd() {
    return this.dateStartEnd.end;
  }
  set dateStartEndEnd(date: DateTime | undefined) {
    this.value = { ...this.dateStartEnd, end: date };
    setTimeout(() => {
      this.dateStartEndEndInvalid = !!this.input2?.nativeElement.value && !date;
      this._control?.updateValueAndValidity();
    }, 0);
  }

  get selectedGroupUsers() {
    return Array.isArray(this.value)
      ? (this.value as GroupUser[])
      : this.value
      ? [this.value as GroupUser]
      : [];
  }

  groupUsers$: Observable<GroupUser[]> = of([]);

  ColumnType = ColumnType;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value?: MetadataFilterType) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  private _control?: AbstractControl;

  isRequired = false;

  private _beforeInit = true;

  groupUserCompareFn = (o1?: GroupUser, o2?: GroupUser) => o1?.id == o2?.id;

  constructor(
    private readonly dateAdapter: AppDateAdapter,
    @Optional() private readonly controlContainer: ControlContainer
  ) {}

  getTranslation(label: string): string {
    return translations$.value[label];
  }
  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  getGroupUser(id: string) {
    let displayName = '';
    groupUsers$.pipe(
      map((g) => {
        const gu = g.find((gu) => gu.id == id);
        displayName = gu?.displayName ?? '';
      })
    );
    return displayName;
  }

  ngOnInit(): void {
    this._beforeInit = false;
    if (this.formControlName)
      this._control =
        this.controlContainer.control?.get(this.formControlName) ?? undefined;
    else if (this.formControl) this._control = this.formControl;
    this._control?.addValidators(validValueValidator(this));
    this.isRequired =
      (this.required !== undefined &&
        (this.required != 'false' || +this.required != 0)) ||
      (this._control?.hasValidator(Validators.required) ?? false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.param &&
      changes.param.currentValue != changes.param.previousValue
    ) {
      this.param = new MetadataParam({
        ...changes.param.currentValue,
        choices: [...changes.param.currentValue.choices],
      });
      if (changes.param.currentValue?.id != changes.param.previousValue?.id) {
        this.value = undefined;
        if (this.param.type == ColumnType.DateTime && this.param.format)
          this.dateAdapter.customFormat = this.param.format;
        if (this.param.type == ColumnType.GroupUser)
          if (this.param.showGroups && this.param.showUsers)
            this.groupUsers$ = groupUsers$.pipe(
              map((g) =>
                g
                  ? [...g].sort(
                      (a, b) =>
                        (a.group ? 0 : 1) - (b.group ? 0 : 1) ||
                        (a.displayName ?? '').localeCompare(b.displayName ?? '')
                    )
                  : []
              )
            );
          else if (this.param.showGroups)
            this.groupUsers$ = groups$.pipe(
              map((g) =>
                g
                  ? [...g].sort((a, b) =>
                      (a.displayName ?? '').localeCompare(b.displayName ?? '')
                    )
                  : []
              )
            );
          else if (this.param.showUsers)
            this.groupUsers$ = users$.pipe(
              map((g) =>
                g
                  ? [...g].sort((a, b) =>
                      (a.displayName ?? '').localeCompare(b.displayName ?? '')
                    )
                  : []
              )
            );
      }
    }
    if (
      changes.required &&
      changes.required.currentValue != changes.required.previousValue
    )
      this.isRequired =
        (this.required !== undefined &&
          (this.required != 'false' || +this.required != 0)) ||
        (this._control?.hasValidator(Validators.required) ?? false);
  }

  getChoiceParameter(params: MetadataParam[], id: string) {
    return params.find((p) => p.id == id);
  }

  getChoiceTranslation(languageId: string, choice: MetadataChoice) {
    return choice.translations.find((t) => t.language.id == languageId);
  }

  getSelectTrigger(params: MetadataParam[], paramId: string) {
    let result = '';
    if (this.value) {
      const choiceParam = params.find((p) => p.id == paramId)!;
      if (Array.isArray(this.value))
        result = this.value
          .map((v) => choiceParam.choices.find((c) => c.id == v)?.value)
          .join(', ');
      else
        result =
          choiceParam.choices.find((c) => c.id == this.value)?.value ?? '';
    }
    return result;
  }
  getConsolidatedSelectTrigger(params: MetadataParam[]) {
    if (this.value) {
      if (Array.isArray(this.value)) {
        const result = this.value
          .map((v) => {
            const split = (v as string).split('.');
            const choiceParam = params.find((p) => p.id == split[0])!;
            return `(${choiceParam.title}) ${
              choiceParam.choices.find((c) => c.id == split[1])?.value
            }`;
          })
          .join(', ');
        return result;
      }
      const split = (this.value as string).split('.');
      const choiceParam = params.find((p) => p.id == split[0])!;
      return `(${choiceParam.title}) ${
        choiceParam.choices.find((c) => c.id == split[1])?.value
      }`;
    } else return '';
  }

  writeValue(obj?: MetadataFilterType): void {
    this.value = obj;
  }
  registerOnChange(fn: (value?: MetadataFilterType) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }
  reset() {
    this.value = undefined;
    this.touched = false;
  }

  adminItemAdded(choice: MetadataChoice) {
    this.param.choices.push(choice);
    this.params
      .find((p) => p.id == choice.metadataParameterId)
      ?.choices.push(choice);
  }

  focus() {
    if (this.input) this.focusElement(this.input.nativeElement);
    if (this.selectInput) this.selectInput.open();
  }

  focusElement(element: HTMLInputElement) {
    setTimeout(() => {
      element.focus();
    }, 0);
  }

  selectFirstGroupUser(filter: string) {
    this.groupUsers$.pipe(first()).subscribe((g) => {
      const groupUser = g.find((gu) =>
        gu.displayName?.toLowerCase().includes(filter.toLowerCase())
      );
      if (groupUser)
        if (this.param.multi)
          if (Array.isArray(this.value))
            if (
              !(this.value as GroupUser[]).find(
                (v: GroupUser) => v.id == groupUser.id
              )
            )
              this.value = [...(this.value ?? []), groupUser] as GroupUser[];
            else
              this.value = (this.value as GroupUser[]).filter(
                (v: GroupUser) => v.id != groupUser.id
              );
          else this.value = [groupUser] as GroupUser[];
        else this.value = groupUser;
    });
  }
}
