<div
  class="d-flex flex-row align-items-stretch"
  [formGroup]="form"
  (click)="$event.preventDefault()"
>
  <div
    #container
    class="metadata-form scrollbar overflow-auto"
    [ngStyle]="{ 'max-height': maxHeight ?? '' }"
  >
    <mat-grid-list [cols]="calcCols(container.clientWidth)" rowHeight="4rem">
      <mat-grid-tile
        *ngFor="let param of shownMetadata; trackBy: paramTrackBy"
        rowspan="1"
        [colspan]="calcSize(container.clientWidth, param)"
      >
        <ng-container *ngIf="formControlsLength">
          <app-adaptable-input
            [formControlName]="param.id"
            [param]="param"
            [params]="(metadata$ | async) ?? []"
            [library]="library"
            [filter]="filter"
            class="m-2 {{ hasValue(param.id) ? 'has-value' : '' }}"
          ></app-adaptable-input>
        </ng-container>
      </mat-grid-tile>

      <ng-container *ngIf="buttonTemplate">
        <mat-grid-tile
          colspan="1"
          rowspan="1"
          *ngFor="let _ of calcEmptyTiles(container.clientWidth, shownMetadata)"
          class="empty-tile"
        ></mat-grid-tile>
        <mat-grid-tile colspan="1" rowspan="1">
          <ng-template
            [ngTemplateOutlet]="buttonTemplate"
            [ngTemplateOutletContext]="{
              value: value,
              clearValue: clearValue.bind(this)
            }"
          ></ng-template>
        </mat-grid-tile>
      </ng-container>
    </mat-grid-list>
  </div>
</div>
