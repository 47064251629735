import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'any' })
export class AppDateAdapter extends NativeDateAdapter {
  customFormat = 'dd/MM/yyyy HH:mm';
  constructor() {
    super('nl-BE');
  }
  override parse(value: unknown): Date | null {
    if (typeof value === 'string')
      return DateTime.fromFormat(value, 'dd/MM/yyyy HH:mm').toJSDate();
    else if (value instanceof Date) return value;
    else if (typeof value === 'number') return new Date(value);
    else return null;
  }
  override format(date: Date, displayFormat: string) {
    if (this.customFormat)
      return DateTime.fromJSDate(date).toFormat(this.customFormat);
    return DateTime.fromJSDate(date).toFormat(displayFormat);
  }
}
