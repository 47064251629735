<div class="bg-gray d-flex flex-column mh-100 h-100">
  <h1 mat-dialog-title>{{ getTranslation$("dropZone") | async }}</h1>
  <div
    mat-dialog-content
    class="d-flex flex-column scrollbar h-100"
    #dialogContent
  >
    <cdk-virtual-scroll-viewport
      *ngIf="initialized; else loading"
      [itemSize]="viewPortItemSize"
      [maxBufferPx]="viewPortItemSize * 2"
      [minBufferPx]="viewPortItemSize"
      class="d-flex flex-column scrollbar h-100"
    >
      <div
        class="d-flex flex-row justify-content-center border p-2 mb-2 bg-white"
        *cdkVirtualFor="
          let file of files;
          let i = index;
          trackBy: fileTrackByFn
        "
      >
        <div
          class="flex-shrink-0 ps-1 pe-2 d-flex justify-content-center align-items-start"
          style="width: 32px"
        >
          {{ i + 1 }}
        </div>
        <div
          class="d-flex justify-content-center align-items-center flex-shrink-0 icon-column p-2 border me-2 bg-gray"
        >
          <div
            class="d-flex flex-column justify-content-center align-items-center bg-white p-2 w-100 position-relative"
          >
            <ng-container
              *ngIf="
                loadedInFiles.includes(file.name) ||
                existingMetadataFiles.includes(file.name)
              "
            >
              <mat-icon
                class="material-icons-outlined position-absolute file-warning clickable"
                color="warn"
                [matTooltip]="getToolTipString(file)"
                matTooltipPosition="right"
                matTooltipClass="toolTipClass"
                >priority_high</mat-icon
              >
            </ng-container>
            <ng-container *ngIf="linkedFileMap[file.name] as linkedFiles">
              <ng-container *ngIf="linkedFiles.length">
                <mat-icon
                  class="material-icons-outlined position-absolute file-link clickable"
                  [matMenuTriggerFor]="fileLinks"
                  >link</mat-icon
                >
                <mat-menu #fileLinks>
                  <div class="ms-2 me-2">
                    {{ getTranslation$("linkedFiles") | async }}
                    <div *ngFor="let f of linkedFiles">{{ f.name }} <br /></div>
                  </div>
                </mat-menu>
              </ng-container>
            </ng-container>

            <img
              [src]="getLibraryIcon(file)"
              width="48"
              height="48"
              alt="{{ file.name }} icon"
            />
            <div class="text-wrap text-break text-center">
              {{ getFileName(file) }}
            </div>
            <ng-container
              *ngIf="
                loadedInFiles.includes(file.name) ||
                existingMetadataFiles.includes(file.name)
              "
            >
              <mat-slide-toggle
                [disabled]="existingMetadataFiles.includes(file.name)"
                [checked]="
                  updateMap[file.name] ||
                  existingMetadataFiles.includes(file.name)
                "
                (toggleChange)="updateMap[file.name] = !updateMap[file.name]"
              >
              </mat-slide-toggle>
              <div class="toggle-text">
                <ng-container
                  *ngIf="
                    updateMap[file.name] ||
                      existingMetadataFiles.includes(file.name);
                    else createNew
                  "
                >
                  {{ getTranslation$("updateExisting") | async }}
                </ng-container>
                <ng-template #createNew>
                  {{ getTranslation$("uploadAsNew") | async }}
                </ng-template>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="w-100 h-100 border bg-gray">
          <app-metadata-inputs
            [library]="library"
            [value]="fileValueMap[file.name]"
            [buttonTemplate]="
              files.length > 1
                ? i === 0
                  ? firstButtonTemplate
                  : otherButtonTemplate
                : undefined
            "
            [copy]="
              updateMap[file.name] !== undefined &&
              updateMap[file.name] !== true
            "
            [extension]="file.name.split('.').pop()"
            (valueChanges)="updateFileValueMap(file, $event)"
            (formValid)="formValidMap[file.name] = $event"
            (addedParam)="addedParam($event)"
          ></app-metadata-inputs>
          <ng-template
            #firstButtonTemplate
            let-selectedTile="selectedTile"
            let-value="value"
          >
            <div class="w-100 d-flex flex-row justify-content-end">
              <button
                mat-raised-button
                color="primary"
                class="me-2"
                (click)="setAllMetadata(value)"
              >
                {{ getTranslation$("copyToAllFiles") | async }}
              </button>
            </div>
          </ng-template>
          <ng-template #otherButtonTemplate let-value="value">
            <div class="w-100 d-flex flex-row justify-content-end">
              <button
                mat-raised-button
                color="primary"
                class="me-2"
                [matMenuTriggerFor]="filePicker"
                #filePickerTrigger="matMenuTrigger"
              >
                {{ getTranslation$("copyFrom") | async }}
              </button>
              <mat-menu #filePicker>
                <mat-action-list *ngIf="filePickerTrigger.menuOpen">
                  <mat-list-item
                    *ngFor="let f of getOtherFiles(file)"
                    (click)="copyMetadataFrom(f, file)"
                  >
                    {{ f.name }}
                  </mat-list-item>
                </mat-action-list>
              </mat-menu>
            </div>
          </ng-template>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
    <ng-template #loading>
      <div
        class="h-100 w-100 d-flex justify-content-center align-items-center lds-overlay"
        *ngIf="loading"
      >
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </ng-template>
  </div>
  <div mat-dialog-actions class="justify-content-end text-uppercase">
    <button
      mat-raised-button
      mat-dialog-close
      color="primary"
      (click)="save()"
      [disabled]="!formValid"
    >
      {{ getTranslation$("save") | async }}
    </button>
    <button mat-button mat-dialog-close>
      {{ getTranslation$("cancel") | async }}
    </button>
  </div>
</div>
