import { createAction, props } from '@ngrx/store';
import { LibraryItem } from '../../domain/models/item.model';
import { Library } from '../../domain/models/library.model';
import { Permission } from '../../domain/models/permission.model';
import { ActionBase } from '../action-base.interface';
import { LibraryTrigger } from '../../domain/models/library-trigger.model';
import { Observable } from 'rxjs';

export const getLibraries = createAction(
  '[Library] Get Libraries',
  props<ActionBase<Library[]>>()
);
export const getLibrariesResolved = createAction(
  '[Library] Get Libraries resolved',
  props<{ libraries: Library[] }>()
);
export const createLibrary = createAction(
  '[Library] Create Library',
  props<{ library: Library } & ActionBase<Library>>()
);
export const createLibraryResolved = createAction(
  '[Library] Create Library resolved',
  props<{ library: Library }>()
);
export const createLibrariesResolved = createAction(
  '[Library] Create Libraries resolved',
  props<{ libraries: Library[] }>()
);
export const updateLibrary = createAction(
  '[Library] Update Library',
  props<
    {
      library: Library;
    } & ActionBase<Library>
  >()
);
export const updateLibraryResolved = createAction(
  '[Library] Update Library resolved',
  props<{ library: Library }>()
);
export const deleteLibrary = createAction(
  '[Library] Delete Library',
  props<{ libraryId: string } & ActionBase<string>>()
);
export const deleteLibraryResolved = createAction(
  '[Library] Delete Library resolved',
  props<{ libraryId: string }>()
);
export const createLibraryPermission = createAction(
  '[Library] Create Library Permission',
  props<
    {
      permission: Permission;
      libraryId: string;
    } & ActionBase<Permission>
  >()
);
export const createLibraryPermissionResolved = createAction(
  '[Library] Create Library Permission resolved',
  props<{ permission: Permission }>()
);
export const updateLibraryPermission = createAction(
  '[Library] Update Library Permission',
  props<
    {
      permission: Permission;
    } & ActionBase<Permission>
  >()
);
export const updateLibraryPermissionResolved = createAction(
  '[Library] Update Library Permission resolved',
  props<{ permission: Permission }>()
);
export const deleteLibraryPermission = createAction(
  '[Library] Delete Library Permission',
  props<
    {
      permissionId: string;
    } & ActionBase<string>
  >()
);
export const deleteLibraryPermissionResolved = createAction(
  '[Library] Delete Library Permission resolved',
  props<{ permissionId: string }>()
);
export const getSharedItems = createAction(
  '[Library] Get shared items',
  props<
    {
      orderBy: string | undefined;
      orderByDirection: 'asc' | 'desc' | undefined;
      filters: { [key: string]: string };
      pageSize: number;
      page: number;
    } & ActionBase<{
      result: LibraryItem[];
      totalRecordCount: number;
    }>
  >()
);
export const getSharedItemsResolved = createAction(
  '[Library] Get shared items resolved',
  props<{
    result: LibraryItem[];
    totalRecordCount: number;
  }>()
);
export const getLibraryItems = createAction(
  '[Library] Get Library items',
  props<
    {
      orderBy: string | undefined;
      orderByDirection: 'asc' | 'desc' | undefined;
      filters: { [key: string]: string };
      pageSize: number;
      page: number;
      libraryId: string | undefined;
      reset$: (() => Observable<void>) | undefined;
    } & ActionBase<{
      result: LibraryItem[];
      totalRecordCount: number;
    }>
  >()
);
export const getLibraryItemsResolved = createAction(
  '[Library] Get Library items resolved',
  props<{
    result: LibraryItem[];
    totalRecordCount: number;
  }>()
);
export const createLibraryItems = createAction(
  '[Library] Create Library items',
  props<
    {
      items: LibraryItem[];
    } & ActionBase<LibraryItem[]>
  >()
);
export const createLibraryItemsResolved = createAction(
  '[Library] Create Library items resolved',
  props<{
    items: LibraryItem[];
  }>()
);
export const updateLibraryItemsForMove = createAction(
  '[Library] Update Library items for move',
  props<
    {
      items: LibraryItem[];
    } & ActionBase<LibraryItem[]>
  >()
);
export const updateLibraryItems = createAction(
  '[Library] Update Library items',
  props<
    {
      items: LibraryItem[];
    } & ActionBase<LibraryItem[]>
  >()
);
export const updateLibraryItemsResolved = createAction(
  '[Library] Update Library items resolved',
  props<{
    items: LibraryItem[];
  }>()
);
export const deleteLibraryItems = createAction(
  '[Library] Delete Library items',
  props<
    {
      itemIds: string[];
    } & ActionBase<string[]>
  >()
);
export const deleteLibraryItemsResolved = createAction(
  '[Library] Delete Library items resolved',
  props<{
    itemIds: string[];
  }>()
);

export const removeItemMetadataForParam = createAction(
  '[Library] Remove item metadata for param',
  props<{ paramId: string }>()
);

export const resetSlice = createAction('[Library] Reset slice');

export const getLibraryTriggers = createAction(
  '[Library] Get Library Triggers',
  props<ActionBase<LibraryTrigger[]>>()
);
export const getLibraryTriggersResolved = createAction(
  '[Library] Get Library Triggers resolved',
  props<{
    items: LibraryTrigger[];
  }>()
);
