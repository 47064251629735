import { createSelector } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { TilePageSegment } from '../../domain/models/tile-page-segment.model';
import { TileAction } from '../../domain/enums/tile-action.enum';

const tilePageState = (state: AppState) => state.coreFeature.tilepage;
const getTilePages = createSelector(tilePageState, (state) => state.tilePages);
const getTilePagesForSegmentFunc = createSelector(
  tilePageState,
  (state) => (segmentId: string) =>
    state.tilePages.filter((t) =>
      t.segments.some((s) => s.tilePageSegmentId == segmentId)
    )
);
const getSegments = createSelector(tilePageState, (state) =>
  state.tilePages
    .map((t) => t.segments.map((s) => s.tilePageSegment))
    .reduce(
      (acc, s) => [
        ...acc,
        ...s.filter((s1) => !acc.some((s2) => s1.id == s2.id)),
      ],
      [] as TilePageSegment[]
    )
);
const getSegmentsForTileFunc = createSelector(
  getSegments,
  (segments) => (tileId: string) =>
    segments.filter((s) => s.tiles.some((t) => t.tileId == tileId))
);
const getTilePageByIdFunc = createSelector(
  getTilePages,
  (state) => (id: string) => state.find((p) => p.id == id)
);
const getTileByIdFunc = createSelector(
  getTilePages,
  (state) => (id: string) =>
    state
      .flatMap((p) => p.segments)
      .flatMap((s) => s.tilePageSegment.tiles.map((t) => t.tile))
      .find((t) => t.id == id)
);
const getLibraryFilterValuesForParamIdFunc = createSelector(
  getTilePages,
  (state) => (paramId: string) =>
    state
      .flatMap((p) => p.segments)
      .flatMap((s) => s.tilePageSegment.tiles.map((t) => t.tile))
      .filter((t) => t.tileActionType == TileAction.Library)
      .flatMap((t) => t.metadataFilters)
      .filter((f) => f?.metadataParameter.id == paramId)
      .map((f) => f?.value)
);

const getLibraryForParamIdFunc = createSelector(
  getTilePages,
  (state) => (paramId: string, value: string) =>
    state
      .flatMap((p) => p.segments)
      .flatMap((s) => s.tilePageSegment.tiles.map((t) => t.tile))
      .filter((t) => t.tileActionType == TileAction.Library)
      .find((t) =>
        t.metadataFilters?.some(
          (f) => f.metadataParameter.id == paramId && f?.value == value
        )
      )
);
const getCurrentTilePage = createSelector(
  tilePageState,
  (state) => state.currentPage
);
const getHomePage = createSelector(tilePageState, (state) =>
  state.tilePages.find((p) => p.homepage)
);
export const tilePageSelectors = {
  getTilePages,
  getTilePagesForSegmentFunc,
  getSegments,
  getSegmentsForTileFunc,
  getTilePageByIdFunc,
  getTileByIdFunc,
  getLibraryFilterValuesForParamIdFunc,
  getLibraryForParamIdFunc,
  getCurrentTilePage,
  getHomePage,
};
