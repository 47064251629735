import { BaseEntity } from 'processdelight-angular-components';
import { MetadataPermissionConfigPermission } from './metadata-permission-config-permission.model';

export class MetadataPermissionConfiguration extends BaseEntity {
  metadataParameterId!: string;
  subValue?: string;
  isPermissionsEnabled!: boolean;
  isRestrictivePermission!: boolean;
  permissions: MetadataPermissionConfigPermission[] = [];

  constructor(obj: Partial<MetadataPermissionConfiguration>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.permissions?.length) {
      this.permissions = obj.permissions.map(
        (p) => new MetadataPermissionConfigPermission(p)
      );
    }
  }
}
