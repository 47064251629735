import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Tile } from 'src/app/core/domain/models/tile.model';

@Injectable({
  providedIn: 'root',
})
export class LibraryTileActionService implements OnDestroy {
  private actionSubject = new Subject<Tile>();

  get actions$() {
    return this.actionSubject.asObservable();
  }

  ngOnDestroy(): void {
    this.actionSubject.complete();
  }

  libraryTileClicked(tile: Tile) {
    this.actionSubject.next(tile);
  }
}
