import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { first, map, of, switchMap } from 'rxjs';
import { CoreModule } from 'src/app/core/core.module';
import { isAdmin$, translations$ } from 'src/app/core/data/data.observables';
import { ColumnType } from 'src/app/core/domain/enums/column-type.enum';
import { LibraryParamConfig } from 'src/app/core/domain/models/library-param-config.model';
import { Library } from 'src/app/core/domain/models/library.model';
import { MetadataChoice } from 'src/app/core/domain/models/metadata-choice.model';
import { MetadataParam } from 'src/app/core/domain/models/metadata-param.model';
import { LibraryFacade } from 'src/app/core/store/library/library.facade';
import { MetadataFacade } from 'src/app/core/store/metadata/metadata.facade';
import { AddChoicePopupComponent } from './add-choice-popup/add-choice-popup.component';
import { MetadataChoiceTranslation } from 'src/app/core/domain/models/metadata-choice-translation.model';

@Component({
  selector: 'app-choice-select',
  standalone: true,
  imports: [
    CoreModule,
    MatButtonModule,
    MatCheckboxModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatIconModule,
    MatDialogModule,
  ],
  templateUrl: './choice-select.component.html',
  styleUrls: ['./choice-select.component.scss'],
})
export class ChoiceSelectComponent implements OnChanges, AfterViewInit {
  @Input() choiceParam!: MetadataParam;
  @Input() params!: MetadataParam[];
  @Input() library?: Library;
  @Input() value?: string | string[];
  @Input() multiple = false;
  @Input() filter = false;

  @Output() valueChanges = new EventEmitter<string | string[]>();
  @Output() adminItemAdded = new EventEmitter<MetadataChoice>();

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;

  consolidatedChoices: MetadataParam[] = [];

  get isConsolidatedChoice() {
    return this.choiceParam.type === ColumnType.ConsolidatedChoice;
  }

  tableColumns: string[] = [];
  consolidatedChoicesTableColumns: { [key: string]: string[] } = {};
  isSaving = false;

  datasource = new MatTableDataSource<MetadataChoice>();
  consolidatedChoicesDatasources: {
    [key: string]: MatTableDataSource<MetadataChoice>;
  } = {};

  choiceTrackBy = (_index: number, choice: MetadataChoice) => choice.id;

  isAdmin$ = isAdmin$;

  constructor(
    private readonly metadataFacade: MetadataFacade,
    private readonly libraryFacade: LibraryFacade,
    private addChoiceDialog: MatDialog
  ) {}

  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  ngAfterViewInit(): void {
    this.focusSearch();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.choiceParam &&
      changes.choiceParam.currentValue?.id !=
        changes.choiceParam.previousValue?.id
    ) {
      if (this.choiceParam.type === ColumnType.ConsolidatedChoice) {
        this.consolidatedChoices = this.choiceParam.consolidatedChoices.map(
          (c) =>
            this.params.find((p) => p.id === c.metadataConsolidatedChoiceId)!
        );
        this.consolidatedChoicesTableColumns = {};
        this.consolidatedChoicesDatasources = {};
        this.consolidatedChoices.forEach((c) => {
          const columns: string[] = [];
          if (this.multiple) columns.push('checkbox');
          columns.push('value');
          c.languages.forEach((l) => columns.push(l.languageId));
          this.consolidatedChoicesTableColumns[c.id] = columns;
          this.consolidatedChoicesDatasources[c.id] =
            new MatTableDataSource<MetadataChoice>(
              c.choices
                .filter(
                  (ch) =>
                    !this.library ||
                    !this.library.configuredParams.some(
                      (p) => p.paramId == c.id
                    ) ||
                    this.library.configuredParams.some(
                      (p) =>
                        p.paramId == c.id &&
                        (!p.choices.length ||
                          p.choices.some((c) => c.metadataChoiceId == ch.id))
                    )
                )
                .sort((a, b) => {
                  if (this.value?.includes(a.id) && !this.value.includes(b.id))
                    return -1;
                  else if (this.value?.includes(b.id)) return 1;
                  else return a.value.localeCompare(b.value);
                })
            );
        });
      } else if (this.choiceParam.hasTranslations) {
        this.datasource.data = this.choiceParam.choices
          .filter(
            (ch) =>
              !this.library ||
              this.library.configuredParams.some(
                (p) =>
                  p.paramId == this.choiceParam.id &&
                  (!p.choices.length ||
                    p.choices.some((c) => c.metadataChoiceId == ch.id))
              )
          )
          .sort((a, b) => {
            if (this.value?.includes(a.id) && !this.value.includes(b.id))
              return -1;
            else if (this.value?.includes(b.id)) return 1;
            else return a.value.localeCompare(b.value);
          });
        this.tableColumns = [];
        if (this.multiple) this.tableColumns.push('checkbox');
        this.tableColumns.push('value');
        this.choiceParam.languages.forEach((l) =>
          this.tableColumns.push(l.languageId)
        );
      }
    }
  }

  filterChoices(param: MetadataParam) {
    const config = this.library?.configuredParams.find(
      (p) => p.paramId == param.id
    );
    let result = config?.choices?.length
      ? param.choices
          .filter((c) =>
            config?.choices.some((ch) => ch.metadataChoiceId == c.id)
          )
          .sort((a, b) => a.value.localeCompare(b.value))
      : [...param.choices].sort((a, b) => a.value.localeCompare(b.value));
    if (this.multiple)
      result = result.sort((a, b) => {
        if (this.value?.includes(a.id) && !this.value?.includes(b.id))
          return -1;
        else if (this.value?.includes(b.id)) return 1;
        else return a.value.localeCompare(b.value);
      });
    return result;
  }

  valueChecked(choice: MetadataChoice, param?: MetadataParam) {
    const val = `${param ? param.id + '.' : ''}${choice.id}`;
    if (this.multiple) {
      if (this.value) {
        const values = Array.isArray(this.value) ? this.value : [this.value];
        return values.includes(val);
      }
      return false;
    } else return this.value === val;
  }

  selectChoice(choice?: MetadataChoice, param?: MetadataParam) {
    if (choice) {
      const val = `${param ? param.id + '.' : ''}${choice.id}`;
      if (this.multiple) {
        if (this.value) {
          const values = Array.isArray(this.value) ? this.value : [this.value];
          if (values.includes(val))
            this.value = values.filter((v) => v !== val);
          else this.value = [...values, val];
        } else this.value = [val];
        this.datasource.data = [
          ...this.choiceParam.choices.filter(
            (ch) =>
              !this.library ||
              this.library.configuredParams.some(
                (p) =>
                  p.paramId == this.choiceParam.id &&
                  (!p.choices.length ||
                    p.choices.some((c) => c.metadataChoiceId == ch.id))
              )
          ),
        ].sort((a, b) => {
          if (this.value?.includes(a.id) && !this.value.includes(b.id))
            return -1;
          else if (this.value?.includes(b.id)) return 1;
          else return a.position - b.position;
        });
      } else this.value = val;
    } else this.value = undefined;
    this.valueChanges.emit(this.value);
  }

  matchChoiceFilter(filter: string, choice: MetadataChoice) {
    return (
      choice.value.toLowerCase().includes(filter.toLowerCase()) ||
      choice.translations.some((t) =>
        t.translation?.toLowerCase().includes(filter.toLowerCase())
      )
    );
  }
  matchConsolidatedChoiceFilter(filter: string, param?: MetadataParam) {
    return param?.choices.some(
      (choice) =>
        choice.value.toLowerCase().includes(filter.toLowerCase()) ||
        choice.translations.some((t) =>
          t.translation?.toLowerCase().includes(filter.toLowerCase())
        )
    );
  }

  getChoiceTranslation(languageId: string, choice: MetadataChoice) {
    return choice.translations.find((t) => t.languageId == languageId);
  }

  canAddItem$(value: string) {
    return this.metadataFacade.adminMetadataParams$.pipe(
      first(),
      map((params) => {
        const param = params.find((p) => p.id == this.choiceParam.id);
        return (
          param?.choices?.every(
            (c) => c.value.toLowerCase().trim() !== value.toLowerCase().trim()
          ) ?? false
        );
      })
    );
  }

  addItem(value: string) {
    this.addChoiceDialog
      .open(AddChoicePopupComponent, {
        disableClose: true,
        width: this.choiceParam.languages.length > 0 ? '80%' : '40%',
        data: { choiceParam: this.choiceParam, value: value },
      })
      .afterClosed()
      .subscribe((choice: MetadataChoice) => {
        if (choice) {
          this.isSaving = true;
          this.metadataFacade.adminMetadataParams$
            .pipe(first())
            .subscribe((params) => {
              const param = params.find((p) => p.id == this.choiceParam.id);
              if (!param) return;

              this.metadataFacade
                .createMetadataChoice$(choice)
                .subscribe((choice) => {
                  this.isSaving = false;
                  if (this.library) {
                    const config = this.library.configuredParams.find(
                      (p) => p.paramId == this.choiceParam.id
                    );
                    if (config?.choices?.length) {
                      config?.choices.push(choice);
                      this.libraryFacade
                        .updateLibrary$(
                          new Library({
                            ...this.library,
                            configuredParams: this.library.configuredParams.map(
                              (p) =>
                                new LibraryParamConfig({
                                  ...p,
                                  choices: [...p.choices],
                                })
                            ),
                          })
                        )
                        .subscribe();
                    }
                  }
                  this.adminItemAdded.emit(choice);
                  if (this.choiceParam.hasTranslations) {
                    this.datasource.data = this.choiceParam.choices.filter(
                      (ch) =>
                        !this.library ||
                        this.library.configuredParams.some(
                          (p) =>
                            p.paramId == this.choiceParam.id &&
                            (!p.choices.length ||
                              p.choices.some(
                                (c) => c.metadataChoiceId == ch.id
                              ))
                        )
                    );
                    this.tableColumns = [];
                    if (this.multiple) this.tableColumns.push('checkbox');
                    this.tableColumns.push('value');
                    this.choiceParam.languages.forEach((l) =>
                      this.tableColumns.push(l.languageId)
                    );
                  }
                  if (this.multiple) {
                    if (this.value && Array.isArray(this.value))
                      this.value.push(choice.id);
                    else if (this.value) this.value = [this.value, choice.id];
                    else this.value = [choice.id];
                  } else this.value = choice.id;
                  this.valueChanges.emit(this.value);
                });
            });
        }
      });
  }
  public focusSearch() {
    setTimeout(() => {
      this.searchInput?.nativeElement.focus();
    }, 0);
  }

  selectFirstValue(filter: string) {
    if (this.choiceParam.type == ColumnType.ConsolidatedChoice) {
      const param = this.consolidatedChoices.find((p) =>
        this.matchConsolidatedChoiceFilter(filter, p)
      );
      if (param) {
        const choice = param.choices.find((c) =>
          this.matchChoiceFilter(filter, c)
        );
        if (choice) this.selectChoice(choice, param);
      }
    } else {
      const choice = this.choiceParam.choices.find((c) =>
        this.matchChoiceFilter(filter, c)
      );
      if (choice) this.selectChoice(choice);
    }
  }
}
