<div class="d-flex flex-column h-100">
  <div
    class="titleContainer d-flex flex-column justify-content-center align-items-center"
  >
    <div class="title">
      <h5 style="margin: 0px">
        {{ getTranslation$("add") | async }}
      </h5>
    </div>
    <processdelight-action-bar
      [navColor]="'var(--nav-color)'"
      [navContrast]="'var(--nav-contrast)'"
      [iconActions]="iconActions"
      [buttonActions]="actions"
      [searchEnabled]="false"
      [sideBarButtonEnabled]="false"
    ></processdelight-action-bar>
  </div>
  <div
    mat-dialog-content
    class="h-100 mh-100 min-height-0 scrollbar"
    style="padding: 0px"
  >
    <div
      class="formWrapper"
      style="
        width: 100%;
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
      "
    >
      <form
        [formGroup]="choiceForm"
        class="h-100 d-flex flex-row align-items-center add-choice-popup"
      >
        <div class="name p-1">
          <mat-form-field class="clickable" appearance="outline">
            <mat-label class="clickable">{{
              getTranslation$("choiceItemName") | async
            }}</mat-label>
            <input
              class="inputFontSize"
              formControlName="value"
              matInput
              placeholder="Choice item name"
            />
          </mat-form-field>
        </div>
        <ng-container formArrayName="translations">
          <div
            *ngFor="let translation of translationArrayControls; let i = index"
            [formGroupName]="i"
            div
            class="translation p-1"
          >
            <mat-form-field class="clickable" appearance="outline">
              <mat-label class="clickable">{{ getLanguage(i) }}</mat-label>
              <input
                class="inputFontSize"
                formControlName="value"
                matInput
                [placeholder]="getTranslation$('translation') | async"
              />
            </mat-form-field>
          </div>
        </ng-container>
        <div *ngIf="choiceForm?.errors?.required">Required</div>
      </form>
    </div>
  </div>
</div>
