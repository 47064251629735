import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { LibraryItem } from '../../domain/models/item.model';
import { Library } from '../../domain/models/library.model';
import { Permission } from '../../domain/models/permission.model';
import { BaseFacade } from '../base.facade';
import {
  createLibrary,
  createLibraryItems,
  createLibraryPermission,
  deleteLibrary,
  deleteLibraryItems,
  deleteLibraryPermission,
  getLibraries,
  getLibraryItems,
  getLibraryTriggers,
  getSharedItems,
  resetSlice,
  updateLibrary,
  updateLibraryItems,
  updateLibraryItemsForMove,
  updateLibraryPermission,
  updateLibraryResolved,
} from './library.actions';
import { librarySelectors } from './library.selectors';
import { LibraryTrigger } from '../../domain/models/library-trigger.model';

@Injectable({
  providedIn: 'root',
})
export class LibraryFacade extends BaseFacade {
  libraryItems$ = this._store.pipe(select(librarySelectors.getLibraryItems));
  sharedItems$ = this._store.pipe(select(librarySelectors.getSharedItems));
  libraries$ = this._store.pipe(select(librarySelectors.getLibraries));
  libraryTriggers$ = this._store.pipe(
    select(librarySelectors.getLibraryTriggers)
  );

  libraryById$ = (id: string) =>
    this._store.pipe(
      select(librarySelectors.getLibraryById),
      map((selector) => selector(id))
    );

  getLibraries$() {
    return this.dispatchAction$(getLibraries);
  }

  createLibrary$(library: Library) {
    return this.dispatchAction$(createLibrary, {
      library,
    });
  }

  updateLibrary$(library: Library) {
    return this.dispatchAction$(updateLibrary, {
      library,
    });
  }
  updateLibraryResolved(library: Library) {
    this._store.dispatch(updateLibraryResolved({ library }));
  }

  deleteLibrary$(libraryId: string) {
    return this.dispatchAction$(deleteLibrary, {
      libraryId,
    });
  }

  createLibraryPermission$(permission: Permission, libraryId: string) {
    return this.dispatchAction$(createLibraryPermission, {
      permission,
      libraryId,
    });
  }
  updateLibraryPermission$(permission: Permission) {
    return this.dispatchAction$(updateLibraryPermission, {
      permission,
    });
  }
  deleteLibraryPermission$(permissionId: string) {
    return this.dispatchAction$(deleteLibraryPermission, {
      permissionId,
    });
  }

  getSharedItems$(
    orderBy: string | undefined,
    orderByDirection: 'asc' | 'desc' | undefined,
    filters: { [key: string]: string },
    pageSize: number,
    page: number
  ) {
    return this.dispatchAction$(getSharedItems, {
      orderBy,
      orderByDirection,
      filters,
      pageSize,
      page,
    });
  }
  getLibraryItems$(
    orderBy: string | undefined,
    orderByDirection: 'asc' | 'desc' | undefined,
    filters: { [key: string]: string },
    pageSize: number,
    page: number,
    libraryId: string | undefined = undefined,
    reset$: (() => Observable<void>) | undefined = undefined
  ) {
    return this.dispatchAction$(getLibraryItems, {
      orderBy,
      orderByDirection,
      filters,
      pageSize,
      page,
      libraryId,
      reset$,
    });
  }

  createLibraryItems$(items: LibraryItem[]) {
    return this.dispatchAction$(createLibraryItems, {
      items,
    });
  }
  updateLibraryItemsForMove$(items: LibraryItem[]) {
    return this.dispatchAction$(updateLibraryItemsForMove, {
      items,
    });
  }
  updateLibraryItems$(items: LibraryItem[]) {
    return this.dispatchAction$(updateLibraryItems, {
      items,
    });
  }
  deleteLibraryItems$(itemIds: string[]) {
    return this.dispatchAction$(deleteLibraryItems, {
      itemIds,
    });
  }
  deleteLibraryItems(itemIds: string[]) {
    this._store.dispatch(
      deleteLibraryItems({
        itemIds,
      })
    );
  }

  getLibraryTriggers() {
    return this.dispatchAction$(getLibraryTriggers);
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
