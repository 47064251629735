import { BaseEntity } from 'processdelight-angular-components';
import { TilePageType } from '../enums/tile-page-type.enum';
import { TilePagePermission } from './tile-page-permission.model';
import { TilePageTilePageSegment } from './tile-page-tile-page-segment.model';

export class TilePage extends BaseEntity {
  title!: string;
  disabled?: boolean;
  homepage?: boolean;
  type!: TilePageType;
  segments: TilePageTilePageSegment[] = [];
  permissions: TilePagePermission[] = [];

  app = 'DMS';

  constructor(obj: Partial<TilePage>) {
    super(obj);
    Object.assign(this, obj);
    if (typeof obj.type == 'string')
      this.type = TilePageType[obj.type as keyof typeof TilePageType];
    if (obj.segments)
      this.segments = obj.segments.map((s) => new TilePageTilePageSegment(s));
    else this.segments = [];
    if (obj.permissions?.length)
      this.permissions = obj.permissions
        .map((p) => new TilePagePermission(p))
        .filter((p) => p.permission?.groupUser);
    else this.permissions = [];
  }
}
