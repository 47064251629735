import { Action, createReducer, on } from '@ngrx/store';
import { LibraryItem } from '../../domain/models/item.model';
import {
  createLibrariesResolved,
  createLibraryItemsResolved,
  createLibraryPermission,
  createLibraryResolved,
  deleteLibraryItemsResolved,
  deleteLibraryPermission,
  deleteLibraryResolved,
  getLibrariesResolved,
  getLibraryItemsResolved,
  getLibraryTriggersResolved,
  getSharedItemsResolved,
  removeItemMetadataForParam,
  resetSlice,
  updateLibraryItemsResolved,
  updateLibraryPermission,
  updateLibraryResolved,
} from './library.actions';
import { Library } from '../../domain/models/library.model';
import { LibraryTrigger } from '../../domain/models/library-trigger.model';
import { LibraryPermission } from '../../domain/models/library-permission.model';

export const featureSlice = 'library';

export interface State {
  libraries: Library[];
  items: LibraryItem[];
  totalRecordCount: number;
  sharedItems: LibraryItem[];
  sharedRecordCount: number;
  libraryTriggers?: LibraryTrigger[];
}

const defaultState: State = {
  libraries: [],
  items: [],
  totalRecordCount: 0,
  sharedItems: [],
  sharedRecordCount: 0,
};

export function Reducer(state: State | undefined, action: Action) {
  return libraryReducer(state, action);
}

export const initialState: State = defaultState;
export const libraryReducer = createReducer(
  initialState,
  on(getLibrariesResolved, (state, { libraries }) => ({
    ...state,
    libraries,
  })),
  on(createLibraryResolved, (state, { library }) => ({
    ...state,
    libraries: [...state.libraries, library],
  })),
  on(createLibrariesResolved, (state, { libraries: library }) => ({
    ...state,
    libraries: [...state.libraries, ...library],
  })),
  on(updateLibraryResolved, (state, { library }) => ({
    ...state,
    libraries: state.libraries.map((l) =>
      l.id == library.id
        ? new Library({
            ...library,
            permissions: l.permissions,
          })
        : l
    ),
  })),
  on(deleteLibraryResolved, (state, { libraryId }) => ({
    ...state,
    libraries: state.libraries.filter((l) => l.id != libraryId),
  })),
  on(createLibraryPermission, (state, { permission, libraryId }) => ({
    ...state,
    libraries: state.libraries.map((l) =>
      l.id == libraryId
        ? new Library({
            ...l,
            permissions: [
              ...l.permissions,
              new LibraryPermission({
                libraryId: l.id,
                library: l,
                permissionId: permission.id,
                permission,
              }),
            ],
          })
        : l
    ),
  })),
  on(updateLibraryPermission, (state, { permission }) => ({
    ...state,
    libraries: state.libraries.map(
      (l) =>
        new Library({
          ...l,
          permissions: l.permissions.map((p) =>
            p.permissionId == permission.id
              ? new LibraryPermission({
                  libraryId: l.id,
                  library: l,
                  permissionId: permission.id,
                  permission,
                })
              : p
          ),
        })
    ),
  })),
  on(deleteLibraryPermission, (state, { permissionId }) => ({
    ...state,
    libraries: state.libraries.map(
      (l) =>
        new Library({
          ...l,
          permissions: l.permissions.filter(
            (p) => p.permissionId != permissionId
          ),
        })
    ),
  })),
  on(getLibraryItemsResolved, (state, { result, totalRecordCount }) => ({
    ...state,
    items: result,
    totalRecordCount,
  })),
  on(getSharedItemsResolved, (state, { result, totalRecordCount }) => ({
    ...state,
    sharedItems: result,
    sharedRecordCount: totalRecordCount,
  })),
  on(createLibraryItemsResolved, (state, { items }) => ({
    ...state,
    items: [...state.items, ...items],
  })),
  on(updateLibraryItemsResolved, (state, { items }) => ({
    ...state,
    items: state.items.map((i) => {
      const found = items.find((x) => x.id == i.id);
      return found ?? i;
    }),
  })),
  on(deleteLibraryItemsResolved, (state, { itemIds }) => ({
    ...state,
    items: state.items.filter((i) => !itemIds.some((id) => i.id == id)),
  })),
  on(removeItemMetadataForParam, (state, { paramId }) => ({
    ...state,
    items: state.items.map(
      (i) =>
        new LibraryItem({
          ...i,
          metadata: i.metadata.filter((m) => m.metadataParameter.id != paramId),
        })
    ),
    libraries: state.libraries.map(
      (l) =>
        new Library({
          ...l,
          configuredParams: l.configuredParams.filter(
            (p) => p.paramId != paramId
          ),
        })
    ),
  })),
  on(getLibraryTriggersResolved, (state, { items }) => ({
    ...state,
    libraryTriggers: items,
  })),

  on(resetSlice, () => initialState)
);
