<div class="dialogContainer">
  <h1 mat-dialog-title class="text-center">
    {{ getTranslation$("moveDocuments") | async }}
  </h1>
  <mat-icon class="closeButtonIcon" (click)="close()">close</mat-icon>
  <div mat-dialog-content class="p-3 d-flex flex-column justify-content-center">
    <ng-container *ngIf="erroredItems.length">
      <p class="mb-0">
        {{ getErroredDocumentsTranslation$() | async }}
      </p>
      <p>{{ getTranslation$("moveDocumentsConfirmNotMoved") | async }}</p>
      <div
        class="rounded border border-gray mb-3 w-100 overflow-auto scrollbar"
        style="max-height: 16rem"
      >
        <mat-action-list class="p-0">
          <mat-list-item *ngFor="let item of erroredItems" class="mb-2">
            <div class="w-100 d-flex flex-column">
              <div class="d-flex flex-row align-items-center">
                <img
                  class="ms-2 pe-2"
                  [src]="getIcon(item.item)"
                  width="24"
                  height="24"
                  alt="file icon"
                />
                <div>
                  {{ fileNameMap[item.item.id] }}
                </div>
              </div>
              <div class="ms-2">
                {{
                  item.error === 0
                    ? (getTranslation$("insufficientPermissions") | async)
                    : (getTranslation$("alreadyPresentInLibrary") | async)
                }}
              </div>
            </div>
          </mat-list-item>
        </mat-action-list>
      </div>
    </ng-container>
    <p>
      {{ getMoveDocumentsConfirmationMessageTranslation$() | async }}
    </p>
    <div
      class="rounded border border-gray mb-2 w-100 overflow-auto scrollbar"
      style="max-height: 16rem"
    >
      <mat-action-list class="p-0">
        <mat-list-item *ngFor="let item of items">
          <div class="w-100 d-flex flex-row align-items-center">
            <img
              class="ms-2 pe-2"
              [src]="getIcon(item)"
              width="24"
              height="24"
              alt="file icon"
            />
            <div>
              {{ fileNameMap[item.id] }}
            </div>
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="!items.length">
          <div class="w-100 d-flex flex-row align-items-center">
            <div>
              {{ getTranslation$("noValidDocuments") | async }}
            </div>
          </div>
        </mat-list-item>
      </mat-action-list>
    </div>
    <div class="buttons">
      <button
        mat-raised-button
        class="menuButton"
        (click)="confirm()"
        [disabled]="!items.length"
      >
        <mat-icon>check</mat-icon>
        <span>{{ getTranslation$("move") | async }}</span>
      </button>
      <button mat-raised-button class="menuButton" (click)="close()">
        <mat-icon>close</mat-icon>
        <span>{{ getTranslation$("cancel") | async }}</span>
      </button>
    </div>
  </div>
</div>
