import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { catchError, map } from 'rxjs';
import { ConfirmPopupComponent } from 'src/app/core/components/confirm-popup/confirm-popup.component';
import { CoreModule } from 'src/app/core/core.module';
import { translations$ } from 'src/app/core/data/data.observables';
import { LibraryItem } from 'src/app/core/domain/models/item.model';
import { SharedItem } from 'src/app/core/domain/models/shared-item.model';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-shared-with',
  standalone: true,
  imports: [
    CoreModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatSnackBarModule,
  ],
  templateUrl: './shared-with.component.html',
  styleUrls: ['./shared-with.component.scss'],
})
export class SharedWithComponent implements OnInit {
  item: LibraryItem;

  sharedWith: SharedItem[] = [];

  loading = true;

  constructor(
    private readonly api: ApiService,
    private readonly dialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    data: {
      item: LibraryItem;
    }
  ) {
    this.item = data.item;
  }

  getTranslation(label: string): string {
    return translations$.value[label];
  }
  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  ngOnInit(): void {
    this.api.getSharedWithItems(this.item.id).subscribe((sharedWith) => {
      this.sharedWith = sharedWith;
      this.loading = false;
    });
  }

  removeSharing(item: SharedItem) {
    this.dialog.open(ConfirmPopupComponent, {
      data: {
        title: this.getTranslation$('removeSharing'),
        msg: this.getTranslation$('stopSharingWith').pipe(
          map((t) => `${t} ${item.to.displayName}?`)
        ),
        confirmText: this.getTranslation$('remove'),
        cancelText: this.getTranslation$('cancel'),
        confirmAction: () => {
          this.loading = true;
          this.api
            .deleteSharedWithItem(item.id)
            .pipe(
              catchError((e) => {
                this.snackbar.open(
                  this.getTranslation('errorSomethingWentWrong'),
                  'Ok',
                  {
                    duration: 3000,
                  }
                );
                this.loading = false;
                throw e;
              })
            )
            .subscribe(() => {
              this.sharedWith = this.sharedWith.filter((x) => x.id !== item.id);
              this.snackbar.open(this.getTranslation('removedSharing'), 'Ok', {
                duration: 3000,
              });
              this.loading = false;
            });
        },
      },
    });
  }
}
