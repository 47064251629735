import { GroupUser } from 'processdelight-angular-components';
import { PermissionType } from '../enums/permission-type.enum';
import { BaseEntity } from 'processdelight-angular-components';

export class Permission extends BaseEntity {
  permissionType!: PermissionType;
  downloadPermission = false;
  uploadPermission = false;
  deletePermission = false;
  groupUserId!: string;
  groupUser!: GroupUser;

  constructor(obj: Partial<Permission>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.groupUser) this.groupUser = new GroupUser(obj.groupUser);
    if (obj.permissionType && typeof obj.permissionType == 'string') {
      this.permissionType =
        PermissionType[obj.permissionType as keyof typeof PermissionType];
    }
  }
}
