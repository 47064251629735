import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { translations$ } from '../../data/data.observables';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-confirm-popup',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDialogModule, MatButtonModule],
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent {
  confirmAction?: () => void;
  title: Observable<string>;
  msg = this.getTranslation$('errorPerformThisAction');
  confirmText = this.getTranslation$('confirm');
  cancelText = this.getTranslation$('cancel');
  confirmIcon = 'check';
  cancelIcon = 'close';
  confirmData?: any;
  cancelData?: any;

  getTranslation(label: string): string {
    return translations$.value[label];
  }
  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  constructor(
    public dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public confirmPopupData: {
      title: Observable<string>;
      msg?: Observable<string>;
      confirmText?: Observable<string>;
      cancelText?: Observable<string>;
      confirmIcon?: string;
      cancelIcon?: string;
      confirmAction: () => void;
    }
  ) {
    this.confirmAction = confirmPopupData.confirmAction;
    this.msg = confirmPopupData.msg || this.msg;
    this.confirmText = confirmPopupData.confirmText || this.confirmText;
    this.cancelText = confirmPopupData.cancelText || this.cancelText;
    this.confirmIcon = confirmPopupData.confirmIcon || this.confirmIcon;
    this.cancelIcon = confirmPopupData.cancelIcon || this.cancelIcon;
    this.title = confirmPopupData.title;
  }

  confirm() {
    if (this.confirmAction) this.confirmAction();
    this.dialogRef.close(this.confirmData);
  }

  close() {
    this.dialogRef.close(this.cancelData);
  }

  onNoClick(): void {
    this.dialogRef.close(this.cancelData);
  }
}
