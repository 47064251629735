export enum ColumnType {
  Text,
  Number,
  DateTime,
  Choice,
  GroupUser,
  ConsolidatedChoice,
}

export const columnTypeEnumMap: { [key: number]: string } = {
  [ColumnType.Text]: 'Text field',
  [ColumnType.Number]: 'Number field',
  [ColumnType.DateTime]: 'Date field',
  [ColumnType.Choice]: 'Choice picker',
  [ColumnType.GroupUser]: 'People picker',
  [ColumnType.ConsolidatedChoice]: 'Consolidated choice',
};
