export class UserSettings {
  librarySettings: LibraryUserSettings[] = [];

  constructor(init?: Partial<UserSettings>) {
    Object.assign(this, init);
    if (init?.librarySettings) {
      this.librarySettings = init.librarySettings.map(
        (l) => new LibraryUserSettings(l)
      );
    }
  }
}
export class LibraryUserSettings {
  libraryId?: string;
  columnOrder: string[] = [];
  columnSizes: { [key: string]: number } = {};
  sortColumn?: string;
  sortDirection?: 'asc' | 'desc';
  pageSize = 100;

  constructor(init?: Partial<LibraryUserSettings>) {
    Object.assign(this, init);
  }
}
