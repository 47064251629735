import { GroupUser } from 'processdelight-angular-components';
import { BaseEntity } from 'processdelight-angular-components';
import { LibraryItem } from './item.model';
import { MetadataParam } from './metadata-param.model';
import { DateTime } from 'luxon';

export class Metadata extends BaseEntity {
  value?: string;
  numberValue?: number;
  dateTimeValue?: DateTime;
  groupUserValue?: GroupUser;
  itemId!: string;
  item!: LibraryItem;
  metadataParameterId!: string;
  metadataParameter!: MetadataParam;

  constructor(obj: Partial<Metadata>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.groupUserValue)
      this.groupUserValue = new GroupUser(obj.groupUserValue);
    if (obj.metadataParameter)
      this.metadataParameter = new MetadataParam(obj.metadataParameter);
    if (obj.item) this.item = new LibraryItem(obj.item);
    if (typeof obj.dateTimeValue == 'string')
      this.dateTimeValue = DateTime.fromISO(obj.dateTimeValue, { zone: 'utc' });
    if (obj.dateTimeValue instanceof DateTime)
      this.dateTimeValue = DateTime.fromMillis(obj.dateTimeValue.valueOf(), {
        zone: 'utc',
      });
  }
}
