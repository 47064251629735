import { BaseEntity } from 'processdelight-angular-components';
import { Library } from './library.model';
import { Permission } from './permission.model';

export class LibraryPermission extends BaseEntity {
  libraryId!: string;
  permissionId!: string;
  library!: Library;
  permission!: Permission;

  constructor(obj: Partial<LibraryPermission>) {
    super(obj);
    Object.assign(this, obj);
    if (obj?.library) {
      this.library =
        obj.library instanceof Library ? obj.library : new Library(obj.library);
    }
    if (obj?.permission) {
      this.permission =
        obj.permission instanceof Permission
          ? obj.permission
          : new Permission(obj.permission);
    }
  }
}
