import { BaseEntity } from 'processdelight-angular-components';
import { LibraryParamConfig } from './library-param-config.model';
import { MetadataChoice } from './metadata-choice.model';

export class LibraryParamConfigChoice extends BaseEntity {
  libraryParamConfigId?: string;
  metadataChoiceId?: string;
  libraryParamConfig?: LibraryParamConfig;
  metadataChoice?: MetadataChoice;

  constructor(obj: Partial<LibraryParamConfigChoice>) {
    super(obj);
    Object.assign(this, obj);
    if (obj?.libraryParamConfig) {
      this.libraryParamConfig =
        obj.libraryParamConfig instanceof LibraryParamConfig
          ? obj.libraryParamConfig
          : new LibraryParamConfig(obj.libraryParamConfig);
    }
    if (obj?.metadataChoice) {
      this.metadataChoice =
        obj.metadataChoice instanceof MetadataChoice
          ? obj.metadataChoice
          : new MetadataChoice(obj.metadataChoice);
    }
  }
}
