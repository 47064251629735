import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MicrosoftAuthenticationService } from 'processdelight-angular-components';
import { catchError, mergeMap, of, switchMap, tap } from 'rxjs';
import { translations$ } from '../../data/data.observables';
import { ApiService } from '../../services/api.service';
import { removeItemMetadataForParam } from '../library/library.actions';
import {
  createDMSWebpartPermissions,
  createDMSWebpartPermissionsResolved,
  createMetadataChoice,
  createMetadataChoiceResolved,
  createMetadataChoiceTranslations,
  createMetadataChoiceTranslationsResolved,
  createMetadataParam,
  createMetadataParamResolved,
  deleteDMSWebpartPermissions,
  deleteDMSWebpartPermissionsResolved,
  deleteMetadataChoice,
  deleteMetadataChoiceResolved,
  deleteMetadataParam,
  deleteMetadataParamResolved,
  getAdminMetadataParams,
  getAdminMetadataParamsResolved,
  getDMSWebpartPermissions,
  getDMSWebpartPermissionsResolved,
  getMetadataParams,
  getMetadataParamsResolved,
  getMetadataPermissionConfigurations,
  getMetadataPermissionConfigurationsResolved,
  updateMetadataChoice,
  updateMetadataChoiceResolved,
  updateMetadataChoices,
  updateMetadataChoicesResolved,
  updateMetadataParam,
  updateMetadataParamResolved,
  updateMetadataPermissionConfiguration,
  updateMetadataPermissionConfigurationResolved,
  updateMetadataView,
} from './metadata.actions';

@Injectable({ providedIn: 'root' })
export class MetadataEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private snackbar: MatSnackBar,
    private msal: MicrosoftAuthenticationService
  ) {}

  getTranslation(label: string): string {
    return translations$.value[label];
  }

  getMetadataParams = createEffect(() =>
    this.actions$.pipe(
      ofType(getMetadataParams),
      switchMap(({ callback, error }) =>
        this.api.getMetadataParams().pipe(
          tap((metadataParams) =>
            callback ? callback(metadataParams) : undefined
          ),
          switchMap((metadataParams) =>
            of(getMetadataParamsResolved({ metadataParams }))
          ),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );
  getAdminMetadataParams = createEffect(() =>
    this.actions$.pipe(
      ofType(getAdminMetadataParams),
      switchMap(({ callback, error }) =>
        this.api.getMetadataParams(true).pipe(
          tap((metadataParams) =>
            callback ? callback(metadataParams) : undefined
          ),
          switchMap((metadataParams) =>
            of(getAdminMetadataParamsResolved({ metadataParams }))
          ),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );
  createMetadataParam = createEffect(() =>
    this.actions$.pipe(
      ofType(createMetadataParam),
      mergeMap(({ metadataParam, callback, error }) =>
        this.api.createMetadataParam(metadataParam).pipe(
          tap((metadataParam) =>
            callback ? callback(metadataParam) : undefined
          ),
          mergeMap((metadataParam) => {
            this.snackbar.open(this.getTranslation('parameterCreated'), 'Ok', {
              panelClass: 'app-notification-success',
              duration: 3000,
            });
            return of(createMetadataParamResolved({ metadataParam }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorCreateParameter'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );
  updateMetadataParam = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMetadataParam),
      mergeMap(({ metadataParam, callback, error }) =>
        this.api.updateMetadataParam(metadataParam).pipe(
          tap((metadataParam) =>
            callback ? callback(metadataParam) : undefined
          ),
          mergeMap((metadataParam) => {
            this.snackbar.open(this.getTranslation('parameterUpdate'), 'Ok', {
              panelClass: 'app-notification-success',
              duration: 3000,
            });
            return of(
              updateMetadataParamResolved({ metadataParams: [metadataParam] })
            );
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorUpdateParameter'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );
  deleteMetadataParam = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMetadataParam),
      mergeMap(({ metadataParamId, callback, error }) =>
        this.api.deleteMetadataParam(metadataParamId).pipe(
          tap(() => (callback ? callback(metadataParamId) : undefined)),
          mergeMap(() => {
            this.snackbar.open(this.getTranslation('paramDeleted'), 'Ok', {
              panelClass: 'app-notification-success',
              duration: 3000,
            });
            return [
              deleteMetadataParamResolved({
                metadataParamIds: [metadataParamId],
              }),
              removeItemMetadataForParam({ paramId: metadataParamId }),
            ];
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorDeleteParameter'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );
  createMetadataChoice = createEffect(() =>
    this.actions$.pipe(
      ofType(createMetadataChoice),
      mergeMap(({ metadataChoice, callback, error }) =>
        this.api.createMetadataChoice(metadataChoice).pipe(
          tap((metadataChoice) =>
            callback ? callback(metadataChoice) : undefined
          ),
          mergeMap((metadataChoice) => {
            this.snackbar.open(this.getTranslation('choiceCreated'), 'Ok', {
              panelClass: 'app-notification-success',
              duration: 3000,
            });
            return of(createMetadataChoiceResolved({ metadataChoice }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorCreatingChoice'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );
  updateMetadataChoice = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMetadataChoice),
      mergeMap(({ metadataChoice, callback, error }) =>
        this.api.updateMetadataChoice(metadataChoice).pipe(
          tap((metadataChoice) =>
            callback ? callback(metadataChoice) : undefined
          ),
          mergeMap((metadataChoice) => {
            this.snackbar.open(this.getTranslation('choiceUpdated'), 'Ok', {
              panelClass: 'app-notification-success',
              duration: 3000,
            });
            return of(updateMetadataChoiceResolved({ metadataChoice }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(this.getTranslation('errorUpdateChoice'), 'Ok', {
              panelClass: 'app-notification-error',
              duration: 3000,
            });
            return [];
          })
        )
      )
    )
  );
  updateMetadataChoices = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMetadataChoices),
      mergeMap(({ metadataChoices, callback, error }) =>
        this.api.updateMetadataChoices(metadataChoices).pipe(
          tap((metadataChoices) =>
            callback ? callback(metadataChoices) : undefined
          ),
          mergeMap((metadataChoices) => {
            this.snackbar.open(this.getTranslation('choicesUpdated'), 'Ok', {
              panelClass: 'app-notification-success',
              duration: 3000,
            });
            return of(updateMetadataChoicesResolved({ metadataChoices }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorUpdateChoices'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );
  createMetadataChoiceTranslations = createEffect(() =>
    this.actions$.pipe(
      ofType(createMetadataChoiceTranslations),
      mergeMap(({ paramId, translations, callback, error }) =>
        this.api.createMetadataChoiceTranslations(translations).pipe(
          tap((translations) =>
            callback ? callback(translations) : undefined
          ),
          mergeMap((translations) => {
            return of(
              createMetadataChoiceTranslationsResolved({
                paramId,
                translations,
              })
            );
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorCreateChoiceTranslations'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );
  deleteMetadataChoice = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMetadataChoice),
      mergeMap(({ metadataParamId, metadataChoiceId, callback, error }) =>
        this.api.deleteMetadataChoice(metadataParamId, metadataChoiceId).pipe(
          mergeMap(() => {
            this.snackbar.open(this.getTranslation('choiceDeleted'), 'Ok', {
              panelClass: 'app-notification-success',
              duration: 3000,
            });
            return of(
              deleteMetadataChoiceResolved({
                metadataParamId,
                metadataChoiceId,
              })
            );
          }),
          tap(() => (callback ? callback(metadataChoiceId) : undefined)),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(this.getTranslation('errorDeleteChoice'), 'Ok', {
              panelClass: 'app-notification-error',
              duration: 3000,
            });
            return [];
          })
        )
      )
    )
  );

  getMetadataPermissionConfigurations = createEffect(() =>
    this.actions$.pipe(
      ofType(getMetadataPermissionConfigurations),
      switchMap(({ callback, error }) =>
        this.api.getMetadataPermissionConfigurations().pipe(
          tap((configurations) =>
            callback ? callback(configurations) : undefined
          ),
          switchMap((configurations) =>
            of(getMetadataPermissionConfigurationsResolved({ configurations }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );

  updateMetadataPermissionConfiguration = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMetadataPermissionConfiguration),
      mergeMap(({ configuration, callback, error }) =>
        this.api.updateMetadataPermissionConfiguration(configuration).pipe(
          tap((configuration) =>
            callback ? callback(configuration) : undefined
          ),
          mergeMap((configuration) => [
            updateMetadataPermissionConfigurationResolved({ configuration }),
            updateMetadataView({
              configuration,
              hasAccess: configuration.permissions.some((p) =>
                p.metadataPermission.groupUser.group
                  ? p.metadataPermission.groupUser.group.members?.some(
                      (m) => m.id == this.msal.userId
                    )
                  : p.metadataPermission.groupUser.user?.id == this.msal.userId
              ),
            }),
          ]),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorUpdatePermission'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );

  getDMSWebpartPermissions = createEffect(() =>
    this.actions$.pipe(
      ofType(getDMSWebpartPermissions),
      switchMap(({ callback, error }) =>
        this.api.getDMSWebpartPermissions().pipe(
          tap((permissions) => (callback ? callback(permissions) : undefined)),
          switchMap((permissions) =>
            of(getDMSWebpartPermissionsResolved({ permissions }))
          ),
          catchError((e) => {
            if (error) error(e);
            return [];
          })
        )
      )
    )
  );
  createDMSWebpartPermissions = createEffect(() =>
    this.actions$.pipe(
      ofType(createDMSWebpartPermissions),
      mergeMap(({ permissions, callback, error }) =>
        this.api.createDMSWebpartPermissions(permissions).pipe(
          tap((permissions) => (callback ? callback(permissions) : undefined)),
          mergeMap((permissions) =>
            of(createDMSWebpartPermissionsResolved({ permissions }))
          ),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorCreateWebpartPermissions'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );

  deleteDMSWebpartPermissions = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDMSWebpartPermissions),
      mergeMap(({ permissionIds, callback, error }) =>
        this.api.deleteDMSWebpartPermissions(permissionIds).pipe(
          tap(() => (callback ? callback(permissionIds) : undefined)),
          mergeMap(() =>
            of(
              deleteDMSWebpartPermissionsResolved({
                permissionIds,
              })
            )
          ),
          catchError((e) => {
            if (error) error(e);
            this.snackbar.open(
              this.getTranslation('errorDeleteWebpartPermissions'),
              'Ok',
              {
                panelClass: 'app-notification-error',
                duration: 3000,
              }
            );
            return [];
          })
        )
      )
    )
  );
}
