import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import {
  BreadCrumbService,
  BreadcrumbItem,
  ContextMenuAction,
  Ishtar365CommunicationService,
  SideBarAction,
  TopLayoutComponent,
} from 'processdelight-angular-components';
import { Subject, filter, map, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CoreModule } from './core/core.module';
import { license$, translations$ } from './core/data/data.observables';
import { UserLicenseInfo } from './core/domain/models/user-license-info.model';
import { LicenseService } from './core/services/license.service';
import { StartUpService } from './core/services/startup.service';

@Component({
  standalone: true,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    CoreModule,
    RouterModule,
    TopLayoutComponent,
    NgxMatNativeDateModule,
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  static readonly appName = 'Ishtar.DMS!';
  static readonly domainName = 'Ishtar.DMS';
  static readonly IshtarTasksAppName = 'Ishtar.Tasks';

  appLogo = environment.cdnAppIconUrl.replace('color', 'blue');
  appName = AppComponent.appName;

  navColor$ = license$.pipe(map((u) => u?.navColor));
  navContrast$ = license$.pipe(map((u) => u?.navContrast));

  buttonActions: ContextMenuAction<unknown>[] = [];
  iconActions: ContextMenuAction<unknown>[] = [];
  sideBarActions: SideBarAction[] = [];
  hideBreadcrumbs = false;

  destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private startup: StartUpService,
    private ishtarCommunicationService: Ishtar365CommunicationService,
    private breadcrumbService: BreadCrumbService,
    private licenseService: LicenseService
  ) { }

  ngOnInit(): void {
    this.initializeNavigationHooks();
    this.initializeLanguageSetup();
    this.initializeLogo();

    this.licenseService.currentLicenses$
      .pipe(takeUntil(this.destroy$))
      .subscribe((userLicenseInfo) => {
        this.initializeBreadcrumbItems();
        this.initializeStyleProperties(userLicenseInfo);
      });

    this.licenseService.isUserHavingBasicLicense(AppComponent.domainName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(hasLicense => hasLicense && this.initializeSidebarItems());

    this.licenseService.isUserHavingAdminLicense(AppComponent.domainName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(hasLicense => hasLicense && this.initializeHeaderActions());
  }

  ngOnDestroy(): void {
    this.startup.ngOnDestroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeBreadcrumbItems(): void {
    this.breadcrumbService.clearBreadcrumbs();

    this.breadcrumbService.addBreadcrumb(
      new BreadcrumbItem({
        label: 'Home',
        action: () => this.router.navigate(['page']),
      })
    );
  }

  private initializeStyleProperties(userLicenseInfo: UserLicenseInfo): void {
    document.documentElement.style.setProperty(
      '--nav-color',
      userLicenseInfo?.navColor ?? '#fff'
    );
    document.documentElement.style.setProperty(
      '--nav-contrast',
      userLicenseInfo?.navContrast ?? '#000'
    );
  }

  private initializeSidebarItems(): void {
    this.sideBarActions.push(
      new SideBarAction({
        title: translations$.asObservable().pipe(map((t) => t.home)),
        icon: 'home',
        iconOutline: true,
        route: ['page'],
      }),
      new SideBarAction({
        title: translations$.asObservable().pipe(map((t) => t.shared)),
        icon: 'folder_shared',
        iconOutline: true,
        route: ['shared'],
      })
    );
  }

  private initializeHeaderActions(): void {
    this.iconActions.push(
      new ContextMenuAction<unknown>({
        label: translations$.asObservable().pipe(map((t) => t.settings)),
        icon: 'settings',
        iconOutline: true,
        action: () => this.router.navigate(['settings', 'general']),
      })
    );
  }

  private initializeNavigationHooks(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((b) => b instanceof NavigationEnd)
      )
      .subscribe((event) => {
        const e = event as NavigationEnd;
        e.url.startsWith('/page')
          ? (this.hideBreadcrumbs = false)
          : (this.hideBreadcrumbs = true);
      });
  }

  private initializeLanguageSetup(): void {
    this.ishtarCommunicationService.language$
      .pipe(takeUntil(this.destroy$))
      .subscribe((language: string) => {
        if (!license$.value) return;
        // handle language change
        // update license
        license$.next({ ...license$.value, language } as UserLicenseInfo);
        // Get translations
        this.startup.getTranslations().subscribe();
      });
  }

  private initializeLogo(): void {
    const params = new URLSearchParams(location.search);

    if (params.has('appColor'))
      this.appLogo = environment.cdnAppIconUrl.replace(
        'color',
        params.get('appColor')!
      );

    const tabIconLink = document.querySelector(
      "link[rel~='icon']"
    ) as HTMLLinkElement;

    if (tabIconLink) tabIconLink.href = this.appLogo;
  }
}
