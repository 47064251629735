<div class="dialogContainer">
  <h1 mat-dialog-title class="text-center">{{ title | async }}</h1>
  <mat-icon class="closeButtonIcon" (click)="close()">close</mat-icon>
  <div
    mat-dialog-content
    class="p-3 d-flex flex-column justify-content-center align-items-center"
  >
    <p>{{ msg | async }}</p>
    <div class="buttons">
      <button mat-raised-button class="menuButton" (click)="confirm()">
        <mat-icon>{{ confirmIcon }}</mat-icon>
        <span>{{ confirmText | async }}</span>
      </button>
      <button mat-raised-button class="menuButton" (click)="close()">
        <mat-icon>{{ cancelIcon }}</mat-icon>
        <span>{{ cancelText | async }}</span>
      </button>
    </div>
  </div>
</div>
