export enum TileAction {
  TilePage = 1,
  Placeholder = 200,
  Library = 201,
  LibraryFilter = 202,
}

export const tileActionEnumMap: { [key: number]: string } = {
  [TileAction.TilePage]: 'Tile page',
  [TileAction.Placeholder]: 'Placeholder',
  [TileAction.LibraryFilter]: 'Library Filter',
};
