<div class="tile-wrapper" [class.disabled]="isDisabled">
  <ng-container *ngIf="!isPlaceholder">
    <div
      class="tile-icon"
      [class.clickable]="!isDisabled"
      (click)="open()"
      [title]="tile.tooltip || ''"
    >
      <img
        *ngIf="objectUrl; else imgNotFound"
        [src]="objectUrl"
        [width]="size"
        [height]="size"
        alt="{{ tile.title }} {{
          (getTranslation$('tileIcon') | async)?.toLowerCase()
        }}"
        class="tile-img"
        (error)="imgErrored($event)"
      />
      <ng-template #imgNotFound>
        <img
          [src]="notFoundImage"
          [width]="size"
          [height]="size"
          [alt]="getTranslation$('noImageFound') | async"
          class="tile-img"
        />
      </ng-template>
    </div>
    <div
      class="tile-title"
      [class.clickable]="!isDisabled"
      [style.font-size.px]="fontSize"
      [style.max-width.px]="intranet ? size : size * 2"
      (click)="open()"
    >
      {{ tile.title }}
    </div>
  </ng-container>
</div>
