import { BaseEntity } from 'processdelight-angular-components';
import { AppItemInfo } from './app-item-info.model';
import { Metadata } from './metadata.model';
import { Library } from './library.model';
import { LinkedItem } from './linked-item.model';

export class LibraryItem extends BaseEntity {
  title!: string;
  sharepointId!: string;
  fileLocation!: string;
  metadata: Metadata[] = [];
  linkedItems: LinkedItem[] = [];
  libraryId?: string;
  library?: Library;
  appItems: AppItemInfo[] = [];

  constructor(obj: Partial<LibraryItem>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.metadata) this.metadata = obj.metadata.map((m) => new Metadata(m));
    if (obj.appItems)
      this.appItems = obj.appItems.map((m) => new AppItemInfo(m));
    if (obj.linkedItems)
      this.linkedItems = obj.linkedItems.map((l) => new LinkedItem(l));
    if (obj.library) this.library = new Library(obj.library);
  }
}
