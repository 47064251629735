import { BaseEntity } from 'processdelight-angular-components';
import { MetadataParam } from './metadata-param.model';

export class MetadataParamConsolidatedChoice extends BaseEntity {
  metadataParameterId?: string;
  metadataConsolidatedChoiceId?: string;
  metadataParameter?: MetadataParam;

  constructor(obj: Partial<MetadataParamConsolidatedChoice>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.metadataParameter) {
      this.metadataParameter = new MetadataParam(obj.metadataParameter);
    }
  }
}
