export enum PermissionType {
  Read,
  Write,
  FullControl,
}
export const permissionTypeEnumMap: { [key: number]: string } = {
  [PermissionType.Read]: 'Read',
  [PermissionType.Write]: 'Write',
  [PermissionType.FullControl]: 'Full control',
};
