import { Injectable } from '@angular/core';
import {
  Ishtar365CommunicationService,
  Notification,
  NotificationType,
  ProgressNotification,
} from 'processdelight-angular-components';
import {
  Observable,
  Subject,
  catchError,
  concatMap,
  first,
  from,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { translations$ } from 'src/app/core/data/data.observables';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({ providedIn: 'root' })
export class ItemUploadService {
  private chunkSize = 10 * 1024 * 1024;
  constructor(
    private readonly api: ApiService,
    private readonly ishtarCommunicationService: Ishtar365CommunicationService
  ) {}

  getTranslation(label: string): string {
    return translations$.value[label];
  }

  private *handleChunkUpload(
    filename: string,
    file: File,
    relativeUrl: string | undefined,
    notification: ProgressNotification,
    cancellation: Observable<void>
  ) {
    const chunkAmount = Math.ceil(file.size / this.chunkSize);
    let uploadId: string | undefined;
    let cancelled = false;
    const finishedSubject = new Subject<void>();
    cancellation
      .pipe(takeUntil(finishedSubject), first())
      .subscribe(() => (cancelled = true));
    for (let i = 0; i < chunkAmount; i++) {
      if (i == 0)
        yield () => {
          if (cancelled) throw new Error(this.getTranslation('cancelled'));
          return this.api
            .uploadFileChunk(
              filename,
              relativeUrl,
              file.slice(0, this.chunkSize, 'application/octet-stream'),
              0,
              this.chunkSize
            )
            .pipe(
              takeUntil(cancellation),
              tap(({ uploadId: id }) => (uploadId = id)),
              tap(() => {
                if (notification instanceof ProgressNotification)
                  notification.progress = Math.round(
                    100 * (i / (chunkAmount - 1))
                  );
                this.ishtarCommunicationService.updateProgressNotification(
                  notification
                );
              })
            );
        };
      else if (i == chunkAmount - 1)
        yield () => {
          if (cancelled) throw new Error(this.getTranslation('cancelled'));
          return this.api
            .uploadFileChunk(
              filename,
              relativeUrl,
              file.slice(
                i * this.chunkSize,
                file.size,
                'application/octet-stream'
              ),
              i * this.chunkSize,
              0,
              uploadId
            )
            .pipe(
              takeUntil(cancellation),
              tap(() => {
                if (notification instanceof ProgressNotification)
                  notification.progress = 100;
                notification.label =
                  `${this.getTranslation('finishedUploading')} ` + filename;
                this.ishtarCommunicationService.updateProgressNotification(
                  notification
                );
                finishedSubject.next();
                finishedSubject.complete();
              })
            );
        };
      else
        yield () => {
          if (cancelled) throw new Error(this.getTranslation('cancelled'));
          return this.api
            .uploadFileChunk(
              filename,
              relativeUrl,
              file.slice(
                i * this.chunkSize,
                (i + 1) * this.chunkSize,
                'application/octet-stream'
              ),
              i * this.chunkSize,
              (i + 1) * this.chunkSize,
              uploadId
            )
            .pipe(
              takeUntil(cancellation),
              tap(() => {
                if (notification instanceof ProgressNotification)
                  notification.progress = Math.round(
                    100 * (i / (chunkAmount - 1))
                  );
                this.ishtarCommunicationService.updateProgressNotification(
                  notification
                );
              })
            );
        };
    }
  }

  uploadItem(
    filename: string,
    file: File,
    relativeUrl: string | undefined,
    notification: ProgressNotification
  ) {
    this.ishtarCommunicationService.addProgressNotification(notification);
    if (file.size > this.chunkSize) {
      const cancelSubject = new Subject<void>();
      notification.closeAction = () => {
        cancelSubject.next();
        cancelSubject.complete();
        setTimeout(() => {
          this.ishtarCommunicationService.addNotification(
            new Notification({
              label: `${this.getTranslation('cancelledUploading')} ` + filename,
              type: NotificationType.Info,
            })
          );
        }, 0);
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        notification.closeAction = () => {};
      };
      const handler = this.handleChunkUpload(
        filename,
        file,
        relativeUrl,
        notification,
        cancelSubject.asObservable()
      );
      return handler.next().value!()!.pipe(
        switchMap(() => from(handler).pipe(concatMap((o) => o()!))),
        map(({ fileLocation, sharepointId }) => ({
          fileLocation,
          sharepointId,
        })),
        catchError(() => {
          this.ishtarCommunicationService.updateNotification(
            new Notification({
              ...notification,
              label: `${this.getTranslation('errorUploading')}: ${filename}`,
              type: NotificationType.Error,
            })
          );
          return of(undefined);
        })
      );
    }
    return this.api.uploadLibraryFile(filename, file, relativeUrl).pipe(
      tap(() => {
        if (notification instanceof ProgressNotification)
          notification.progress = 100;
        notification.label =
          `${this.getTranslation('finishedUploading')} ` + filename;
        this.ishtarCommunicationService.updateProgressNotification(
          notification
        );
      }),
      catchError(() => {
        this.ishtarCommunicationService.updateNotification(
          new Notification({
            ...notification,
            label: `${this.getTranslation('errorUploading')}: ${filename}`,
            type: NotificationType.Error,
          })
        );
        return of(undefined);
      })
    );
  }
}
