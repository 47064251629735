import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import * as fromTilePage from './tilepage/tilepage.reducer';
import * as fromMetadata from './metadata/metadata.reducer';
import * as fromLibrary from './library/library.reducer';
import * as fromNotification from './notification/notification.reducer';

export const featureKey = 'coreFeature';

export interface CoreState {
  [fromTilePage.featureSlice]: fromTilePage.State;
  [fromMetadata.featureSlice]: fromMetadata.State;
  [fromLibrary.featureSlice]: fromLibrary.State;
  [fromNotification.featureSlice]: fromNotification.State;
}

export const reducers = new InjectionToken<ActionReducerMap<CoreState>>(
  featureKey,
  {
    factory: () => ({
      [fromTilePage.featureSlice]: fromTilePage.Reducer,
      [fromMetadata.featureSlice]: fromMetadata.Reducer,
      [fromLibrary.featureSlice]: fromLibrary.Reducer,
      [fromNotification.featureSlice]: fromNotification.Reducer,
    }),
  }
);
