import { BaseEntity } from 'processdelight-angular-components';
import { ColumnType } from '../enums/column-type.enum';
import { MetadataChoice } from './metadata-choice.model';
import { MetadataParamConsolidatedChoice } from './metadata-param-consolidated-choice.model';
import { MetadataParamLanguage } from './metadata-param-language.model';

export class MetadataParam extends BaseEntity {
  title!: string;
  tooltip?: string;
  type!: ColumnType;
  required = false;
  fileNameParam = false;
  modifiedByParam = false;
  modifiedOnParam = false;
  createdOnParam = false;
  createdByParam = false;
  showGroups = true;
  showUsers = true;
  multi = false;
  format?: string;
  maxLength?: number;
  consolidatedChoices: MetadataParamConsolidatedChoice[] = [];
  hasTranslations = false;
  languages: MetadataParamLanguage[] = [];

  choices: MetadataChoice[] = [];

  constructor(obj: Partial<MetadataParam>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.choices)
      this.choices = obj.choices
        .map((c) => new MetadataChoice(c))
        .sort((a, b) => a.position - b.position);
    if (obj.consolidatedChoices)
      this.consolidatedChoices = obj.consolidatedChoices.map(
        (c) => new MetadataParamConsolidatedChoice(c)
      );
    if (obj.languages)
      this.languages = obj.languages.map((l) => new MetadataParamLanguage(l));
    if (typeof obj.type == 'string')
      this.type = ColumnType[obj.type as keyof typeof ColumnType];
  }
}
