import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateTime } from 'luxon';
import {
  GroupUser,
  MY_FORMATS,
  MicrosoftAuthenticationService,
  ProgressNotification,
} from 'processdelight-angular-components';
import {
  Subject,
  first,
  forkJoin,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import { MetadataInputsComponent } from 'src/app/core/components/metadata-inputs/metadata-inputs.component';
import { CoreModule } from 'src/app/core/core.module';
import { translations$ } from 'src/app/core/data/data.observables';
import { ColumnType } from 'src/app/core/domain/enums/column-type.enum';
import { AADUser } from 'src/app/core/domain/models/aad-user.model';
import { LibraryItem } from 'src/app/core/domain/models/item.model';
import { Library } from 'src/app/core/domain/models/library.model';
import { MetadataParam } from 'src/app/core/domain/models/metadata-param.model';
import { Metadata } from 'src/app/core/domain/models/metadata.model';
import {
  MetadataFilterType,
  MetadataType,
  compareMetadataValues,
} from 'src/app/core/helper/metadata.functions';
import { ApiService } from 'src/app/core/services/api.service';
import { LibraryIconService } from 'src/app/core/services/library-icon.service';
import { LibraryFacade } from 'src/app/core/store/library/library.facade';
import { MetadataFacade } from 'src/app/core/store/metadata/metadata.facade';
import * as uuid from 'uuid';
import { ItemUploadService } from '../item-upload.service';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { LinkedItem } from 'src/app/core/domain/models/linked-item.model';

export class DropZoneData {
  files: File[] = [];
  library?: Library;
  filterValue: {
    [key: string]: string | { start: Date | undefined; end: Date | undefined };
  } = {};
  linkedItems: { [key: string]: string[] } = {};
}

enum SaveAction {
  Create,
  Update,
  File,
  Cancelled,
}

@Component({
  selector: 'app-drop-zone',
  standalone: true,
  imports: [
    CoreModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    MetadataInputsComponent,
    FormsModule,
    ScrollingModule,
    MatTooltipModule,
  ],
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS,
    },
  ],
})
export class DropZoneComponent implements OnInit, AfterViewInit, OnDestroy {
  files: File[] = [];
  library?: Library;
  filterValue: {
    [key: string]: MetadataFilterType;
  } = {};
  linkedItems: { [key: string]: string[] } = {};

  fileValueMap: {
    [key: string]: {
      [key: string]: MetadataType;
    };
  } = {};

  updateMap: { [key: string]: boolean } = {};
  formValidMap: { [key: string]: boolean } = {};

  metadataLoadedIn: LibraryItem[] = [];
  loadedInFiles: string[] = [];

  existingMetadataMap: { [key: string]: string | undefined } = {};
  existingMetadataFiles: string[] = [];

  get formValid() {
    return !Object.values(this.formValidMap).some((b) => !b);
  }

  getLibraryForParamIdFunc!: (
    paramId: string,
    value: string
  ) => string | undefined;

  fileNameParam?: MetadataParam;
  otherFixedParams: MetadataParam[] = [];

  @ViewChildren(MetadataInputsComponent)
  metadataInputs!: MetadataInputsComponent[];

  linkedFileMap: { [key: string]: File[] } = {};

  destroy$ = new Subject<void>();

  initialized = false;

  fileTrackByFn = (_index: number, item: File) => item.name;

  viewPortItemSize = 100;

  @ViewChild('dialogContent') dialogContent?: ElementRef<HTMLDivElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: DropZoneData,
    private libraryIconService: LibraryIconService,
    private api: ApiService,
    private libraryFacade: LibraryFacade,
    private metadataFacade: MetadataFacade,
    private msal: MicrosoftAuthenticationService,
    private itemUploadService: ItemUploadService
  ) {
    Object.assign(this, data);
  }

  getTranslation(label: string): string {
    return translations$.value[label];
  }
  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  ngOnInit(): void {
    const files = Array.from(this.files);
    this.files = files;
    this.metadataFacade.metadataParams$.pipe(first()).subscribe((params) => {
      this.fileNameParam = params.find((f) => f.fileNameParam);
      this.otherFixedParams = params.filter(
        (f) =>
          f.createdByParam ||
          f.createdOnParam ||
          f.modifiedByParam ||
          f.modifiedOnParam
      );
      const mappedValue = Object.fromEntries(
        Object.entries(this.filterValue)
          .filter(
            ([k]) => this.otherFixedParams.filter((f) => f.id == k).length == 0
          )
          .map(([k, v]) => {
            const param = params.find((p) => p.id == k);
            if (v && typeof v == 'object' && ('start' in v || 'end' in v))
              return [k, v.start ?? v.end];
            else if (v && param?.multi) {
              const value: any[] = [];
              if (Array.isArray(v)) value.push(...v);
              else value.push(v);
              if (param.type == ColumnType.Choice) {
                return [
                  k,
                  value.filter(
                    (v) => param.choices.findIndex((c) => c.id == v) != -1
                  ),
                ];
              } else if (param.type == ColumnType.ConsolidatedChoice) {
                const stringArray = value as string[];
                const foundChoices = param.consolidatedChoices.flatMap(
                  (c) =>
                    params.find((p) => p.id == c.metadataConsolidatedChoiceId)
                      ?.choices ?? []
                );
                return [
                  k,
                  stringArray.filter(
                    (v) => foundChoices.findIndex((c) => v.endsWith(c.id)) != -1
                  ),
                ];
              } else return [k, value];
            }
            return [
              k,
              v && typeof v == 'object' && ('start' in v || 'end' in v)
                ? v.start ?? v.end
                : v,
            ];
          })
          .filter(([_, v]) => v !== undefined)
      );
      this.files.forEach((f) => {
        const fileValue = { ...mappedValue };

        if (this.fileNameParam)
          fileValue[this.fileNameParam.id] = this.getFileName(f);
        this.fileValueMap[f.name] = fileValue;
        this.updateMap[f.name] = false;
        this.formValidMap[f.name] = false;
      });

      this.files.forEach((f) => {
        this.linkedFileMap[f.name] = [
          ...(this.linkedItems[f.name] ?? []).map(
            (v) => this.files.find((f) => f.name == v) as File
          ),
          ...Object.entries(this.linkedItems)
            .filter(([k, v]) => k != f.name && v.includes(f.name))
            .map(([k]) => this.files.find((f) => f.name == k) as File),
        ];
      });
      this.initialized = true;

      this.files.forEach((f) =>
        this.api
          .checkUploadedFiles(f.name, this.library?.id)
          .pipe(first(), withLatestFrom(this.metadataFacade.metadataParams$))
          .subscribe(([item, params]) => {
            if (!item) return;
            this.metadataLoadedIn.push(item!);
            this.existingMetadataMap[f.name] = item?.id;
            this.updateMap[f.name] = true;
            this.fileValueMap[f.name] = {};
            const val: { [key: string]: MetadataType } = {};
            item!.metadata.forEach((m) => {
              const param = params.find((p) => p.id == m.metadataParameter.id);
              if (param?.multi) {
                const value = val[m.metadataParameter.id];
                const newValue: any = m.value ?? m.groupUserValue;
                if (value) {
                  if (Array.isArray(value)) {
                    value.push(newValue);
                  } else {
                    val[m.metadataParameter.id] = [value, newValue];
                  }
                } else {
                  val[m.metadataParameter.id] = [newValue];
                }
              } else
                val[m.metadataParameter.id] = (m.dateTimeValue ??
                  m.numberValue ??
                  m.groupUserValue ??
                  m.value)!;
            });
            this.fileValueMap[f.name] = val;
            this.loadedInFiles = this.metadataLoadedIn.map((i) => i.title);
            this.existingMetadataFiles = Object.keys(
              this.existingMetadataMap
            ).filter((k) => this.existingMetadataMap[k]);
          })
      );
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.dialogContent)
        this.metadataFacade.metadataParams$
          .pipe(first())
          .subscribe((params) => {
            const paramsInView = [
              this.fileNameParam,
              ...params.filter(
                (p) =>
                  !p.fileNameParam &&
                  !p.modifiedByParam &&
                  !p.modifiedOnParam &&
                  !p.createdByParam &&
                  !p.createdOnParam &&
                  (!this.library ||
                    this.library.configuredParams.some(
                      (c) => c.paramId == p.id
                    ))
              ),
            ].length;
            const cols = Math.max(
              1,
              Math.floor(
                (this.dialogContent!.nativeElement.clientWidth - 220) / 280
              )
            );
            const rest = paramsInView % cols;
            this.viewPortItemSize = (paramsInView / cols) * 64 + 23;
            if (rest == 0) this.viewPortItemSize += 64;
          });
    }, 0);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addedParam(param: MetadataParam) {
    this.metadataInputs.forEach((m) => m.addMetadataParamToView(param));
  }

  save() {
    const date = DateTime.now().toUTC(0, { keepLocalTime: true });
    const user = new GroupUser({
      user: new AADUser({ id: this.msal.userId, mail: this.msal.email }),
    });
    const fileIds = Object.fromEntries(
      this.files.map((f) => [
        f.name,
        this.updateMap[f.name]
          ? this.metadataLoadedIn.find((i) => i.title == f.name)?.id ??
            uuid.v4()
          : uuid.v4(),
      ])
    );
    this.metadataFacade.metadataParams$
      .pipe(
        first(),
        switchMap((params) => {
          const actions: [
            SaveAction,
            File,
            string,
            LibraryItem | undefined,
            ProgressNotification
          ][] = this.files.map((f) => {
            const fileNameParam = params.find((p) => p.fileNameParam);
            const fileName = fileNameParam
              ? (this.fileValueMap[f.name][fileNameParam.id] as string)
              : f.name.split('.')[0];
            if (this.existingMetadataFiles.includes(f.name))
              return [
                SaveAction.File,
                f,
                fileName,
                undefined,
                new ProgressNotification({
                  label: `${this.getTranslation('uploadingFile')}: ` + fileName,
                  progress: 0,
                }),
              ];
            const createItem = (existing?: LibraryItem) => {
              const id = existing?.id ?? fileIds[f.name];
              const valueMap = { ...this.fileValueMap[f.name] };
              const modifiedBy = this.otherFixedParams.find(
                (p) => p.modifiedByParam
              );
              const modifiedOn = this.otherFixedParams.find(
                (p) => p.modifiedOnParam
              );
              const createdBy = this.otherFixedParams.find(
                (p) => p.createdByParam
              );
              const createdOn = this.otherFixedParams.find(
                (p) => p.createdOnParam
              );
              if (modifiedBy) valueMap[modifiedBy.id] = user;
              if (modifiedOn) valueMap[modifiedOn.id] = date;
              if (!existing) {
                if (createdBy) valueMap[createdBy.id] = user;
                if (createdOn) valueMap[createdOn.id] = date;
              } else {
                const existingCreatedBy = existing.metadata.find(
                  (m) => m.metadataParameter.id == createdBy?.id
                );
                if (createdBy && existingCreatedBy?.groupUserValue)
                  valueMap[createdBy.id] = existingCreatedBy.groupUserValue;
                const existingCreatedOn = existing.metadata.find(
                  (m) => m.metadataParameter.id == createdOn?.id
                );
                if (createdOn && existingCreatedOn?.dateTimeValue)
                  valueMap[createdOn.id] = existingCreatedOn.dateTimeValue;
              }
              return new LibraryItem({
                id: id,
                title: f.name,
                linkedItems:
                  this.linkedItems[f.name]?.map(
                    (n) =>
                      new LinkedItem({
                        linkedItemId: fileIds[n],
                        dmsItemId: undefined,
                      })
                    // ({
                    //   id: fileIds[n],
                    // } as LibraryItem)
                  ) ?? [],
                libraryId: this.library?.id,
                sharepointId: existing?.sharepointId,
                metadata: Object.entries(valueMap)
                  .filter(([_, v]) => v != undefined)
                  .reduce((acc, [k, v]) => {
                    const param = params.find((p) => p.id == k);
                    if (Array.isArray(v)) {
                      if (param?.type == ColumnType.GroupUser)
                        return [
                          ...acc,
                          ...v.map(
                            (val) =>
                              new Metadata({
                                id: existing?.metadata.find(
                                  (m) =>
                                    m.metadataParameter.id == k &&
                                    m.value == val
                                )?.id,
                                metadataParameterId: param.id,
                                itemId: id,
                                groupUserValue: val as any,
                              })
                          ),
                        ];
                      return [
                        ...acc,
                        ...v.map(
                          (val) =>
                            new Metadata({
                              id: existing?.metadata.find(
                                (m) =>
                                  m.metadataParameter.id == k && m.value == val
                              )?.id,
                              metadataParameterId: param?.id,
                              itemId: id,
                              value: val as any,
                            })
                        ),
                      ];
                    }
                    if (param?.type == ColumnType.DateTime)
                      return [
                        ...acc,
                        new Metadata({
                          id: existing?.metadata.find(
                            (m) => m.metadataParameter.id == k
                          )?.id,
                          metadataParameterId: param.id,
                          itemId: id,
                          dateTimeValue: v as DateTime,
                        }),
                      ];
                    else if (param?.type == ColumnType.Number)
                      return [
                        ...acc,
                        new Metadata({
                          id: existing?.metadata.find(
                            (m) => m.metadataParameter.id == k
                          )?.id,
                          metadataParameterId: param.id,
                          itemId: id,
                          numberValue: v as any,
                        }),
                      ];
                    else if (param?.type == ColumnType.GroupUser)
                      return [
                        ...acc,
                        new Metadata({
                          id: existing?.metadata.find(
                            (m) => m.metadataParameter.id == k
                          )?.id,
                          metadataParameterId: param.id,
                          itemId: id,
                          groupUserValue: v as any,
                        }),
                      ];
                    else
                      return [
                        ...acc,
                        new Metadata({
                          id: existing?.metadata.find(
                            (m) => m.metadataParameter.id == k
                          )?.id,
                          metadataParameterId: param?.id,
                          itemId: id,
                          value: v as any,
                        }),
                      ];
                  }, [] as Metadata[]),
              });
            };
            if (this.updateMap[f.name])
              return [
                SaveAction.Update,
                f,
                fileName,
                createItem(
                  this.metadataLoadedIn.find((i) => i.title == f.name)
                ),
                new ProgressNotification({
                  label: `${this.getTranslation('uploadingFile')}: ` + fileName,
                  progress: 0,
                }),
              ];
            return [
              SaveAction.Create,
              f,
              fileName,
              createItem(),
              new ProgressNotification({
                label: `${this.getTranslation('uploadingFile')}: ` + fileName,
                progress: 0,
              }),
            ];
          });
          return forkJoin(
            actions.map(([action, file, fileName, item, notification]) =>
              this.itemUploadService
                .uploadItem(
                  [fileName, file.name.split('.').pop()].join('.'),
                  file,
                  this.library?.sharepointUrl?.split('sharepoint.com').pop(),
                  notification
                )
                .pipe(
                  map((data) => {
                    if (!data?.sharepointId)
                      return [SaveAction.Cancelled, file, undefined] as [
                        SaveAction,
                        File,
                        LibraryItem | undefined
                      ];
                    if (item && data?.fileLocation)
                      item.fileLocation = data.fileLocation;
                    if (item && data?.sharepointId)
                      item.sharepointId = data.sharepointId;
                    return [action, file, item] as [
                      SaveAction,
                      File,
                      LibraryItem | undefined
                    ];
                  })
                )
            )
          ).pipe(
            switchMap((actions) => {
              const toCreate = actions
                .filter((a) => a[0] == SaveAction.Create)
                .map((a) => a[2]!);
              const toUpdate = actions
                .filter((a) => a[0] == SaveAction.Update)
                .map((a) => a[2]!);
              return forkJoin([
                toCreate.length
                  ? this.libraryFacade.createLibraryItems$(toCreate)
                  : of(true),
                toUpdate.length
                  ? this.libraryFacade.updateLibraryItems$(toUpdate)
                  : of(true),
              ]);
            })
          );
        })
      )
      .subscribe();
  }

  getLibraryIcon(file: File) {
    return this.libraryIconService.provideIcon(file.name);
  }

  getFileName(file: File) {
    return file.name.split('.').slice(0, -1).join('.');
  }

  private copyValue(value: MetadataType): MetadataType {
    if (Array.isArray(value)) return [...value] as string[] | GroupUser[];
    return value;
  }

  setAllMetadata(value: { [key: string]: MetadataType }) {
    this.files.slice(1).forEach((f) => {
      this.updateFileValueMap(f, {
        [this.fileNameParam!.id]:
          this.fileValueMap[f.name][this.fileNameParam!.id],
        ...Object.fromEntries(
          Object.entries(value)
            .filter(([k]) => this.fileNameParam?.id != k)
            .map(([k, v]) => [k, this.copyValue(v)])
        ),
      });
      this.formValidMap[f.name] =
        this.formValidMap[this.files[0].name] &&
        this.fileValueMap[f.name][this.fileNameParam!.id] != undefined;
    });
  }

  copyMetadataFrom(from: File, to: File) {
    this.updateFileValueMap(to, {
      [this.fileNameParam!.id]:
        this.fileValueMap[to.name][this.fileNameParam!.id],
      ...Object.fromEntries(
        Object.entries(this.fileValueMap[from.name])
          .filter(([k]) => this.fileNameParam?.id != k)
          .map(([k, v]) => [k, this.copyValue(v)])
      ),
    });

    this.formValidMap[to.name] = this.formValidMap[from.name];
  }

  getOtherFiles(file: File) {
    return this.files.filter((f) => f.name != file.name);
  }

  updateFileValueMap(
    file: File,
    value: {
      [key: string]: MetadataFilterType;
    }
  ) {
    const v = value as {
      [key: string]: MetadataType;
    };
    if (!compareMetadataValues(this.fileValueMap[file.name], v)) {
      this.fileValueMap[file.name] = v;
      this.metadataFacade.metadataParams$.pipe(first()).subscribe((params) => {
        const tempId = uuid.v4();
        this.api
          .checkExistingMetadata(
            Object.entries(this.fileValueMap[file.name])
              .filter(([_, v]) => v != undefined)
              .reduce((acc, [k, v]) => {
                const param = params.find((p) => p.id == k);
                if (Array.isArray(v)) {
                  if (param?.type == ColumnType.GroupUser)
                    return [
                      ...acc,
                      ...v.map(
                        (x) =>
                          new Metadata({
                            metadataParameterId: param?.id,
                            itemId: tempId,
                            groupUserValue: x as any,
                          })
                      ),
                    ];
                  return [
                    ...acc,
                    ...v.map(
                      (x) =>
                        new Metadata({
                          metadataParameterId: param?.id,
                          itemId: tempId,
                          value: x as any,
                        })
                    ),
                  ];
                }
                if (param?.type == ColumnType.DateTime)
                  return [
                    ...acc,
                    new Metadata({
                      metadataParameterId: param?.id,
                      itemId: tempId,
                      dateTimeValue: v as DateTime,
                    }),
                  ];
                else if (param?.type == ColumnType.Number)
                  return [
                    ...acc,
                    new Metadata({
                      metadataParameterId: param?.id,
                      itemId: tempId,
                      numberValue: v as any,
                    }),
                  ];
                else if (param?.type == ColumnType.GroupUser)
                  return [
                    ...acc,
                    new Metadata({
                      metadataParameterId: param?.id,
                      itemId: tempId,
                      groupUserValue: v as any,
                    }),
                  ];
                else
                  return [
                    ...acc,
                    new Metadata({
                      metadataParameterId: param?.id,
                      itemId: tempId,
                      value: v as any,
                    }),
                  ];
              }, [] as Metadata[]),
            file.name.split('.').pop()!,
            undefined,
            this.library?.id
          )
          .subscribe((id) => {
            this.existingMetadataMap[file.name] = id;
            this.updateMap[file.name] = !!id;
            this.existingMetadataFiles = Object.keys(
              this.existingMetadataMap
            ).filter((k) => this.existingMetadataMap[k]);
          });
      });
    }
  }
  getToolTipString(file: File) {
    let toolTipstring = '';
    this.loadedInFiles.includes(file.name)
      ? (toolTipstring += this.getTranslation('fileAlreadyUploaded'))
      : '';
    this.existingMetadataFiles.includes(file.name)
      ? (toolTipstring += '\n  ' + this.getTranslation('onlyFileWillBeUpdated'))
      : '';
    return toolTipstring;
  }
}
