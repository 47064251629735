import { Injectable } from '@angular/core';
import { Observable, filter, first, map } from 'rxjs';
import { license$ } from '../data/data.observables';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  public currentLicenses$ = license$
    .asObservable()
    .pipe(
      filter((userLicenseInfo) => !!userLicenseInfo && !!Object.keys(userLicenseInfo).length),
      first(),
      map(userLicenseInfo => userLicenseInfo as UserLicenseInfo)
    );

  public isUserHavingBasicLicense(requiredLicense: string): Observable<boolean> {
    return this.currentLicenses$
      .pipe(
        map(userLicenseInfo => userLicenseInfo
          .licenses
          .some((license) => license.productName === requiredLicense))
      );
  }

  public isUserHavingAdminLicense(requiredLicense: string): Observable<boolean> {
    return this.currentLicenses$
      .pipe(
        map(userLicenseInfo => userLicenseInfo
          .licenses
          .some((license) => license.productName === requiredLicense && license.isAdmin))
      );
  }
}
