<div class="dialogContainer">
  <h2 mat-dialog-title>{{ getTranslation$("copyDocuments") | async }}</h2>
  <mat-icon class="closeButtonIcon" (click)="close()">close</mat-icon>
  <div
    mat-dialog-content
    class="p-3 d-flex flex-column justify-content-center align-items-start"
  >
    <ng-container *ngIf="itemsWithNoPermissions.length">
      <p class="mb-0">
        {{ getTranslation$("insufficientPermissionsToCopyDocuments") | async }}
        ({{ itemsWithNoPermissions.length }}).
      </p>
      <p>{{ getTranslation$("copyDocumentsConfirmNotCopied") | async }}</p>
      <div
        class="rounded border border-gray mb-3 w-100 overflow-auto scrollbar"
        style="max-height: 16rem"
      >
        <mat-action-list class="p-0">
          <mat-list-item *ngFor="let item of itemsWithNoPermissions">
            <div class="w-100 d-flex flex-row align-items-center">
              <img
                class="ms-2 pe-2"
                [src]="getIcon(item)"
                width="24"
                height="24"
                alt="file icon"
              />
              <div>
                {{ item.title }}
              </div>
            </div>
          </mat-list-item>
        </mat-action-list>
      </div>
    </ng-container>
    <p>{{ getTranslation$("nameCopiedItems") | async }}</p>
    <div
      [formGroup]="form"
      class="d-flex flex-column w-100 overflow-auto scrollbar pt-2"
      style="max-height: 16rem; overflow-x: hidden !important"
    >
      <mat-form-field
        class="w-100 mb-2"
        appearance="outline"
        *ngFor="let item of items"
      >
        <mat-label>{{ originalFileNames[item.id] }}</mat-label>
        <img
          matPrefix
          class="ms-2 pe-2"
          [src]="getIcon(item)"
          width="24"
          height="24"
          alt="file icon"
        />
        <input matInput type="text" [formControlName]="item.id" />
        <mat-error *ngIf="getControl(item.id).errors?.required">{{
          getTranslation$("required") | async
        }}</mat-error>
        <mat-error *ngIf="getControl(item.id).errors?.nameExists">{{
          getTranslation$("fileNameAlreadyExists") | async
        }}</mat-error>
      </mat-form-field>
      <div
        class="w-100 d-flex flex-row align-items-center"
        *ngIf="!items.length"
      >
        <div>
          {{ getTranslation$("noValidDocuments") | async }}
        </div>
      </div>
    </div>
    <div class="w-100 d-flex flex-row justify-content-center mt-2">
      <button
        mat-raised-button
        class="menuButton"
        (click)="confirm()"
        [disabled]="!formValid || !items.length"
        class="me-2"
      >
        <mat-icon>check</mat-icon>
        <span>{{ getTranslation$("confirm") | async }}</span>
      </button>
      <button mat-raised-button class="menuButton" (click)="close()">
        <mat-icon>cancel</mat-icon>
        <span>{{ getTranslation$("cancel") | async }}</span>
      </button>
    </div>
  </div>
</div>
