import { GroupUser } from 'processdelight-angular-components';
import { DateTime } from 'luxon';

export class DateStartEnd {
  start?: DateTime;
  end?: DateTime;
}

export type MetadataFilterType =
  | string
  | number
  | DateTime
  | GroupUser
  | GroupUser[]
  | string[]
  | DateStartEnd;
export type MetadataType =
  | string
  | number
  | DateTime
  | GroupUser
  | GroupUser[]
  | string[];

export function dateStartEndTypeGuard(value: MetadataFilterType) {
  return (
    value && typeof value == 'object' && 'start' in value && 'end' in value
  );
}
function dateTimeMilliseconds(value: DateTime | undefined) {
  return value?.valueOf();
}

export function compareMetadataValues(
  v1: {
    [key: string]: MetadataFilterType;
  },
  v2: {
    [key: string]: MetadataFilterType;
  }
): boolean {
  if (v1 === undefined && v2 === undefined) return true;
  else if (v1 === undefined || v2 === undefined) return false;
  const keys1 = Object.keys(v1).sort((a, b) => a.localeCompare(b));
  const keys2 = Object.keys(v2).sort((a, b) => a.localeCompare(b));
  if (keys1.length != keys2.length) return false;
  if (keys1.some((k, i) => k != keys2[i])) return false;
  const checkValues = (
    val1: MetadataFilterType,
    val2: MetadataFilterType
  ): boolean => {
    if (typeof val1 != typeof val2) return false;
    if (Array.isArray(val1) != Array.isArray(val2)) return false;
    if (Array.isArray(val1)) {
      if (val1.length != (val2 as unknown[]).length) return false;
      if (val1.length == 0) return true;
      if (val1[0] instanceof GroupUser) {
        const val1Sorted = [...(val1 as GroupUser[])].sort(
          (a, b) => a.id?.localeCompare(b.id ?? '') ?? 0
        );
        const val2Sorted = [...(val2 as GroupUser[])].sort(
          (a, b) => a.id?.localeCompare(b.id ?? '') ?? 0
        );
        return !val1Sorted.some((v, i) => !checkValues(v, val2Sorted[i]));
      } else {
        const val1Sorted = [...(val1 as string[])].sort((a, b) =>
          a.localeCompare(b)
        );
        const val2Sorted = [...(val2 as string[])].sort((a, b) =>
          a.localeCompare(b)
        );
        return !val1Sorted.some((v, i) => !checkValues(v, val2Sorted[i]));
      }
    }
    if (val1 instanceof DateTime != val2 instanceof DateTime) return false;
    if (dateStartEndTypeGuard(val1) != dateStartEndTypeGuard(val2))
      return false;
    if (val1 instanceof GroupUser != val2 instanceof GroupUser) return false;
    if (val1 instanceof DateTime)
      return (
        dateTimeMilliseconds(val1) == dateTimeMilliseconds(val2 as DateTime)
      );
    if (dateStartEndTypeGuard(val1)) {
      const d1 = v1 as DateStartEnd;
      const d2 = v2 as DateStartEnd;
      return (
        dateTimeMilliseconds(d1.start) == dateTimeMilliseconds(d2.start) &&
        dateTimeMilliseconds(d1.end) == dateTimeMilliseconds(d2.end)
      );
    }
    if (val1 instanceof GroupUser) return val1.id == (val2 as GroupUser).id;
    return val1 == val2;
  };
  return !keys1.some((k) => !checkValues(v1[k], v2[k]));
}
