import { Injectable } from '@angular/core';
import { MicrosoftAuthenticationService } from 'processdelight-angular-components';
import { environment } from 'src/environments/environment';
import { license$ } from '../data/data.observables';
@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  apiBase = `${environment.ishtarFunctions}/api`;
  constructor(private msal: MicrosoftAuthenticationService) {}
  private createApiEndpointUrl(path: string) {
    const url = new URL(`${this.apiBase}/${path}`);
    if (environment.ishtarFunctionsKey.trim() !== '')
      url.searchParams.append('code', environment.ishtarFunctionsKey);
    return url.toString();
  }

  getFileLocation(relativeUrl: string) {
    const sharepointUrl = license$.value!.sharepointUrl;
    const sp = 'sharepoint.com/';
    const index = sharepointUrl.indexOf(sp) + sp.length - 1;
    return `${sharepointUrl.substring(0, index)}${relativeUrl}`;
  }

  getSPValueUrl(relativeUrl: string, preview = false) {
    const sharepointUrl = license$.value?.sharepointUrl;
    const split = relativeUrl.split('/').filter((s) => s);
    let filename = split.pop()!;
    const filenameSplit = filename.split('.');
    const extension = filenameSplit.pop()!;
    filename = filenameSplit.join('.');
    const library = split.join('/');
    return this.createApiEndpointUrl(
      `sharepoint/${this.msal.tenantId}/libraries/${encodeURIComponent(
        encodeURIComponent(library)
      )}/${encodeURIComponent(
        filename
      )}.${extension}?sharepointUrl=${sharepointUrl}&preview=${preview}`
    );
  }
  getSPValueUrlById(sharepointId: string, preview = false) {
    const sharepointUrl = license$.value?.sharepointUrl;
    return this.createApiEndpointUrl(
      `sharepoint/${this.msal.tenantId}/libraries/files/value?sharepointUrl=${sharepointUrl}&fileId=${sharepointId}&preview=${preview}`
    );
  }
}
