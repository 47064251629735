import { createAction, props } from '@ngrx/store';
import { MetadataFilter } from '../../domain/models/metadata-filter.model';
import { TilePageSegment } from '../../domain/models/tile-page-segment.model';
import { TilePage } from '../../domain/models/tile-page.model';
import { Permission } from '../../domain/models/permission.model';
import { Tile } from '../../domain/models/tile.model';
import { ActionBase } from '../action-base.interface';

export const getTilePages = createAction(
  '[TilePage] Get tile pages',
  props<ActionBase<TilePage[]>>()
);
export const getTilePagesResolved = createAction(
  '[TilePage] Get tile pages resolved',
  props<{
    pages: TilePage[];
  }>()
);
export const createTilePage = createAction(
  '[TilePage] Create tile page',
  props<
    {
      page: TilePage;
    } & ActionBase<TilePage>
  >()
);
export const createTilePageResolved = createAction(
  '[TilePage] Create tile page resolved',
  props<{
    page: TilePage;
  }>()
);
export const updateTilePage = createAction(
  '[TilePage] Update tile page',
  props<
    {
      page: TilePage;
    } & ActionBase<TilePage>
  >()
);
export const updateTilePageResolved = createAction(
  '[TilePage] Update tile page resolved',
  props<{
    page: TilePage;
  }>()
);
export const deleteTilePage = createAction(
  '[TilePage] Delete tile page',
  props<
    {
      tilePageId: string;
    } & ActionBase<string>
  >()
);
export const deleteTilePageResolved = createAction(
  '[TilePage] Delete tile page resolved',
  props<{
    tilePageId: string;
  }>()
);
export const createSegment = createAction(
  '[TilePage] Create segment',
  props<
    {
      segment: TilePageSegment;
      tilePageId: string;
    } & ActionBase<TilePageSegment>
  >()
);
export const createSegmentResolved = createAction(
  '[TilePage] Create segment resolved',
  props<{
    segment: TilePageSegment;
    tilePageId: string;
  }>()
);
export const updateSegment = createAction(
  '[TilePage] Update segment',
  props<
    {
      segment: TilePageSegment;
      tilePageIds: string[];
      previousTilePageIds: string[];
    } & ActionBase<TilePageSegment>
  >()
);
export const updateSegmentResolved = createAction(
  '[TilePage] Update segment resolved',
  props<{
    segment: TilePageSegment;
    tilePageIds: string[];
    previousTilePageIds: string[];
  }>()
);
export const updateSegments = createAction(
  '[TilePage] Update segments',
  props<
    {
      segments: TilePageSegment[];
      tilePageId: string;
    } & ActionBase<TilePageSegment[]>
  >()
);
export const updateSegmentsResolved = createAction(
  '[TilePage] Update segments resolved',
  props<{
    segments: TilePageSegment[];
    tilePageId: string;
  }>()
);
export const deleteSegment = createAction(
  '[TilePage] Delete segment',
  props<
    {
      segmentId: string;
    } & ActionBase<string>
  >()
);
export const deleteSegmentResolved = createAction(
  '[TilePage] Delete segment resolved',
  props<{
    segmentId: string;
  }>()
);
export const createTile = createAction(
  '[TilePage] Create tile',
  props<
    {
      tile: Tile;
      segmentId: string;
    } & ActionBase<Tile>
  >()
);
export const createTileResolved = createAction(
  '[TilePage] Create tile resolved',
  props<{
    tile: Tile;
    segmentId: string;
  }>()
);
export const updateTile = createAction(
  '[TilePage] Update tile',
  props<
    {
      tile: Tile;
      segmentIds: string[];
      previousSegmentIds: string[];
    } & ActionBase<Tile>
  >()
);
export const updateTileResolved = createAction(
  '[TilePage] Update tile resolved',
  props<{
    tile: Tile;
    segmentIds: string[];
    previousSegmentIds: string[];
  }>()
);
export const updateTileByIdResolved = createAction(
  '[TilePage] Update tile by id resolved',
  props<{
    tiles: Tile[];
  }>()
);
export const updateTiles = createAction(
  '[TilePage] Update tiles',
  props<
    {
      tiles: Tile[];
    } & ActionBase<Tile[]>
  >()
);
export const updateTilesResolved = createAction(
  '[TilePage] Update tiles resolved',
  props<{
    tiles: Tile[];
  }>()
);
export const deleteTile = createAction(
  '[TilePage] Delete tile',
  props<
    {
      tileId: string;
    } & ActionBase<string>
  >()
);
export const deleteTileResolved = createAction(
  '[TilePage] Delete tile resolved',
  props<{
    tileId: string;
  }>()
);
export const createTilePagePermission = createAction(
  '[TilePage] Create tile page permission',
  props<
    {
      permission: Permission;
      tilePageId: string;
    } & ActionBase<Permission>
  >()
);
export const createTilePagePermissionResolved = createAction(
  '[TilePage] Create tile page permission resolved',
  props<{
    permission: Permission;
    tilePageId: string;
  }>()
);
export const updateTilePagePermissions = createAction(
  '[TilePage] Update tile page permissions',
  props<
    {
      permissions: Permission[];
    } & ActionBase<Permission[]>
  >()
);
export const updateTilePagePermissionsResolved = createAction(
  '[TilePage] Update tile page permissions resolved',
  props<{
    permissions: Permission[];
  }>()
);
export const deleteTilePagePermissions = createAction(
  '[TilePage] Delete tile page permissions',
  props<
    {
      permissionIds: string[];
    } & ActionBase<string[]>
  >()
);
export const deleteTilePagePermissionsResolved = createAction(
  '[TilePage] Delete tile page permissions resolved',
  props<{
    permissionIds: string[];
  }>()
);
export const updateMetadataFilters = createAction(
  '[TilePage] Update tile metadata filters',
  props<
    {
      tileId: string;
      previousFilters: MetadataFilter[];
      filters: MetadataFilter[];
    } & ActionBase<MetadataFilter[]>
  >()
);
export const updateMetadataFiltersResolved = createAction(
  '[TilePage] Update tile metadata filters resolved',
  props<{ tileId: string; filters: MetadataFilter[] }>()
);

export const updateCurrentTilePage = createAction(
  '[TilePage] Update current tile page',
  props<{
    tilePage: TilePage;
  }>()
);

export const resetSlice = createAction('[TilePage] Reset slice');
