import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BreadCrumbService,
  BreadcrumbItem,
  MicrosoftAuthenticationService,
} from 'processdelight-angular-components';
import { filter, first, map } from 'rxjs';
import { translations$ } from 'src/app/core/data/data.observables';
import { TileAction } from 'src/app/core/domain/enums/tile-action.enum';
import { Tile } from 'src/app/core/domain/models/tile.model';
import { LibraryFacade } from 'src/app/core/store/library/library.facade';
import { TilePageFacade } from 'src/app/core/store/tilepage/tilepage.facade';
import { environment } from 'src/environments/environment';

@Component({
  standalone: true,
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  imports: [CommonModule],
})
export class TileComponent implements OnInit {
  @Input() tile!: Tile;

  @Input() size = 80;

  @Input() intranet = false;

  fontSize = 10;

  isPlaceholder = true;

  notFoundImage?: SafeResourceUrl;

  isDisabled = false;

  objectUrl?: SafeResourceUrl;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly tilePageFacade: TilePageFacade,
    private readonly libraryFacade: LibraryFacade,
    private readonly msal: MicrosoftAuthenticationService,
    private readonly sanitizer: DomSanitizer,
    private breadcrumbService: BreadCrumbService
  ) {}

  getTranslation(label: string): string {
    return translations$.value[label];
  }
  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  ngOnInit(): void {
    this.fontSize = Math.max(this.size / 6, 10);
    this.isPlaceholder = this.tile.tileActionType == TileAction.Placeholder;
    this.notFoundImage = this.transform(environment.cdnImageNotFound);
    this.objectUrl = this.transform(this.tile.iconUrl);
    if (this.tile.tileActionType == TileAction.LibraryFilter)
      this.libraryFacade
        .libraryById$(this.tile.tileAction)
        .pipe(
          first(),
          filter((l) => !!l)
        )
        .subscribe(
          (l) =>
            (this.isDisabled =
              !!l?.permissions?.length &&
              !l.permissions.some(
                ({ permission }) =>
                  permission.groupUser.user?.id == this.msal.userId ||
                  permission.groupUser.group?.members?.some(
                    (m) => m.id == this.msal.userId
                  )
              ))
        );
    else if (this.tile.tileActionType == TileAction.TilePage)
      this.tilePageFacade.getTilePageByIdFunc$
        .pipe(
          first(),
          map((fn) => fn(this.tile.tileAction))
        )
        .subscribe(
          (t) =>
            (this.isDisabled =
              !!t?.permissions?.length &&
              !t.permissions.some(
                ({ permission }) =>
                  permission.groupUser.user?.id == this.msal.userId ||
                  permission.groupUser.group?.members?.some(
                    (m) => m.id == this.msal.userId
                  )
              ))
        );
  }

  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  open() {
    if (this.isDisabled) return;
    switch (this.tile.tileActionType) {
      case TileAction.TilePage:
        this.tilePageFacade.getTilePageByIdFunc$
          .pipe(first())
          .subscribe((fn) => {
            const page = fn(this.tile.tileAction);
            if (page) {
              this.breadcrumbService.addBreadcrumb(
                new BreadcrumbItem({
                  label: page.title,
                  action: () =>
                    this.router.navigate([
                      this.route.snapshot.routeConfig?.path + '/' + page.title,
                    ]),
                })
              );
              this.router.navigate([
                this.route.snapshot.routeConfig?.path + '/' + page.title,
              ]);
            }
          });
        break;
      case TileAction.LibraryFilter:
      case TileAction.Library:
        if (this.tile.tileAction) {
          this.libraryFacade
            .libraryById$(this.tile.tileAction)
            .pipe(first())
            .subscribe((library) => {
              this.breadcrumbService.addBreadcrumb(
                new BreadcrumbItem({
                  label: library?.title,
                  action: () =>
                    this.router.navigate(
                      [
                        this.route.snapshot.routeConfig?.path +
                          '/' +
                          library?.title,
                      ],
                      { queryParams: { tileId: this.tile.id } }
                    ),
                })
              );
              this.router.navigate(
                [this.route.snapshot.routeConfig?.path + '/' + library?.title],
                { queryParams: { tileId: this.tile.id } }
              );
            });
        } else {
          this.breadcrumbService.addBreadcrumb(
            new BreadcrumbItem({
              label: this.tile.title,
              action: () =>
                this.router.navigate([this.route.snapshot.routeConfig?.path], {
                  queryParams: { tileId: this.tile.id },
                }),
            })
          );
          this.router.navigate([this.route.snapshot.routeConfig?.path], {
            queryParams: { tileId: this.tile.id },
          });
        }
        // else this.libraryTileActionService.libraryTileClicked(this.tile);
        break;
      default:
        console.error(this.tile, this.getTranslation('incorrectTileAction'));
    }
  }

  imgErrored(event: ErrorEvent) {
    const img = event.target as HTMLImageElement;
    img.src = environment.cdnImageNotFound;
    // if (this.notFoundImage) img.src = this.notFoundImage as string;
  }
}
