import { createAction, props } from '@ngrx/store';
import { ActionBase } from '../action-base.interface';
import {
  ApplicationNotification,
  CreateNotificationRequest,
} from 'processdelight-angular-components';

export const getNotifications = createAction(
  '[Notification] Get notifications',
  props<ActionBase<ApplicationNotification[]>>()
);
export const getNotificationsResolved = createAction(
  '[Notification] Get notifications resolved',
  props<{
    notifications: ApplicationNotification[];
  }>()
);
export const createNotification = createAction(
  '[Notification] Create notification',
  props<
    {
      notification: CreateNotificationRequest;
    } & ActionBase<ApplicationNotification>
  >()
);
export const createNotificationResolved = createAction(
  '[Notification] Create notification resolved',
  props<{
    notification: ApplicationNotification;
  }>()
);
export const updateNotification = createAction(
  '[Notification] Update notification',
  props<
    {
      notification: CreateNotificationRequest;
    } & ActionBase<ApplicationNotification>
  >()
);
export const updateNotificationResolved = createAction(
  '[Notification] Update notification resolved',
  props<{
    notification: ApplicationNotification;
  }>()
);
export const deleteNotification = createAction(
  '[Notification] Delete notification',
  props<
    {
      notificationId: string;
    } & ActionBase<string>
  >()
);
export const deleteNotificationResolved = createAction(
  '[Notification] Delete notification resolved',
  props<{
    notificationId: string;
  }>()
);
export const resetSlice = createAction('[Notification] Reset slice');
