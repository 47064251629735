import { BaseEntity, Language } from 'processdelight-angular-components';
import { MetadataParam } from './metadata-param.model';

export class MetadataParamLanguage extends BaseEntity {
  metadataParameterId!: string;
  languageId!: string;
  metadataParameter?: MetadataParam;
  language?: Language;

  constructor(obj: Partial<MetadataParamLanguage>) {
    super(obj);
    Object.assign(this, obj);
    if (obj?.metadataParameter)
      this.metadataParameter = new MetadataParam(obj.metadataParameter);
    if (obj?.language) this.language = new Language(obj.language);
  }
}
