import { DateTime } from 'luxon';
import { BaseEntity } from 'processdelight-angular-components';
import { MetadataFilterType } from '../../helper/metadata.functions';
import { LibraryParamConfigChoice } from './library-param-config-choice.model';
import { Library } from './library.model';
import { MetadataParam } from './metadata-param.model';

export class LibraryParamConfig extends BaseEntity {
  paramId!: string;
  param!: MetadataParam;
  libraryId!: string;
  library!: Library;
  defaultValue?: MetadataFilterType;
  choices: LibraryParamConfigChoice[] = [];
  position!: number;
  size!: number;
  constructor(obj: Partial<LibraryParamConfig>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.defaultValue)
      try {
        this.defaultValue = JSON.parse(obj.defaultValue as any);
      } catch {
        if (typeof obj.defaultValue == 'string') {
          const date = DateTime.fromISO(obj.defaultValue);
          if (date.invalidReason == null) {
            this.defaultValue = date;
          } else this.defaultValue = obj.defaultValue as any;
        }
      }
    if (obj.choices)
      this.choices = obj.choices.map((c) => new LibraryParamConfigChoice(c));
    if (obj.param) this.param = new MetadataParam(obj.param);
    if (obj.library) this.library = new Library(obj.library);
  }
}
