import { BaseEntity } from 'processdelight-angular-components';
import { MetadataPermissionConfiguration } from './metadata-permission-configuration.model';
import { MetadataPermission } from './metadata-permission.model';

export class MetadataPermissionConfigPermission extends BaseEntity {
  metadataPermissionConfigurationId!: string;
  metadataPermissionId!: string;
  metadataPermissionConfiguration!: MetadataPermissionConfiguration;
  metadataPermission!: MetadataPermission;

  constructor(obj: Partial<MetadataPermissionConfigPermission>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.metadataPermissionConfiguration) {
      this.metadataPermissionConfiguration =
        new MetadataPermissionConfiguration(
          obj.metadataPermissionConfiguration
        );
    }
    if (obj.metadataPermission) {
      this.metadataPermission = new MetadataPermission(obj.metadataPermission);
    }
  }
}
