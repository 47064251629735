import { BaseEntity } from 'processdelight-angular-components';

export class AppItemInfo extends BaseEntity {
  tableName!: string;
  value!: string;
  name!: string;

  constructor(obj: Partial<AppItemInfo>) {
    super(obj);
    Object.assign(this, obj);
  }
}
