import { Action, createReducer, on } from '@ngrx/store';
import { TilePageSegment } from '../../domain/models/tile-page-segment.model';
import { TilePage } from '../../domain/models/tile-page.model';
import { Tile } from '../../domain/models/tile.model';
import {
  createSegmentResolved,
  createTilePageResolved,
  createTileResolved,
  deleteSegmentResolved,
  deleteTilePageResolved,
  deleteTilePagePermissionsResolved,
  deleteTileResolved,
  getTilePagesResolved,
  resetSlice,
  updateCurrentTilePage,
  updateMetadataFiltersResolved,
  updateSegmentResolved,
  updateSegmentsResolved,
  updateTilePageResolved,
  updateTilePagePermissionsResolved,
  updateTileResolved,
  updateTilesResolved,
  createTilePagePermissionResolved,
  updateTileByIdResolved,
} from './tilepage.actions';
import { TilePageTilePageSegment } from '../../domain/models/tile-page-tile-page-segment.model';
import { TilePageSegmentTile } from '../../domain/models/tile-page-segment-tile.model';
import { TilePagePermission } from '../../domain/models/tile-page-permission.model';

export const featureSlice = 'tilepage';

export interface State {
  tilePages: TilePage[];
  currentPage?: TilePage;
}

const defaultState: State = {
  tilePages: [],
  currentPage: undefined,
};

export function Reducer(state: State | undefined, action: Action) {
  return tilePageReducer(state, action);
}

export const initialState: State = defaultState;
export const tilePageReducer = createReducer(
  initialState,
  on(getTilePagesResolved, (state, { pages }) => ({
    ...state,
    tilePages: pages,
  })),
  on(updateMetadataFiltersResolved, (state, { tileId, filters }) => ({
    ...state,
    tilePages: state.tilePages.map(
      (t) =>
        new TilePage({
          ...t,
          segments: t.segments.map(
            (s) =>
              new TilePageTilePageSegment({
                ...s,
                tilePageSegment: new TilePageSegment({
                  ...s.tilePageSegment,
                  tiles: s.tilePageSegment.tiles.map((t) =>
                    t.tileId == tileId
                      ? new TilePageSegmentTile({
                          ...t,
                          tile: new Tile({
                            ...t.tile,
                            metadataFilters: filters,
                          }),
                        })
                      : t
                  ),
                }),
              })
          ),
        })
    ),
  })),
  on(updateTilesResolved, (state, { tiles }) => ({
    ...state,
    tilePages: state.tilePages.map(
      (t) =>
        new TilePage({
          ...t,
          segments: t.segments.map(
            (s) =>
              new TilePageTilePageSegment({
                ...s,
                tilePageSegment: new TilePageSegment({
                  ...s.tilePageSegment,
                  tiles: s.tilePageSegment.tiles.map((t) =>
                    tiles.some((t2) => t.tileId == t2.id)
                      ? new TilePageSegmentTile({
                          ...t,
                          tile: new Tile({
                            ...t.tile,
                            ...tiles.find((t3) => t.tileId == t3.id),
                          }),
                        })
                      : t
                  ),
                }),
              })
          ),
        })
    ),
  })),
  on(createTilePagePermissionResolved, (state, { permission, tilePageId }) => ({
    ...state,
    tilePages: state.tilePages.map((t) =>
      t.id == tilePageId
        ? new TilePage({
            ...t,
            permissions: [
              ...(t.permissions ?? []),
              new TilePagePermission({
                tilePageId: t.id,
                tilePage: t,
                permissionId: permission.id,
                permission,
              }),
            ],
          })
        : t
    ),
  })),
  on(updateTilePagePermissionsResolved, (state, { permissions }) => ({
    ...state,
    tilePages: state.tilePages.map(
      (t) =>
        new TilePage({
          ...t,
          permissions: t.permissions?.map((p) => {
            const found = permissions.find((p2) => p2.id == p.permissionId);
            if (!found) return p;
            return new TilePagePermission({
              ...p,
              permission: found,
            });
          }),
        })
    ),
  })),
  on(deleteTilePagePermissionsResolved, (state, { permissionIds }) => ({
    ...state,
    tilePages: state.tilePages.map(
      (t) =>
        new TilePage({
          ...t,
          permissions: t.permissions?.filter(
            (p) => !permissionIds.includes(p.id)
          ),
        })
    ),
  })),
  on(createTilePageResolved, (state, { page }) => ({
    ...state,
    tilePages: [...state.tilePages, page],
  })),
  on(updateTilePageResolved, (state, { page }) => ({
    ...state,
    tilePages: state.tilePages.map((t) =>
      t.id == page.id
        ? new TilePage({
            ...page,
            segments: t.segments,
            permissions: t.permissions,
          })
        : t
    ),
  })),
  on(deleteTilePageResolved, (state, { tilePageId }) => ({
    ...state,
    tilePages: state.tilePages.filter((t) => t.id != tilePageId),
  })),
  on(createSegmentResolved, (state, { segment, tilePageId }) => ({
    ...state,
    tilePages: state.tilePages.map((t) =>
      t.id == tilePageId
        ? new TilePage({
            ...t,
            segments: [
              ...t.segments,
              new TilePageTilePageSegment({
                tilePageId: t.id,
                tilePage: t,
                tilePageSegmentId: segment.id,
                tilePageSegment: segment,
              }),
            ],
          })
        : t
    ),
  })),
  on(
    updateSegmentResolved,
    (state, { segment, previousTilePageIds, tilePageIds }) => {
      const toRemove = previousTilePageIds.filter(
        (id) => !tilePageIds.includes(id)
      );
      const toAdd = tilePageIds.filter(
        (id) => !previousTilePageIds.includes(id)
      );
      return {
        ...state,
        tilePages: state.tilePages.map((t) => {
          let segments: TilePageTilePageSegment[] = [];
          if (toRemove.includes(t.id))
            segments = t.segments.filter(
              (s) => s.tilePageSegmentId != segment.id
            );
          else if (toAdd.includes(t.id))
            segments = [
              ...t.segments,
              new TilePageTilePageSegment({
                tilePageId: t.id,
                tilePage: t,
                tilePageSegmentId: segment.id,
                tilePageSegment: segment,
              }),
            ];
          else if (t.segments.some((s) => s.tilePageSegmentId == segment.id))
            segments = t.segments.map((s) =>
              s.tilePageSegmentId == segment.id
                ? new TilePageTilePageSegment({
                    ...s,
                    tilePageSegment: segment,
                  })
                : s
            );
          else return t;
          return new TilePage({ ...t, segments });
        }),
      };
    }
  ),
  on(updateSegmentsResolved, (state, { segments, tilePageId }) => ({
    ...state,
    tilePages: state.tilePages.map((t) =>
      t.id == tilePageId
        ? new TilePage({
            ...t,
            segments: t.segments.map((s) =>
              segments.some((s2) => s.tilePageSegmentId == s2.id)
                ? new TilePageTilePageSegment({
                    ...s,
                    tilePageSegment: segments.find(
                      (s2) => s.tilePageSegmentId == s2.id
                    )!,
                  })
                : s
            ),
          })
        : t
    ),
  })),
  on(deleteSegmentResolved, (state, { segmentId }) => ({
    ...state,
    tilePages: state.tilePages.map((t) =>
      t.segments.some((s) => s.tilePageSegmentId == segmentId)
        ? new TilePage({
            ...t,
            segments: t.segments.filter(
              (s) => s.tilePageSegmentId != segmentId
            ),
          })
        : t
    ),
  })),
  on(createTileResolved, (state, { tile, segmentId }) => ({
    ...state,
    tilePages: state.tilePages.map((t) =>
      t.segments.some((s) => s.tilePageSegmentId == segmentId)
        ? new TilePage({
            ...t,
            segments: t.segments.map((s) =>
              s.tilePageSegmentId == segmentId
                ? new TilePageTilePageSegment({
                    ...s,
                    tilePageSegment: new TilePageSegment({
                      ...s.tilePageSegment,
                      tiles: [
                        ...s.tilePageSegment.tiles,
                        new TilePageSegmentTile({
                          tilePageSegmentId: s.id,
                          tilePageSegment: s.tilePageSegment,
                          tileId: tile.id,
                          tile,
                        }),
                      ],
                    }),
                  })
                : s
            ),
          })
        : t
    ),
  })),
  on(updateTileResolved, (state, { tile, previousSegmentIds, segmentIds }) => {
    const toRemove = previousSegmentIds.filter(
      (id) => !segmentIds.includes(id)
    );
    const toAdd = segmentIds.filter((id) => !previousSegmentIds.includes(id));
    return {
      ...state,
      tilePages: state.tilePages.map(
        (t) =>
          new TilePage({
            ...t,
            segments: t.segments.map((s) => {
              let tiles: TilePageSegmentTile[] = [];
              if (toRemove.includes(s.tilePageSegmentId)) {
                tiles = s.tilePageSegment.tiles.filter(
                  (t) => t.tileId != tile.id
                );
                return new TilePageTilePageSegment({
                  ...s,
                  tilePageSegment: new TilePageSegment({
                    ...s.tilePageSegment,
                    tiles,
                  }),
                });
              } else if (toAdd.includes(s.tilePageSegmentId)) {
                tiles = [
                  ...s.tilePageSegment.tiles,
                  new TilePageSegmentTile({
                    tilePageSegmentId: s.id,
                    tilePageSegment: s.tilePageSegment,
                    tileId: tile.id,
                    tile: tile,
                  }),
                ];
                return new TilePageTilePageSegment({
                  ...s,
                  tilePageSegment: new TilePageSegment({
                    ...s.tilePageSegment,
                    tiles,
                  }),
                });
              } else if (
                s.tilePageSegment.tiles.some((t) => t.tileId == tile.id)
              ) {
                tiles = s.tilePageSegment.tiles.map((t) =>
                  t.tileId == tile.id
                    ? new TilePageSegmentTile({
                        ...t,
                        tile,
                      })
                    : t
                );
                return new TilePageTilePageSegment({
                  ...s,
                  tilePageSegment: new TilePageSegment({
                    ...s.tilePageSegment,
                    tiles,
                  }),
                });
              } else return s;
            }),
          })
      ),
    };
  }),
  on(updateTileByIdResolved, (state, { tiles }) => ({
    ...state,
    tilePages: state.tilePages.map(
      (t) =>
        new TilePage({
          ...t,
          segments: t.segments.map(
            (s) =>
              new TilePageTilePageSegment({
                ...s,
                tilePageSegment: new TilePageSegment({
                  ...s.tilePageSegment,
                  tiles: s.tilePageSegment.tiles.map((t) =>
                    tiles.some((t2) => t.id == t2.id)
                      ? new TilePageSegmentTile({
                          ...t,
                          tile: new Tile({
                            ...t.tile,
                            ...tiles.find((t2) => t.id == t2.id),
                          }),
                        })
                      : t
                  ),
                }),
              })
          ),
        })
    ),
  })),
  on(deleteTileResolved, (state, { tileId }) => ({
    ...state,
    tilePages: state.tilePages.map((t) =>
      t.segments.some((s) =>
        s.tilePageSegment.tiles.some((t) => t.tileId == tileId)
      )
        ? new TilePage({
            ...t,
            segments: t.segments.map((s) =>
              s.tilePageSegment.tiles.some((t) => t.tileId == tileId)
                ? new TilePageTilePageSegment({
                    ...s,
                    tilePageSegment: new TilePageSegment({
                      ...s.tilePageSegment,
                      tiles: s.tilePageSegment.tiles.filter(
                        (t) => t.tileId != tileId
                      ),
                    }),
                  })
                : s
            ),
          })
        : t
    ),
  })),
  on(updateCurrentTilePage, (state, { tilePage }) => ({
    ...state,
    currentTilePage: tilePage,
  })),
  on(resetSlice, () => initialState)
);
