import { BaseEntity } from 'processdelight-angular-components';
import { MetadataChoice } from './metadata-choice.model';
import { Language } from 'processdelight-angular-components';

export class MetadataChoiceTranslation extends BaseEntity {
  translation!: string;
  parentItemId!: string;
  parentItem!: MetadataChoice;
  languageId!: string;
  language!: Language;

  constructor(obj: Partial<MetadataChoiceTranslation>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.parentItem) this.parentItem = new MetadataChoice(obj.parentItem);
    if (obj.language) this.language = new Language(obj.language);
  }
}
