import { BaseEntity } from 'processdelight-angular-components';
import { Permission } from './permission.model';
import { TilePage } from './tile-page.model';

export class TilePagePermission extends BaseEntity {
  tilePageId!: string;
  tilePage!: TilePage;
  permissionId!: string;
  permission!: Permission;

  constructor(obj: Partial<TilePagePermission>) {
    super(obj);
    Object.assign(this, obj);
    if (obj?.tilePage) {
      this.tilePage =
        obj.tilePage instanceof TilePage
          ? obj.tilePage
          : new TilePage(obj.tilePage);
    }
    if (obj?.permission) {
      this.permission =
        obj.permission instanceof Permission
          ? obj.permission
          : new Permission(obj.permission);
    }
  }
}
