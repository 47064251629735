<div class="dialogContainer">
  <h1 mat-dialog-title class="text-center">
    {{ getTranslation$("editDocuments") | async }}
  </h1>
  <mat-icon class="closeButtonIcon" (click)="close()">close</mat-icon>
  <div mat-dialog-content class="p-3 d-flex flex-column justify-content-center">
    <ng-container *ngIf="itemsWithNoPermissions.length">
      <p class="mb-0">
        {{ getTranslation$("insufficientPermissionsToEditDocuments") | async }}
        ({{ itemsWithNoPermissions.length }}).
      </p>
      <p>{{ getTranslation$("editDocumentsConfirmNotEdited") | async }}</p>
      <div
        class="rounded border border-gray mb-3 w-100 overflow-auto scrollbar"
        style="max-height: 16rem"
      >
        <mat-action-list class="p-0">
          <mat-list-item *ngFor="let item of itemsWithNoPermissions">
            <div class="w-100 d-flex flex-row align-items-center">
              <img
                class="ms-2 pe-2"
                [src]="getIcon(item)"
                width="24"
                height="24"
                alt="file icon"
              />
              <div>
                {{ fileNameMap[item.id] }}
              </div>
            </div>
          </mat-list-item>
        </mat-action-list>
      </div>
    </ng-container>
    <p>{{ getEditingXDocumentsTranslation$() | async }}</p>
    <div class="d-flex flex-column w-100">
      <div
        *ngFor="let group of paramArray.controls; let index = index"
        [formGroup]="group"
        class="d-flex flex-row justify-content-between align-items-center w-100 mb-3"
      >
        <div class="d-flex flex-row">
          <button mat-icon-button (click)="addParam(index)">
            <mat-icon>add</mat-icon>
          </button>
          <button mat-icon-button (click)="removeParam(index)">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <mat-form-field
          class="w-100 me-2 no-subscript-wrapper"
          appearance="outline"
          floatLabel="always"
          style="width: 48px"
        >
          <mat-label>{{ getTranslation$("parameter") | async }}</mat-label>
          <mat-select
            (openedChange)="$event ? focusInput(searchInput) : undefined"
            panelClass="p-0"
            formControlName="param"
          >
            <div class="d-flex flex-column h-100">
              <mat-form-field class="no-subscript-wrapper">
                <mat-label
                  >{{ getTranslation$("search") | async }}...</mat-label
                >
                <input matInput type="text" #searchInput />
              </mat-form-field>
              <div class="overflow-auto scrollbar">
                <mat-option
                  *ngFor="let param of params; trackBy: paramTrackBy"
                  [value]="param.id"
                  [hidden]="
                    param.title
                      .toLowerCase()
                      .indexOf(searchInput.value.toLowerCase()) === -1
                  "
                >
                  {{ param.title }}
                </mat-option>
              </div>
            </div>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="w-100 no-subscript-wrapper"
          *ngIf="
            !getParam(group) || getResettingValueField(group);
            else valueInput
          "
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>{{ getTranslation$("value") | async }}</mat-label>
          <input matInput formControlName="value" />
        </mat-form-field>
        <mat-checkbox
          color="primary"
          *ngIf="getParam(group)?.multi"
          formControlName="overwrite"
        >
          {{ getTranslation$("overwrite") | async }}?
        </mat-checkbox>
        <ng-template #valueInput>
          <app-adaptable-input
            *ngIf="getParam(group) as param"
            [param]="param"
            [params]="params"
            [library]="library"
            labelOverride="Value"
            formControlName="value"
            class="no-subscript-wrapper w-100"
            style="width: 48px"
          ></app-adaptable-input>
        </ng-template>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <button mat-icon-button (click)="addParam()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
    <div class="buttons">
      <button
        mat-raised-button
        class="menuButton"
        (click)="confirm()"
        [disabled]="!formValid || !items.length"
      >
        <mat-icon>edit</mat-icon>
        <span>{{ getTranslation$("update") | async }}</span>
      </button>
      <button mat-raised-button class="menuButton" (click)="close()">
        <mat-icon>close</mat-icon>
        <span>{{ getTranslation$("cancel") | async }}</span>
      </button>
    </div>
  </div>
</div>
