import { BaseEntity } from 'processdelight-angular-components';
import { TileAction } from '../enums/tile-action.enum';
import { MetadataFilter } from './metadata-filter.model';

export class Tile extends BaseEntity {
  title!: string;
  tooltip?: string;
  tileAction!: string;
  tileActionType!: TileAction;
  iconUrl!: string;
  hideTileInView?: boolean;
  disabled?: boolean;
  metadataFilters?: MetadataFilter[];
  position!: number;

  constructor(obj: Partial<Tile>) {
    super(obj);
    Object.assign(this, obj);
    if (typeof obj.tileActionType == 'string')
      this.tileActionType =
        TileAction[obj.tileActionType as keyof typeof TileAction];
    if (obj.metadataFilters?.length)
      this.metadataFilters = obj.metadataFilters.map(
        (p) => new MetadataFilter(p)
      );
  }
}
