<div class="h-100 d-flex flex-column position-relative">
  <h2 mat-dialog-title>{{ getTranslation$("sharedWith") | async }}</h2>
  <mat-icon class="closeButtonIcon" mat-dialog-close>close</mat-icon>
  <div class="doc-name">
    {{ item.title }}        
  </div>
  <div
    class="d-flex justify-content-center align-items-center"
    style="z-index: 1050; width: 90%"
    *ngIf="loading"
  >
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div
    mat-dialog-content
    class="d-flex flex-column justify-content-center align-items-start position-relative h-100 w-100 min-height-0 min-width-0 scrollbar"
    style="height: 150px; overflow-y: auto"
  >  
    <mat-action-list
      class="h-100 w-100 min-height-0 min-width-0"
      *ngIf="sharedWith.length"
    >     
      <mat-list-item *ngFor="let item of sharedWith">
        <div
          class="w-100 d-flex flex-row justify-content-between align-items-center"
        >
          <div>
            {{ item.to.displayName }}
          </div>
          <button matSuffix mat-icon-button (click)="removeSharing(item)">
            <mat-icon class="material-icons-outlined">delete</mat-icon>
          </button>
        </div>
      </mat-list-item>    
    </mat-action-list>
    <div *ngIf="!sharedWith.length && !loading">
      This file is not shared yet with other users
    </div>
  </div>
</div>
