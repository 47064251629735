import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { DomSanitizer } from '@angular/platform-browser';
import { MicrosoftAuthenticationService } from 'processdelight-angular-components';
import { combineLatest, first, map, switchMap, tap } from 'rxjs';
import { CoreModule } from 'src/app/core/core.module';
import { LibraryItem } from 'src/app/core/domain/models/item.model';
import { Permission } from 'src/app/core/domain/models/permission.model';
import { ApiService } from 'src/app/core/services/api.service';
import { LibraryIconService } from 'src/app/core/services/library-icon.service';
import { LibraryFacade } from 'src/app/core/store/library/library.facade';
import { MetadataFacade } from 'src/app/core/store/metadata/metadata.facade';
import { TilePageFacade } from 'src/app/core/store/tilepage/tilepage.facade';
import { translations$ } from 'src/app/core/data/data.observables';

@Component({
  selector: 'app-copy-items-dialog',
  standalone: true,
  imports: [
    CoreModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    ReactiveFormsModule,
  ],
  templateUrl: './copy-items-dialog.component.html',
  styleUrls: ['./copy-items-dialog.component.scss'],
})
export class CopyItemsDialogComponent implements OnInit {
  confirmAction!: (
    items: LibraryItem[],
    fileNameMap: { [key: string]: string }
  ) => void;

  items: LibraryItem[] = [];
  itemsWithNoPermissions: LibraryItem[] = [];

  originalFileNames: { [key: string]: string } = {};

  fileNameValidityMap: { [key: string]: boolean } = {};

  form = new FormGroup<{ [key: string]: FormControl<string | null> }>({});

  get formValid() {
    return (
      !this.form.invalid &&
      !Object.values(this.fileNameValidityMap).some((v) => !v)
    );
  }

  constructor(
    private readonly api: ApiService,
    private readonly libraryFacade: LibraryFacade,
    private readonly metadataFacade: MetadataFacade,
    private readonly dialogRef: MatDialogRef<CopyItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: {
      items: LibraryItem[];
      confirmAction: (
        items: LibraryItem[],
        fileNameMap: { [key: string]: string }
      ) => void;
    },
    private readonly tilePageFacade: TilePageFacade,
    private readonly msal: MicrosoftAuthenticationService,
    private readonly libraryIconService: LibraryIconService,
    private readonly domSanitizer: DomSanitizer
  ) {}

  getTranslation(label: string): string {
    return translations$.value[label];
  }
  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  ngOnInit(): void {
    this.confirmAction = this.data.confirmAction;
    combineLatest([
      this.libraryFacade.libraries$.pipe(first()),
      this.tilePageFacade.homePage$.pipe(first()),
      this.metadataFacade.fileNameParam$.pipe(first()),
    ]).subscribe(([libs, homepage, param]) => {
      this.data.items.forEach((item) => {
        let permissions: Permission[] = [];
        if (item.library)
          permissions =
            libs
              .find((l) => l.id == item.library?.id)
              ?.permissions.map((p) => p.permission) ?? [];
        else permissions = homepage?.permissions.map((p) => p.permission) ?? [];
        if (
          !permissions.length ||
          permissions.some(
            (p) =>
              (p.groupUser.user?.id == this.msal.userId ||
                p.groupUser.group?.members?.some(
                  (m) => m.id == this.msal.userId
                )) &&
              p.uploadPermission
          )
        ) {
          this.items.push(item);
        } else {
          this.itemsWithNoPermissions.push(item);
        }
      });
      this.items.forEach((item) => {
        const fileName =
          item.metadata?.find((m) => m.metadataParameter.id === param?.id)
            ?.value ?? item.title.split('.')[0];
        this.originalFileNames[item.id] = fileName;
        this.fileNameValidityMap[item.id] = false;
        const control = new FormControl<string>(
          fileName + ' - Copy',
          [Validators.required],
          [
            (control) =>
              this.libraryFacade.libraries$.pipe(
                first(),
                switchMap((libs) =>
                  this.api.checkFileName(
                    libs.find((lib) => lib.id === item.library?.id)
                      ?.sharepointUrl ?? 'IshtarDMSLibraryItems',
                    [control.value!, item.title.split('.').pop()].join('.')
                  )
                ),
                tap((res) => (this.fileNameValidityMap[item.id] = !res)),
                map((res) => (res ? { nameExists: true } : null))
              ),
          ]
        );
        this.form.addControl(item.id, control);
        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();
      });
    });
  }

  getIcon(item: LibraryItem) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.libraryIconService.provideIcon(item.fileLocation)!
    );
  }

  getControl(id: string) {
    return this.form.get(id) as FormControl<string | null>;
  }

  confirm() {
    if (this.form.invalid) return;
    this.confirmAction(this.items, { ...this.form.value } as {
      [key: string]: string;
    });
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
