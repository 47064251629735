import { Action, createReducer, on } from '@ngrx/store';
import { MetadataChoice } from '../../domain/models/metadata-choice.model';
import { MetadataParam } from '../../domain/models/metadata-param.model';
import { MetadataPermissionConfiguration } from '../../domain/models/metadata-permission-configuration.model';
import {
  createDMSWebpartPermissionsResolved,
  createDMSWebpartInterestGroupsResolved,
  createMetadataChoiceResolved,
  createMetadataChoiceTranslationsResolved,
  createMetadataParamResolved,
  createMetadataParamsResolved,
  deleteDMSWebpartPermissionsResolved,
  deleteDMSWebpartInterestGroupsResolved,
  deleteMetadataChoiceResolved,
  deleteMetadataParamResolved,
  getAdminMetadataParamsResolved,
  getDMSWebpartPermissionsResolved,
  getMetadataParamsResolved,
  getDMSWebpartInterestGroupsResolved,
  getMetadataPermissionConfigurationsResolved,
  resetSlice,
  updateMetadataChoiceResolved,
  updateMetadataChoicesResolved,
  updateMetadataParamResolved,
  updateMetadataPermissionConfigurationResolved,
  updateMetadataView,
} from './metadata.actions';
import { DMSWebpartPermission } from '../../domain/models/dms-webpart-permission.model';
import { DMSWebpartInterestGroup } from '../../domain/models/dms-webpart-interestgroup.model';

export const featureSlice = 'metadata';

export interface State {
  metadata: MetadataParam[];
  adminMetadata: MetadataParam[];
  permissionConfigurations: MetadataPermissionConfiguration[];
  dmsWebpartPermissions: DMSWebpartPermission[];
  dmsWebpartInterestGroups: DMSWebpartInterestGroup[];
}

const defaultState: State = {
  metadata: [],
  adminMetadata: [],
  permissionConfigurations: [],
  dmsWebpartPermissions: [],
  dmsWebpartInterestGroups: [],
};

export function Reducer(state: State | undefined, action: Action) {
  return metadataReducer(state, action);
}

export const initialState: State = defaultState;
export const metadataReducer = createReducer(
  initialState,
  on(getMetadataParamsResolved, (state, { metadataParams }) => ({
    ...state,
    metadata: metadataParams,
  })),
  on(getAdminMetadataParamsResolved, (state, { metadataParams }) => ({
    ...state,
    adminMetadata: metadataParams,
  })),
  on(createMetadataParamResolved, (state, { metadataParam }) => ({
    ...state,
    metadata: [...state.metadata, metadataParam],
    adminMetadata: [...state.adminMetadata, metadataParam],
  })),
  on(createMetadataParamsResolved, (state, { metadataParams }) => ({
    ...state,
    metadata: [...state.metadata, ...metadataParams],
    adminMetadata: [...state.adminMetadata, ...metadataParams],
  })),
  on(updateMetadataParamResolved, (state, { metadataParams }) => ({
    ...state,
    metadata: state.metadata.map((m) => {
      const metadataParam = metadataParams.find((x) => x.id == m.id);
      return !!metadataParam && m.id == metadataParam.id
        ? new MetadataParam({
            ...metadataParam,
            choices: m?.choices.map(
              (c) => metadataParam.choices.find((c2) => c2.id == c.id) ?? c
            ),
          })
        : m;
    }),
    adminMetadata: state.adminMetadata.map((m) => {
      const metadataParam = metadataParams.find((x) => x.id == m.id);
      return !!metadataParam && m.id == metadataParam.id ? metadataParam : m;
    }),
  })),
  on(deleteMetadataParamResolved, (state, { metadataParamIds }) => ({
    ...state,
    metadata: state.metadata.filter(
      (m) => !metadataParamIds.some((id) => id === m.id)
    ),
    adminMetadata: state.adminMetadata.filter(
      (m) => !metadataParamIds.some((id) => id === m.id)
    ),
  })),
  on(createMetadataChoiceResolved, (state, { metadataChoice }) => ({
    ...state,
    metadata: state.metadata.map((m) =>
      m.id == metadataChoice.metadataParameterId
        ? new MetadataParam({ ...m, choices: [...m.choices, metadataChoice] })
        : m
    ),
    adminMetadata: state.adminMetadata.map((m) =>
      m.id == metadataChoice.metadataParameterId
        ? new MetadataParam({ ...m, choices: [...m.choices, metadataChoice] })
        : m
    ),
  })),
  on(updateMetadataChoiceResolved, (state, { metadataChoice }) => ({
    ...state,
    metadata: state.metadata.map((m) =>
      m.id == metadataChoice.metadataParameterId
        ? new MetadataParam({
            ...m,
            choices: m.choices.map((c) =>
              c.id == metadataChoice.id ? metadataChoice : c
            ),
          })
        : m
    ),
    adminMetadata: state.adminMetadata.map((m) =>
      m.id == metadataChoice.metadataParameterId
        ? new MetadataParam({
            ...m,
            choices: m.choices.map((c) =>
              c.id == metadataChoice.id ? metadataChoice : c
            ),
          })
        : m
    ),
  })),
  on(updateMetadataChoicesResolved, (state, { metadataChoices }) => ({
    ...state,
    metadata: state.metadata.map((m) =>
      metadataChoices.some((c) => m.id == c.metadataParameterId)
        ? new MetadataParam({
            ...m,
            choices: m.choices.map(
              (c) => metadataChoices.find((x) => c.id == x.id) ?? c
            ),
          })
        : m
    ),
    adminMetadata: state.adminMetadata.map((m) =>
      metadataChoices.some((c) => m.id == c.metadataParameterId)
        ? new MetadataParam({
            ...m,
            choices: m.choices.map(
              (c) => metadataChoices.find((x) => c.id == x.id) ?? c
            ),
          })
        : m
    ),
  })),
  on(
    createMetadataChoiceTranslationsResolved,
    (state, { paramId, translations }) => ({
      ...state,
      metadata: state.metadata.map((m) =>
        m.id == paramId
          ? new MetadataParam({
              ...m,
              choices: m.choices.map((c) =>
                c.id == translations[0]?.parentItem.id
                  ? new MetadataChoice({
                      ...c,
                      translations: translations.concat(
                        c.translations.filter((t) =>
                          translations.some(
                            (t2) => t2.language.id !== t.language.id
                          )
                        )
                      ),
                    })
                  : c
              ),
            })
          : m
      ),
      adminMetadata: state.adminMetadata.map((m) =>
        m.id == paramId
          ? new MetadataParam({
              ...m,
              choices: m.choices.map((c) =>
                c.id == translations[0].parentItem.id
                  ? new MetadataChoice({
                      ...c,
                      translations: translations.concat(
                        c.translations.filter((t) =>
                          translations.some(
                            (t2) => t2.language.id == t.language.id
                          )
                        )
                      ),
                    })
                  : c
              ),
            })
          : m
      ),
    })
  ),
  on(
    deleteMetadataChoiceResolved,
    (state, { metadataParamId, metadataChoiceId }) => ({
      ...state,
      metadata: state.metadata.map((m) =>
        m.id == metadataParamId
          ? new MetadataParam({
              ...m,
              choices: m.choices.filter((c) => c.id != metadataChoiceId),
            })
          : m
      ),
      adminMetadata: state.adminMetadata.map((m) =>
        m.id == metadataParamId
          ? new MetadataParam({
              ...m,
              choices: m.choices.filter((c) => c.id != metadataChoiceId),
            })
          : m
      ),
    })
  ),
  on(
    getMetadataPermissionConfigurationsResolved,
    (state, { configurations }) => ({
      ...state,
      permissionConfigurations: configurations,
    })
  ),
  on(
    updateMetadataPermissionConfigurationResolved,
    (state, { configuration }) => ({
      ...state,
      permissionConfigurations: state.permissionConfigurations.map((c) =>
        c.id === configuration.id ? configuration : c
      ),
    })
  ),
  on(updateMetadataView, (state, { configuration, hasAccess }) => ({
    ...state,
    metadata: state.adminMetadata.flatMap((m) => {
      if (m.id === configuration.metadataParameterId) {
        if (!configuration.isRestrictivePermission) return [m];
        if (configuration.subValue) {
          return [
            new MetadataParam({
              ...m,
              choices: m.choices.flatMap((c) => {
                if (c.id === configuration.subValue && !hasAccess) {
                  return [];
                }
                return [c];
              }),
            }),
          ];
        }
        if (!hasAccess) return [];
      }
      return [m];
    }),
  })),
  on(getDMSWebpartPermissionsResolved, (state, { permissions }) => ({
    ...state,
    dmsWebpartPermissions: permissions,
  })),
  on(createDMSWebpartPermissionsResolved, (state, { permissions }) => ({
    ...state,
    dmsWebpartPermissions: [...state.dmsWebpartPermissions, ...permissions],
  })),
  on(deleteDMSWebpartPermissionsResolved, (state, { permissionIds }) => ({
    ...state,
    dmsWebpartPermissions: state.dmsWebpartPermissions.filter(
      (p) => !permissionIds.some((id) => id === p.id)
    ),
  })),
  on(getDMSWebpartInterestGroupsResolved, (state, { interestGroups }) => ({
    ...state,
    dmsWebpartInterestGroups: interestGroups,
  })),
  on(createDMSWebpartInterestGroupsResolved, (state, { interestGroups }) => ({
    ...state,
    dmsWebpartInterestGroups: [
      ...state.dmsWebpartInterestGroups,
      ...interestGroups,
    ],
  })),
  on(deleteDMSWebpartInterestGroupsResolved, (state, { interestGroupIds }) => ({
    ...state,
    dmsWebpartInterestGroups: state.dmsWebpartInterestGroups.filter(
      (p) => !interestGroupIds.some((id) => id === p.id)
    ),
  })),
  on(resetSlice, () => initialState)
);
