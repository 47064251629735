import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromCoreFeature from './store/core.feature';
import { MetadataEffects } from './store/metadata/metadata.effects';
import { TilePageEffects } from './store/tilepage/tilepage.effects';
import { LibraryEffects } from './store/library/library.effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationEffects } from './store/notification/notification.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCoreFeature.featureKey,
      fromCoreFeature.reducers
    ),
    EffectsModule.forFeature([
      TilePageEffects,
      MetadataEffects,
      LibraryEffects,
      NotificationEffects,
    ]),

    MatSnackBarModule,
  ],
  exports: [CommonModule],
  declarations: [],
})
export class CoreModule {}
