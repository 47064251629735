import { BaseEntity } from 'processdelight-angular-components';
import { LibraryParamConfig } from './library-param-config.model';
import { LibraryPermission } from './library-permission.model';

export class Library extends BaseEntity {
  title!: string;
  sharepointUrl?: string;
  isApp = false;
  libraryTriggerTemplateId?: string;

  permissions: LibraryPermission[] = [];

  configuredParams: LibraryParamConfig[] = [];

  constructor(obj?: Partial<Library>) {
    super(obj ?? {});
    Object.assign(this, obj);
    if (obj?.configuredParams)
      this.configuredParams = obj.configuredParams.map((x) =>
        x instanceof LibraryParamConfig ? x : new LibraryParamConfig(x)
      );
    if (obj?.permissions?.length)
      this.permissions = obj.permissions
        .map((p) => new LibraryPermission(p))
        .filter((p) => p.permission?.groupUser);
  }
}
