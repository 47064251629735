import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import {
  AdditionUnit,
  ApplicationNotification,
  ChannelType,
  CreateNotificationRequest,
  Day,
  Frequency,
  GroupUser,
  Language,
  Lookup,
  Month,
  Ranking,
  TriggerType,
} from 'processdelight-angular-components';
import { Observable, catchError, filter, map, of, tap, timeout } from 'rxjs';
import * as uuid from 'uuid';
import { license$ } from '../data/data.observables';
import { PermissionType } from '../domain/enums/permission-type.enum';
import { AppConfig } from '../domain/models/app-config.model';
import { AppInfo } from '../domain/models/app-info.model';
import { LibraryItem } from '../domain/models/item.model';
import { LibraryTrigger } from '../domain/models/library-trigger.model';
import { Library } from '../domain/models/library.model';
import { MetadataChoiceTranslation } from '../domain/models/metadata-choice-translation.model';
import { MetadataChoice } from '../domain/models/metadata-choice.model';
import { MetadataFilter } from '../domain/models/metadata-filter.model';
import { MetadataParam } from '../domain/models/metadata-param.model';
import { MetadataPermissionConfiguration } from '../domain/models/metadata-permission-configuration.model';
import { MetadataPermission } from '../domain/models/metadata-permission.model';
import { Metadata } from '../domain/models/metadata.model';
import { Permission } from '../domain/models/permission.model';
import { SharedItem } from '../domain/models/shared-item.model';
import { TilePageSegment } from '../domain/models/tile-page-segment.model';
import { TilePage } from '../domain/models/tile-page.model';
import { Tile } from '../domain/models/tile.model';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';
import { UserSettings } from '../domain/models/user-settings.model';
import { camelcaseKeys } from '../helper/object.functions';
import { FunctionsService } from './functions.service';
import { PortalGroup } from '../domain/models/portal-group.model';
import { DMSWebpartPermission } from '../domain/models/dms-webpart-permission.model';

@Injectable({ providedIn: 'root' })
export class ApiService {
  apiBase = `${location.origin}/web`;
  constructor(
    private httpClient: HttpClient,
    private functionsService: FunctionsService
  ) {}

  getLicense(tenantId: string) {
    return this.httpClient.post<UserLicenseInfo>(
      `${this.apiBase}/session/register?tenantId=${tenantId}`,
      {}
    );
  }

  sessionKeepAlive() {
    return this.httpClient.post(`${this.apiBase}/session/keepalive`, {});
  }

  getAppInfo(app: string) {
    return this.httpClient
      .get<AppInfo>(`${this.apiBase}/organization/app/${app}`)
      .pipe(map((info) => new AppInfo(camelcaseKeys(info))));
  }

  getTilePages() {
    return this.httpClient
      .get<TilePage[]>(`${this.apiBase}/tilepages`)
      .pipe(map((pages) => pages.map((p) => new TilePage(camelcaseKeys(p)))));
  }
  createTilePage(page: TilePage) {
    return this.httpClient
      .post<TilePage>(
        `${this.apiBase}/tilepages`,

        page
      )
      .pipe(map((tilePage) => new TilePage(camelcaseKeys(tilePage))));
  }
  updateTilePage(page: TilePage) {
    return this.httpClient
      .patch<TilePage>(
        `${this.apiBase}/tilepages`,

        {
          ...page,
          segments: null,
          permissions: null,
        }
      )
      .pipe(map((tilePage) => new TilePage(camelcaseKeys(tilePage))));
  }
  deleteTilePage(tilePageId: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/tilepages/${tilePageId}`
    );
  }

  createSegment(segment: TilePageSegment, tilePageId: string) {
    return this.httpClient
      .post<TilePageSegment>(`${this.apiBase}/tilepages/segments`, {
        ...segment,
        tilePageId,
      })
      .pipe(map((segment) => new TilePageSegment(camelcaseKeys(segment))));
  }
  updateSegment(
    segment: TilePageSegment,
    tilePageIds: string[],
    previousTilePageIds: string[]
  ) {
    return this.httpClient
      .patch<TilePageSegment>(
        `${this.apiBase}/tilepages/segments/${segment.id}`,
        { ...segment, tilePageIds, previousTilePageIds }
      )
      .pipe(map((segment) => new TilePageSegment(camelcaseKeys(segment))));
  }
  updateSegments(segments: TilePageSegment[]) {
    return this.httpClient
      .patch<TilePageSegment[]>(`${this.apiBase}/tilepages/segments`, segments)
      .pipe(
        map((segments) =>
          segments.map((segment) => new TilePageSegment(camelcaseKeys(segment)))
        )
      );
  }
  deleteSegment(segmentId: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/tilepages/segments/${segmentId}`
    );
  }

  createTile(tile: Tile, segmentId: string) {
    return this.httpClient
      .post<Tile>(`${this.apiBase}/tilepages/tiles`, {
        ...tile,
        segmentId,
      })
      .pipe(map((tile) => new Tile(camelcaseKeys(tile))));
  }
  updateTile(tile: Tile, segmentIds: string[], previousSegmentIds: string[]) {
    return this.httpClient
      .patch<Tile>(`${this.apiBase}/tilepages/tiles/${tile.id}`, {
        ...tile,
        segmentIds,
        previousSegmentIds,
      })
      .pipe(map((tile) => new Tile(camelcaseKeys(tile))));
  }
  updateTiles(tiles: Tile[]) {
    return this.httpClient
      .patch<Tile[]>(`${this.apiBase}/tilepages/tiles`, tiles)
      .pipe(map((tiles) => tiles.map((t) => new Tile(camelcaseKeys(t)))));
  }
  deleteTile(tileId: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/tilepages/tiles/${tileId}`
    );
  }

  createTilePagePermission(permission: Permission, tilePageId: string) {
    return this.httpClient
      .post<Permission>(`${this.apiBase}/tilepages/permissions`, {
        ...permission,
        tilePageId,
        permissionType: PermissionType[permission.permissionType],
      })
      .pipe(map((permission) => new Permission(camelcaseKeys(permission))));
  }
  updateTilePagePermissions(permissions: Permission[]) {
    return this.httpClient
      .patch<Permission[]>(
        `${this.apiBase}/tilepages/permissions`,
        permissions.map((p) => ({
          ...p,
          permissionType: PermissionType[p.permissionType],
        }))
      )
      .pipe(
        map((permissions) =>
          permissions.map((p) => new Permission(camelcaseKeys(p)))
        )
      );
  }
  deleteTilePagePermissions(permissionIds: string[]) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/tilepages/permissions`,
      { body: permissionIds }
    );
  }

  createLibraryPermission(permission: Permission, libraryId: string) {
    return this.httpClient
      .post<Permission>(`${this.apiBase}/libraries/permissions`, {
        ...permission,
        libraryId,
        permissionType: PermissionType[permission.permissionType],
      })
      .pipe(map((permission) => new Permission(camelcaseKeys(permission))));
  }
  updateLibraryPermissions(permissions: Permission[]) {
    return this.httpClient
      .patch<Permission[]>(
        `${this.apiBase}/libraries/permissions`,
        permissions.map((p) => ({
          ...p,
          permissionType: PermissionType[p.permissionType],
        }))
      )
      .pipe(
        map((permissions) =>
          permissions.map((p) => new Permission(camelcaseKeys(p)))
        )
      );
  }
  deleteLibraryPermissions(permissionIds: string[]) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/libraries/permissions`,
      { body: permissionIds }
    );
  }

  getMetadataParams(getAll = false) {
    return this.httpClient
      .get<MetadataParam[]>(
        `${this.apiBase}/metadata/parameters?getAll=${getAll}`
      )
      .pipe(map((params) => params.map((p) => new MetadataParam(p))));
  }
  createMetadataParam(metadataParam: MetadataParam) {
    return this.httpClient
      .post<MetadataParam>(`${this.apiBase}/metadata/parameters`, metadataParam)
      .pipe(map((param) => new MetadataParam(param)));
  }
  updateMetadataParam(metadataParam: MetadataParam) {
    return this.httpClient
      .patch<MetadataParam>(
        `${this.apiBase}/metadata/parameters`,
        metadataParam
      )
      .pipe(map((param) => new MetadataParam(param)));
  }

  deleteMetadataParam(metadataParamId: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/metadata/parameters/${metadataParamId}`
    );
  }

  getMetadataPermissionConfigurations() {
    return this.httpClient
      .get<MetadataPermissionConfiguration[]>(
        `${this.apiBase}/metadata/permissions/configuration`
      )
      .pipe(
        map((permissions) =>
          permissions.map(
            (p) => new MetadataPermissionConfiguration(camelcaseKeys(p))
          )
        )
      );
  }

  addMetadataPermissions(permissions: MetadataPermission[]) {
    return this.httpClient
      .post<MetadataPermission[]>(
        `${this.apiBase}/metadata/permissions`,
        permissions
      )
      .pipe(
        filter(Boolean),
        map((permissions) =>
          permissions.map((p) => new MetadataPermission(camelcaseKeys(p)))
        )
      );
  }

  updateMetadataPermissions(permissions: MetadataPermission[]) {
    return this.httpClient
      .patch<MetadataPermission[]>(
        `${this.apiBase}/metadata/permissions`,
        permissions
      )
      .pipe(
        filter(Boolean),
        map((permissions) =>
          permissions.map((p) => new MetadataPermission(camelcaseKeys(p)))
        )
      );
  }

  updateMetadataPermissionConfiguration(
    permissionConfig: MetadataPermissionConfiguration
  ) {
    return this.httpClient
      .post<MetadataPermissionConfiguration>(
        `${this.apiBase}/metadata/permissions/configuration`,
        permissionConfig
      )
      .pipe(
        filter(Boolean),
        map(
          (permissionConfig) =>
            new MetadataPermissionConfiguration(camelcaseKeys(permissionConfig))
        )
      );
  }

  removeMetadataPermissions(permissionIds: string[]) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/metadata/permissions`,
      { body: permissionIds }
    );
  }

  createMetadataChoice(choice: MetadataChoice) {
    return this.httpClient
      .post<MetadataChoice>(`${this.apiBase}/metadata/choices`, {
        ...choice,
      })
      .pipe(map((p) => new MetadataChoice(camelcaseKeys(p))));
  }
  updateMetadataChoice(choice: MetadataChoice) {
    return this.httpClient
      .patch<MetadataChoice>(
        `${this.apiBase}/metadata/choices/${choice.id}`,
        choice
      )
      .pipe(map((choice) => new MetadataChoice(camelcaseKeys(choice))));
  }
  updateMetadataChoices(choices: MetadataChoice[]) {
    return this.httpClient
      .patch<MetadataChoice[]>(
        `${this.apiBase}/metadata/choices`,
        choices.map((choice) => ({ ...choice, translations: undefined }))
      )
      .pipe(
        map((p) => p.map((choice) => new MetadataChoice(camelcaseKeys(choice))))
      );
  }
  deleteMetadataChoice(paramId: string, choiceId: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/metadata/choices/${paramId}/${choiceId}`
    );
  }

  createMetadataChoiceTranslations(translations: MetadataChoiceTranslation[]) {
    return this.httpClient
      .post<MetadataChoiceTranslation[]>(
        `${this.apiBase}/metadata/choicetranslations`,
        translations
      )
      .pipe(
        map((p) =>
          p.map((t) => new MetadataChoiceTranslation(camelcaseKeys(t)))
        )
      );
  }

  createMetadataFilter(metadata: MetadataFilter[]) {
    return this.httpClient
      .post<MetadataFilter[]>(
        `${this.apiBase}/metadata/filters`,
        this.dateTimesToISO(metadata)
      )
      .pipe(map((m) => m.map((p) => new MetadataFilter(camelcaseKeys(p)))));
  }
  updateMetadataFilter(metadata: MetadataFilter[]) {
    return this.httpClient
      .patch<MetadataFilter[]>(
        `${this.apiBase}/metadata/filters`,
        this.dateTimesToISO(metadata)
      )
      .pipe(map((m) => m.map((p) => new MetadataFilter(camelcaseKeys(p)))));
  }
  deleteMetadataFilter(filterIds: string[]) {
    return this.httpClient.delete<boolean>(`${this.apiBase}/metadata/filters`, {
      body: filterIds,
    });
  }

  createMetadata(metadata: Metadata[]) {
    return this.httpClient
      .post<Metadata[]>(
        `${this.apiBase}/metadata`,
        this.dateTimesToISO(metadata)
      )
      .pipe(map((m) => m.map((p) => new Metadata(camelcaseKeys(p)))));
  }
  updateMetadata(metadata: Metadata[]) {
    return this.httpClient
      .patch<Metadata[]>(
        `${this.apiBase}/metadata`,
        this.dateTimesToISO(metadata)
      )
      .pipe(map((m) => m.map((p) => new Metadata(camelcaseKeys(p)))));
  }
  deleteMetadata(filterIds: string[]) {
    return this.httpClient.delete<boolean>(`${this.apiBase}/metadata`, {
      body: filterIds,
    });
  }

  getLibraries() {
    return this.httpClient
      .get<Library[]>(`${this.apiBase}/libraries`)
      .pipe(map((m) => m.map((p) => new Library(camelcaseKeys(p)))));
  }
  createLibrary(library: Library) {
    return this.httpClient
      .post<Library>(`${this.apiBase}/libraries`, {
        ...library,
        configuredParams: library.configuredParams.map((c) => {
          let value = c.defaultValue;
          if (value) {
            if (Array.isArray(value)) {
              value = JSON.stringify(
                value.map((v) => {
                  if (v instanceof GroupUser) {
                    return {
                      user: v.user
                        ? { id: v.id, displayName: v.user.displayName }
                        : undefined,
                      group: v.group
                        ? {
                            groupId: v.group.groupId,
                            displayName: v.group.displayName,
                          }
                        : undefined,
                    };
                  }
                  return v;
                })
              );
            } else if (value instanceof GroupUser) {
              value = JSON.stringify({
                user: value.user ? { id: value.id } : undefined,
                group: value.group
                  ? {
                      groupId: value.group.groupId,
                      displayName: value.group.displayName,
                    }
                  : undefined,
              });
            } else if (value instanceof Date) {
              value = DateTime.fromJSDate(value).toISO() ?? '';
            } else if (typeof value == 'object') {
              value = JSON.stringify(value);
            } else {
              value = value?.toString() ?? '';
            }
          }
          return {
            ...c,
            defaultValue: value,
          };
        }),
      })
      .pipe(map((m) => new Library(camelcaseKeys(m))));
  }
  updateLibrary(library: Library) {
    return this.httpClient
      .patch<Library>(`${this.apiBase}/libraries`, {
        ...library,
        configuredParams: library.configuredParams.map((c) => {
          let value = c.defaultValue;
          if (value) {
            if (Array.isArray(value)) {
              value = JSON.stringify(
                value.map((v) => {
                  if (v instanceof GroupUser) {
                    return {
                      user: v.user
                        ? { id: v.id, displayName: v.user.displayName }
                        : undefined,
                      group: v.group
                        ? {
                            groupId: v.group.groupId,
                            displayName: v.group.displayName,
                          }
                        : undefined,
                    };
                  }
                  return v;
                })
              );
            } else if (value instanceof GroupUser) {
              value = JSON.stringify({
                user: value.user ? { id: value.id } : undefined,
                group: value.group
                  ? {
                      groupId: value.group.groupId,
                      displayName: value.group.displayName,
                    }
                  : undefined,
              });
            } else if (value instanceof DateTime) {
              value =
                value.toUTC(0, { keepLocalTime: true }).toISO() ?? undefined;
            } else if (typeof value == 'object') {
              value = JSON.stringify(value);
            } else {
              value = value?.toString() ?? undefined;
            }
          }
          return {
            ...c,
            defaultValue: value,
          };
        }),
      })
      .pipe(map((m) => new Library(camelcaseKeys(m))));
  }
  deleteLibrary(libraryId: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/libraries/${libraryId}`
    );
  }

  getSharedWithItems(itemId: string) {
    return this.httpClient
      .get<SharedItem[]>(`${this.apiBase}/documents/shared/${itemId}`)
      .pipe(map((m) => m.map((p) => new SharedItem(camelcaseKeys(p)))));
  }
  createSharedWithItem(items: SharedItem[]) {
    return this.httpClient
      .post<SharedItem[]>(`${this.apiBase}/documents/shared`, items)
      .pipe(map((m) => m.map((p) => new SharedItem(camelcaseKeys(p)))));
  }
  deleteSharedWithItem(itemId: string) {
    return this.httpClient.delete<string[]>(
      `${this.apiBase}/documents/shared/${itemId}`,
      { body: [itemId] }
    );
  }
  getSharedItems(
    orderBy: string | undefined,
    orderByDirection: 'asc' | 'desc' | undefined,
    filters: { [key: string]: string },
    pageSize: number,
    page: number
  ) {
    return this.httpClient
      .get<{ result: LibraryItem[]; totalRecordCount: number }>(
        `${this.apiBase}/documents/shared?pageSize=${pageSize}&page=${page}${
          orderBy ? '&orderBy=' + orderBy : ''
        }${
          orderByDirection ? '&orderByDirection=' + orderByDirection : ''
        }${this.parseFilters(filters)}`
      )
      .pipe(
        map(({ result, totalRecordCount }) => ({
          result: result.map((p) => new LibraryItem(camelcaseKeys(p))),
          totalRecordCount,
        }))
      );
  }

  parseFilters = (filters: {
    [key: string]: string | { start: DateTime; end: DateTime } | GroupUser;
  }) => {
    const result =
      '&filter=' +
      Object.keys(filters)
        .map((key) => {
          if (typeof filters[key] == 'string') {
            if (uuid.validate(filters[key] as string))
              return `(${key} eq ${filters[key]})`;
            else
              return `(${key} in '${encodeURIComponent(
                filters[key] as string
              )}')`;
          } else if (typeof filters[key] == 'object') {
            if (filters[key] instanceof GroupUser) {
              const val = filters[key] as GroupUser;
              return `(${key} eq ${val.id})`;
            } else {
              const val = filters[key] as { start: DateTime; end: DateTime };
              let dateFilter = '';
              if (val.start)
                dateFilter += `(${key} ge ${val.start
                  .toUTC(0, { keepLocalTime: true })
                  .toISO()})`;
              if (val.start && val.end) dateFilter += ',';
              if (val.end)
                dateFilter += `(${key} le ${val.end
                  .toUTC(0, { keepLocalTime: true })
                  .toISO()})`;
              return dateFilter;
            }
          } else if (typeof filters[key] == 'number') {
            return `(${key} eq ${filters[key]})`;
          }
          return undefined;
        })
        .filter((f) => !!f)
        .join(',');
    return result;
  };

  getLibraryItems(
    orderBy: string | undefined,
    orderByDirection: 'asc' | 'desc' | undefined,
    filters: { [key: string]: string },
    pageSize: number,
    page: number,
    libraryId: string | undefined = undefined
  ) {
    return this.httpClient
      .get<{ result: LibraryItem[]; totalRecordCount: number }>(
        `${this.apiBase}/documents?pageSize=${pageSize}&page=${page}${
          orderBy ? '&orderBy=' + orderBy : ''
        }${
          orderByDirection ? '&orderByDirection=' + orderByDirection : ''
        }${this.parseFilters(filters)}${
          libraryId ? `&libraryId=${libraryId}` : ''
        }`
      )
      .pipe(
        map(({ result, totalRecordCount }) => ({
          result: result.map((p) => new LibraryItem(camelcaseKeys(p))),
          totalRecordCount,
        }))
      );
  }
  createLibraryItems(items: LibraryItem[]) {
    return this.httpClient
      .post<LibraryItem[]>(
        `${this.apiBase}/documents`,
        this.dateTimesToISO(items)
      )
      .pipe(map((m) => m.map((p) => new LibraryItem(camelcaseKeys(p)))));
  }
  updateLibraryItemsForMove(items: LibraryItem[]) {
    return this.httpClient
      .patch<LibraryItem[]>(
        `${this.apiBase}/documents/moveUpdate`,
        this.dateTimesToISO(items)
      )
      .pipe(map((m) => m.map((p) => new LibraryItem(camelcaseKeys(p)))));
  }
  updateLibraryItems(items: LibraryItem[]) {
    return this.httpClient
      .patch<LibraryItem[]>(
        `${this.apiBase}/documents`,
        this.dateTimesToISO(items)
      )
      .pipe(map((m) => m.map((p) => new LibraryItem(camelcaseKeys(p)))));
  }
  deleteLibraryItems(itemIds: string[]) {
    return this.httpClient.delete<boolean>(`${this.apiBase}/documents`, {
      body: itemIds,
    });
  }
  checkUploadedFiles(fileName: string, libraryId?: string) {
    return this.httpClient
      .post<LibraryItem | undefined>(
        `${this.apiBase}/documents/checkUploaded('${fileName}')?${
          libraryId ? `libraryId=${libraryId}` : ''
        }`,
        {}
      )
      .pipe(
        map((m) => (m ? new LibraryItem(camelcaseKeys(m)) : undefined)),
        catchError(() => of(undefined))
      );
  }
  checkFileName(libraryName: string, fileName: string) {
    return this.httpClient.post<boolean>(
      `${this.apiBase}/documents/checkFileName('${fileName}')?relativeUrl=${libraryName}`,
      {}
    );
  }
  checkExistingMetadata(
    metadata: Metadata[],
    fileExtension: string,
    excludedId?: string,
    libraryId?: string
  ) {
    return this.httpClient.post<string | undefined>(
      `${this.apiBase}/metadata/checkExisting${
        libraryId ? `?libraryId=${libraryId}` : ''
      }`,
      {
        metadata: this.dateTimesToISO(metadata),
        fileExtension,
        excludedId,
      },
      { responseType: 'text' as 'json' }
    );
  }

  checkLibraryExists(relativeUrl: string) {
    return this.httpClient.post<boolean>(
      `${this.apiBase}/libraries/checkExisting('${relativeUrl}')`,
      {}
    );
  }

  getLibraryFilePreview(sharepointId: string) {
    return this.httpClient.get<string>(
      `${this.apiBase}/documents/${sharepointId}/preview`,
      { responseType: 'text' as 'json' }
    );
  }

  getLibraryFileLocation(sharepointId: string) {
    return this.httpClient.get<string>(
      `${this.apiBase}/documents/${sharepointId}/location`,
      { responseType: 'text' as 'json' }
    );
  }
  getLibraryFileShareUrl(
    sharepointId: string,
    edit = false,
    expiry: Date | undefined = undefined
  ) {
    return this.httpClient.get<{
      shareLink: string;
      anonymousEnabled: boolean;
    }>(
      `${this.apiBase}/documents/${sharepointId}/share?edit=${edit}${
        expiry ? '&expiry=' + expiry?.toISOString() : ''
      }`
    );
  }
  getLibraryFileDesktopAppUrl(sharepointId: string, edit = false) {
    return this.httpClient.get<string>(
      `${this.apiBase}/documents/${sharepointId}/desktopApp?edit=${edit}`,
      {
        responseType: 'text' as 'json',
      }
    );
  }

  uploadLibraryFile(fileName: string, blob: Blob, relativeUrl?: string) {
    const formData = new FormData();
    formData.append('file', blob, fileName);
    formData.append('relativeUrl', relativeUrl ?? 'IshtarDMSLibraryItems');
    return this.httpClient
      .post<{ sharepointId: string; relativeUrl: string }>(
        `${this.apiBase}/documents/upload`,
        formData
      )
      .pipe(
        map(({ relativeUrl, sharepointId }) => ({
          sharepointId,
          fileLocation: this.functionsService.getFileLocation(relativeUrl),
        }))
      );
  }
  uploadFileChunk(
    fileName: string,
    relativeUrl: string | undefined,
    blob: Blob,
    start?: number,
    end?: number,
    uploadId?: string
  ) {
    const formData = new FormData();
    formData.append('file', blob, fileName);
    formData.append('relativeUrl', relativeUrl ?? 'IshtarDMSLibraryItems');
    formData.append('start', start?.toString() ?? '0');
    formData.append('end', end?.toString() ?? blob.size.toString());
    if (uploadId) formData.append('uploadId', uploadId);
    return this.httpClient
      .post<{ uploadId: string; relativeUrl: string; sharepointId: string }>(
        `${this.apiBase}/documents/uploadChunk`,
        formData
      )
      .pipe(
        map(({ uploadId, relativeUrl, sharepointId }) => ({
          uploadId,
          sharepointId,
          fileLocation: this.functionsService.getFileLocation(relativeUrl),
        }))
      );
  }
  moveLibraryFile(sharepointId: string, newLibraryName: string) {
    const formData = new FormData();
    formData.append('fileId', sharepointId);
    formData.append('newLibraryName', newLibraryName);
    return this.httpClient.post<string>(
      `${this.apiBase}/documents/move`,
      formData,
      { responseType: 'text' as 'json' }
    );
  }
  copyLibraryFile(sharepointId: string, newFileName: string) {
    const formData = new FormData();
    formData.append('fileId', sharepointId);
    formData.append('newFileName', newFileName);
    return this.httpClient.post<string>(
      `${this.apiBase}/documents/copy`,
      formData,
      { responseType: 'text' as 'json' }
    );
  }
  deleteLibraryFile(sharepointId: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/documents/${sharepointId}`
    );
  }

  getLibraryTriggers() {
    return this.httpClient
      .get<LibraryTrigger[]>(`${this.apiBase}/libraries/libraryTriggers`)
      .pipe(map((c) => c.map((t) => new LibraryTrigger(camelcaseKeys(t)))));
  }

  uploadTileIcon(fileName: string, blob: Blob) {
    const formData = new FormData();
    formData.append('file', blob, fileName);
    return this.httpClient
      .post<string>(`${this.apiBase}/tilepages/tileicons`, formData, {
        responseType: 'text' as 'json',
      })
      .pipe(
        map((relativeUrl) =>
          this.functionsService.getSPValueUrl(relativeUrl, true)
        )
      );
  }
  deleteTileIcon(relativeUrl: string) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/documents/tileicons?relativeurl=${relativeUrl}`
    );
  }

  getLanguages() {
    return this.httpClient
      .get<Language[]>(`${this.apiBase}/user/languages`)
      .pipe(map((ls) => ls.map((l) => new Language(camelcaseKeys(l)))));
  }
  getTranslations() {
    return this.httpClient.get<any>(
      `${this.apiBase}/user/translations/${license$.value!.language}`
    );
  }

  getUsers() {
    return this.httpClient
      .get<GroupUser[]>(`${this.apiBase}/organization/users`)
      .pipe(map((user) => user.map((u) => new GroupUser(camelcaseKeys(u)))));
  }
  getGroups() {
    return this.httpClient
      .get<GroupUser[]>(`${this.apiBase}/organization/groups`)
      .pipe(map((group) => group.map((g) => new GroupUser(camelcaseKeys(g)))));
  }
  getConfiguration() {
    return this.httpClient
      .get<AppConfig>(`${this.apiBase}/organization/config`)
      .pipe(map((c) => new AppConfig(camelcaseKeys(c))));
  }
  updateConfiguration(config: AppConfig) {
    return this.httpClient
      .post<AppConfig>(`${this.apiBase}/organization/config`, config)
      .pipe(map((c) => new AppConfig(camelcaseKeys(c))));
  }
  getUserSettings() {
    return this.httpClient
      .get<UserSettings>(`${this.apiBase}/user/settings`)
      .pipe(map((c) => new UserSettings(camelcaseKeys(c))));
  }
  updateUserSettings(settings: UserSettings) {
    return this.httpClient
      .post<UserSettings>(`${this.apiBase}/user/settings`, settings)
      .pipe(map((c) => new UserSettings(camelcaseKeys(c))));
  }

  getBlob(url: string, timeoutAfter = 10000) {
    let pipe;
    if (timeoutAfter > 0)
      pipe = timeout({
        first: timeoutAfter,
      });
    else pipe = tap(() => undefined);
    return this.httpClient
      .get<Blob>(url, { responseType: 'blob' as 'json' })
      .pipe(pipe) as Observable<Blob>;
  }

  getTaskTemplates() {
    return this.httpClient
      .get<Lookup[]>(`${this.apiBase}/libraries/taskTemplates`)
      .pipe(map((c) => c.map((t) => new Lookup(camelcaseKeys(t)))));
  }

  // notifications

  getNotifications() {
    return this.httpClient
      .get<ApplicationNotification[]>(`${this.apiBase}/notifications`)
      .pipe(
        map((notifications) =>
          notifications.map(
            (n) => new ApplicationNotification(camelcaseKeys(n))
          )
        )
      );
  }
  getNotificationById(notificationId: string) {
    return this.httpClient
      .get<ApplicationNotification>(
        `${this.apiBase}/notifications/${notificationId}`
      )
      .pipe(map((n) => new ApplicationNotification(camelcaseKeys(n))));
  }
  createNotification(notification: CreateNotificationRequest) {
    return this.httpClient
      .post<ApplicationNotification>(
        `${this.apiBase}/notifications`,
        this.dateTimesToISO(notification)
      )
      .pipe(map((n) => new ApplicationNotification(camelcaseKeys(n))));
  }
  updateNotification(notification: CreateNotificationRequest) {
    return this.httpClient
      .patch<ApplicationNotification>(
        `${this.apiBase}/notifications`,
        this.dateTimesToISO(notification)
      )
      .pipe(map((n) => new ApplicationNotification(camelcaseKeys(n))));
  }
  deleteNotification(notificationId: string) {
    return this.httpClient.delete<string>(
      `${this.apiBase}/notifications/${notificationId}`,
      {
        responseType: 'text' as 'json',
      }
    );
  }

  getNotificationFrequencies() {
    return this.httpClient
      .get<Frequency[]>(`${this.apiBase}/notifications/frequencies`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new Frequency(camelcaseKeys(n)))
        )
      );
  }

  getNotificationTriggerTypes() {
    return this.httpClient
      .get<TriggerType[]>(`${this.apiBase}/notifications/triggertypes`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new TriggerType(camelcaseKeys(n)))
        )
      );
  }

  getNotificationAdditionUnits() {
    return this.httpClient
      .get<AdditionUnit[]>(`${this.apiBase}/notifications/additionunits`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new AdditionUnit(camelcaseKeys(n)))
        )
      );
  }

  getNotificationChannelTypes() {
    return this.httpClient
      .get<ChannelType[]>(`${this.apiBase}/notifications/channeltypes`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new ChannelType(camelcaseKeys(n)))
        )
      );
  }

  getDays() {
    return this.httpClient
      .get<Day[]>(`${this.apiBase}/notifications/days`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new Day(camelcaseKeys(n)))
        )
      );
  }

  getMonths() {
    return this.httpClient
      .get<Month[]>(`${this.apiBase}/notifications/months`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new Month(camelcaseKeys(n)))
        )
      );
  }

  getRankings() {
    return this.httpClient
      .get<Ranking[]>(`${this.apiBase}/notifications/rankings`)
      .pipe(
        map((notifications) =>
          notifications.map((n) => new Ranking(camelcaseKeys(n)))
        )
      );
  }

  getPortalGroups() {
    return this.httpClient
      .get<PortalGroup[]>(`${this.apiBase}/portal/groups`)
      .pipe(map((group) => group.map((g) => new PortalGroup(g))));
  }

  getDMSWebpartPermissions() {
    return this.httpClient
      .get<DMSWebpartPermission[]>(
        `${this.apiBase}/metadata/webpart/permissions`
      )
      .pipe(
        map((permissions) =>
          permissions.map((p) => new DMSWebpartPermission(p))
        )
      );
  }
  createDMSWebpartPermissions(permissions: DMSWebpartPermission[]) {
    return this.httpClient
      .post<DMSWebpartPermission[]>(
        `${this.apiBase}/metadata/webpart/permissions`,
        permissions
      )
      .pipe(map((ps) => ps.map((p) => new DMSWebpartPermission(p))));
  }
  deleteDMSWebpartPermissions(permissionIds: string[]) {
    return this.httpClient.delete<boolean>(
      `${this.apiBase}/metadata/webpart/permissions`,
      { body: permissionIds }
    );
  }

  private dateTimesToISO(obj: any): any {
    if (Array.isArray(obj)) return [...obj.map((o) => this.dateTimesToISO(o))];
    else if (obj instanceof Object)
      return Object.entries(obj).reduce(
        (acc, e) => ({
          ...acc,
          [e[0]]:
            e[1] instanceof DateTime
              ? e[1].toUTC(0, { keepLocalTime: true }).toISO()
              : this.dateTimesToISO(e[1]),
        }),
        {}
      );
    else return obj;
  }
}
