export class AppInfo {
  name!: string;
  description?: string;
  appUrl!: string;
  iconUrl?: string;

  constructor(obj: Partial<AppInfo>) {
    Object.assign(this, obj);
  }
}
