<div class="h-100 d-flex flex-column">
  <h1 mat-dialog-title class="text-center">
    {{ getTranslation$("shareDocuments") | async }}
  </h1>
  <mat-icon class="closeButtonIcon" (click)="close()">close</mat-icon>
  <div
    mat-dialog-content
    class="p-3 d-flex flex-column h-100 justify-content-between"
  >
    <div class="d-flex flex-column">
      <p>{{ getTranslation$("sharingFollowingDocuments") | async }} (1)</p>
      <div
        class="rounded border border-gray mb-2 w-100 overflow-auto scrollbar"
        style="max-height: 16rem"
      >
        <mat-action-list class="p-0">
          <mat-list-item>
            <div class="w-100 d-flex flex-row align-items-center">
              <img
                class="ms-2 pe-2"
                [src]="getIcon(item)"
                width="24"
                height="24"
                alt="file icon"
              />
              <div>
                {{ fileNameMap[item.id] }}
              </div>
            </div>
          </mat-list-item>
        </mat-action-list>
      </div>
      <p *ngIf="!anonymousEnabled">
        {{ getTranslation$("anonymousSharingDisabled") | async }}
      </p>
      <mat-form-field appearance="outline" floatLabel="always" class="mt-2">
        <mat-label>{{ getTranslation$("url") | async }}</mat-label>
        <input matInput type="text" [value]="shareLink" disabled />
        <button mat-icon-button matSuffix (click)="copyToClipboard()">
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-form-field>
      <div class="d-flex flex-row">
        <mat-radio-group
          [formControl]="typeControl"
          ariaOrientation="vertical"
          class="d-flex flex-column pe-3 mb-5"
        >
          <mat-radio-button color="primary" [value]="ShareLinkType.View">{{
            getTranslation$("view") | async
          }}</mat-radio-button>
          <mat-radio-button color="primary" [value]="ShareLinkType.Edit">{{
            getTranslation$("edit") | async
          }}</mat-radio-button>
        </mat-radio-group>
        <div class="d-flex flex-column ms-2">
          <mat-checkbox
            class="mb-2"
            [(ngModel)]="showExpiry"
            [disabled]="expiryRequired"
            color="primary"
            (change)="getNewShareUrl()"
            >{{ getTranslation$("expiration") | async }}</mat-checkbox
          >
          <mat-form-field
            *ngIf="showExpiry"
            appearance="outline"
            floatLabel="always"
          >
            <input
              matInput
              [matDatepicker]="picker"
              [formControl]="expiryControl"
              [placeholder]="getTranslation$('expireOn') | async"
              [min]="today"
              processdelightDatePickerFormat
              [datePickerFormat]="dateFormat"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex flex-column">
        <mat-form-field appearance="outline" floatLabel="always">
          <mat-label>{{ getTranslation$("emailPeople") | async }}</mat-label>
          <mat-chip-grid #chipGrid aria-label="Enter fruits">
            <ng-container *ngFor="let person of emailPeople">
              <mat-chip-row
                *ngIf="getPersonName(person) as personName"
                (removed)="removePerson(person)"
                [editable]="true"
                (edited)="editPerson(person, $event)"
                [aria-description]="'press enter to edit ' + personName"
              >
                {{ personName }}
                <button
                  matChipRemove
                  [attr.aria-label]="'remove ' + personName"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            </ng-container>
            <input
              [matChipInputFor]="chipGrid"
              (matChipInputTokenEnd)="addPerson($event)"
              [matAutocomplete]="autocomplete"
              #personInput
            />
            <mat-autocomplete
              #autocomplete
              (optionSelected)="addGroupUser($event)"
            >
              <ng-container *ngFor="let user of users">
                <mat-option
                  [value]="user"
                  *ngIf="
                    user.displayName
                      ?.toLowerCase()
                      ?.includes(personInput.value.toLowerCase())
                  "
                >
                  {{ user.displayName }}
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-chip-grid>

          <button mat-icon-button matSuffix (click)="openMail()">
            <mat-icon class="material-icons-outlined">send</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

    <div class="buttons">
      <button mat-raised-button class="menuButton" (click)="close()">
        <mat-icon>close</mat-icon>
        <span>{{ getTranslation$("cancel") | async }}</span>
      </button>
    </div>
  </div>
</div>
