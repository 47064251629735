<div class="position-relative d-flex flex-row w-100 h-100">
  <div
    class="position-absolute w-100 d-flex justify-content-center align-items-center lds-overlay"
    style="transform: translateY(120px); height: calc(100% - 180px)"
    *ngIf="loading"
  >
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <processdelight-dashboard
    [data]="pagedItems"
    [maxSizeItems]="totalRecordCount"
    [pageSize]="pageSize"
    [possibleColumns]="possibleColumns"
    [selectedColumns]="selectedColumns"
    [showExpandedDetails]="false"
    [sortColumn]="orderBy"
    [sortDirection]="orderByDirection ?? ''"
    (pageChange)="pageChange($event)"
    (sortChange)="sortChange($event)"
    (rowExpanded)="!appLibrary ? openPreview($event) : undefined"
    (columnChange)="columnChange($event)"
    [emptyTableText]="noItems"
    [itemTrackByFn]="itemTrackByFn"
    [selectedItems]="selectedItems"
    [columnBreakpointObserver]="false"
    [highlightOnHover]="true"
    [horizontalScroll]="true"
    [customContextMenu]="true"
    [contextMenuActionsFn]="itemContextMenuFn"
    (rowDblClicked)="!appLibrary ? openDocument($event, true) : undefined"
    [columnsResizable]="true"
    (selectionChanged)="selectedItems = $event"
    (columnWidthChanged)="columnWidthChange($event)"
    [multiSelect]="true"
    [multiSelectActions]="multiSelectActions"
    (selectionChanged)="updateMultiSelectActions($event)"
    [highlightSelected]="true"
    [arrowKeySelectionChange]="true"
    [extraButtonsTemplate]="buttonsTemplateRef"
  >
  </processdelight-dashboard>
  <ng-template #noItems>
    <div class="w-100 h-100 d-flex justify-content-center align-items-center">
      <h1 *ngIf="!loading">{{ getTranslation$("noResults") | async }}</h1>
    </div>
  </ng-template>
</div>
