import { BaseEntity } from 'processdelight-angular-components';
import { MetadataChoiceTranslation } from './metadata-choice-translation.model';
import { MetadataParam } from './metadata-param.model';

export class MetadataChoice extends BaseEntity {
  value!: string;
  metadataParameterId!: string;
  metadataParameter!: MetadataParam;
  position!: number;
  translations: MetadataChoiceTranslation[] = [];

  constructor(obj: Partial<MetadataChoice>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.translations)
      this.translations = obj.translations.map(
        (t) => new MetadataChoiceTranslation(t)
      );
    else this.translations = [];
    if (obj.metadataParameter)
      this.metadataParameter = new MetadataParam(obj.metadataParameter);
  }
}
