import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { DateTime } from 'luxon';
import {
  ColumnDef,
  ContextMenuAction,
  DashboardComponent,
  Ishtar365CommunicationService,
  MicrosoftAuthenticationService,
  Notification,
  NotificationType,
  SignalRService,
} from 'processdelight-angular-components';
import {
  Subject,
  catchError,
  combineLatest,
  debounceTime,
  delay,
  distinctUntilChanged,
  first,
  forkJoin,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CoreModule } from 'src/app/core/core.module';
import {
  config$,
  groups$,
  translations$,
  userSettings$,
  users$,
} from 'src/app/core/data/data.observables';
import { ColumnType } from 'src/app/core/domain/enums/column-type.enum';
import { PermissionType } from 'src/app/core/domain/enums/permission-type.enum';
import { LibraryItem } from 'src/app/core/domain/models/item.model';
import { Library } from 'src/app/core/domain/models/library.model';
import { MetadataParam } from 'src/app/core/domain/models/metadata-param.model';
import { Metadata } from 'src/app/core/domain/models/metadata.model';
import { Permission } from 'src/app/core/domain/models/permission.model';
import { SharedItem } from 'src/app/core/domain/models/shared-item.model';
import {
  LibraryUserSettings,
  UserSettings,
} from 'src/app/core/domain/models/user-settings.model';
import { MetadataFilterType } from 'src/app/core/helper/metadata.functions';
import { ApiService } from 'src/app/core/services/api.service';
import { FunctionsService } from 'src/app/core/services/functions.service';
import {
  LibraryDocumentService,
  PreviewType,
} from 'src/app/core/services/library-document.service';
import { LibraryIconService } from 'src/app/core/services/library-icon.service';
import { LibraryFacade } from 'src/app/core/store/library/library.facade';
import { MetadataFacade } from 'src/app/core/store/metadata/metadata.facade';
import { TilePageFacade } from 'src/app/core/store/tilepage/tilepage.facade';
import { v4 } from 'uuid';
import { CopyItemsDialogComponent } from './copy-items-dialog/copy-items-dialog.component';
import { DeleteItemsDialogComponent } from './delete-items-dialog/delete-items-dialog.component';
import { EditItemsDialogComponent } from './edit-items-dialog/edit-items-dialog.component';
import { MoveItemsDialogComponent } from './move-items-dialog/move-items-dialog.component';
import { ShareLinkDialogComponent } from './share-link-dialog/share-link-dialog.component';
import { SharedWithComponent } from './shared-with/shared-with.component';
import { AppComponent } from 'src/app/app.component';
import { AppItemInfo } from 'src/app/core/domain/models/app-item-info.model';
import { camelcaseKeys } from 'src/app/core/helper/object.functions';
import { PreviousVersionsDialogComponent } from './previous-versions-dialog/previous-versions-dialog.component';

@Component({
  selector: 'app-library',
  standalone: true,
  imports: [
    CoreModule,
    DashboardComponent,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    RouterModule,
  ],
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
})
export class LibraryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() library?: Library;
  @Input() filterValue: { [key: string]: string } = {};
  @Input() buttonsTemplateRef?: TemplateRef<void>;

  @Output() itemClicked = new EventEmitter<LibraryItem>();

  orderBy!: string;
  orderByDirection?: 'asc' | 'desc' = 'desc';
  pageSize = 100;
  page = 0;
  highestPage = 0;

  items: LibraryItem[] = [];
  totalRecordCount = 0;

  pagedItems: LibraryItem[] = [];

  metadataParameters$ = this.metadataFacade.metadataParams$;

  private imgColumn = new ColumnDef<LibraryItem>({
    internalName: 'img',
    displayName: '',
    sortable: false,
    filterable: false,
    fixed: true,
    valueHtml: true,
    valueAccessor: (item) =>
      `<img class="ms-2" src="${this.libraryIconService.provideIcon(
        item.fileLocation
      )}" width="24" height="24" alt="file icon" />`,
    canChangeWidth: false,
    width: 50,
  });

  private libraryColumn = new ColumnDef<LibraryItem>({
    internalName: 'library',
    displayName: 'Library',
    sortable: false,
    filterable: false,
    valueAccessor: (item) =>
      item.library?.title ?? item.appItems?.find((i) => !!i)?.tableName,
    cellClass: 'ellipsis',
  });
  private appItemColumn = new ColumnDef<LibraryItem>({
    internalName: 'appItem',
    displayName: 'Linked to',
    sortable: false,
    filterable: false,
    valueAccessor: (item) =>
      item.appItems
        .filter((i) => i.tableName == this.library?.id)
        .map((i) => i.name)
        .join(', '),
    cellClass: 'ellipsis',
  });

  possibleColumns: ColumnDef<LibraryItem>[] = [];

  selectedColumns: string[] = [];
  view?: string[];

  previewItem?: LibraryItem;
  selectedItems: LibraryItem[] = [];

  metadataParams: MetadataParam[] = [];

  contextMenuActions: ContextMenuAction<LibraryItem>[] = [];

  loading = false;

  initialized = false;

  destroy$ = new Subject<void>();

  reset$ = new Subject<void>();

  pagingCalls = 0;
  lastPageCall?: () => void;

  itemTrackByFn: (index: number, item: LibraryItem) => string = (_, item) =>
    item.id;

  appLibrary = false;

  multiSelectActions: ContextMenuAction<LibraryItem[]>[] = [];

  @ViewChild(DashboardComponent) dashboard!: DashboardComponent<LibraryItem>;

  userSettingsUpdateSubject = new Subject<void>();

  constructor(
    private readonly libraryFacade: LibraryFacade,
    private readonly tilePageFacade: TilePageFacade,
    private readonly metadataFacade: MetadataFacade,
    private readonly libraryIconService: LibraryIconService,
    private readonly api: ApiService,
    private readonly functions: FunctionsService,
    private readonly libDocumentService: LibraryDocumentService,
    private readonly msal: MicrosoftAuthenticationService,
    private readonly ishtarCommunicationService: Ishtar365CommunicationService,
    private readonly matDialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    private readonly route: ActivatedRoute,
    private readonly serviceBus: SignalRService
  ) {}

  getTranslation(label: string): string {
    return translations$.value[label];
  }
  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  ngOnInit(): void {
    this.serviceBus.subscribeToTopic<LibraryItem>('ishtardmsitem', {
      post: () => {
        this.getItems({
          orderBy: this.orderBy,
          orderByDirection: this.orderByDirection,
          filter: this.filterValue,
          pageSize: this.pageSize,
          page: this.page,
          showLoader: false,
          resetPaging: true,
        });
      },
      patch: ([item]) => {
        item = camelcaseKeys(item);
        this.pagedItems = this.pagedItems.map((i) =>
          i.id == item.id ? item : i
        );
        if (this.previewItem?.id == item.id) this.previewItem = item;
      },
      delete: ([itemId]) => {
        this.pagedItems = this.pagedItems.filter((i) => i.id != itemId);
      },
    });
    // this.itemUploadedService.itemsUploaded$
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(() =>
    //     this.getItems(
    //       this.orderBy,
    //       this.orderByDirection,
    //       this.filterValue,
    //       this.pageSize,
    //       this.page
    //     )
    //   );
    this.appLibrary =
      !!this.library &&
      !/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i.test(
        this.library.id
      );
    this.metadataParameters$
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        const userSettings = userSettings$.value;
        this.appItemColumn.width = userSettings.librarySettings.find(
          (l) => l.libraryId == this.library?.id
        )?.columnSizes[this.appItemColumn.internalName];
        this.libraryColumn.width = userSettings.librarySettings.find(
          (l) => l.libraryId == this.library?.id
        )?.columnSizes[this.libraryColumn.internalName];

        this.metadataParams = params;
        this.pageSize =
          userSettings.librarySettings.find(
            (l) => l.libraryId == this.library?.id
          )?.pageSize ?? 100;
        this.orderBy =
          userSettings.librarySettings.find(
            (l) => l.libraryId == this.library?.id
          )?.sortColumn ?? params.find((p) => p.modifiedOnParam)!.id;
        this.orderByDirection =
          userSettings.librarySettings.find(
            (l) => l.libraryId == this.library?.id
          )?.sortDirection ?? 'desc';
        this.initialized = true;
      });
    this.metadataParameters$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a, b) => {
          if (a.length != b.length) return false;
          const aSorted = [...a].sort((a, b) => a.id.localeCompare(b.id));
          const bSorted = [...b].sort((a, b) => a.id.localeCompare(b.id));
          for (let i = 0; i < a.length; i++)
            if (
              aSorted[i].id != bSorted[i].id ||
              aSorted[i].choices.length != bSorted[i].choices.length ||
              aSorted[i].format != bSorted[i].format ||
              aSorted[i].consolidatedChoices.length !=
                bSorted[i].consolidatedChoices.length ||
              aSorted[i].title != bSorted[i].title ||
              aSorted[i].hasTranslations != bSorted[i].hasTranslations ||
              aSorted[i].languages.length != bSorted[i].languages.length
            )
              return false;

          return true;
        })
      )
      .subscribe((params) => {
        this.possibleColumns.forEach((column) => {
          const param = params.find((p) => p.id == column.internalName);
          if (param) {
            column.displayName = param.title;

            column.valueAccessor = this.itemValueAccessor(param.id, params);
          }
        });
      });
    this.userSettingsUpdateSubject
      .asObservable()
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe(() => this.updateUserSettings());
    // this.libraryFacade.libraryItems$
    //   .pipe(
    //     takeUntil(this.destroy$),
    //     distinctUntilChanged((a, b) => {
    //       if (a.length != b.length) return false;
    //       for (let i = 0; i < a.length; i++)
    //         if (a[i].id != b[i].id) return false;
    //       return true;
    //     }),
    //     tap((items) => {
    //       this.getItems(
    //         this.orderBy,
    //         this.orderByDirection,
    //         this.filterValue,
    //         this.pageSize,
    //         this.page,
    //         true
    //       );
    //     })
    //   )
    //   .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.filterValue &&
      !changes.filterValue.firstChange &&
      changes.filterValue.currentValue != changes.filterValue.previousValue
    ) {
      this.page = 0;
      this.highestPage = 0;
      this.dashboard.paginator.firstPage();
      this.getItems();
    }

    if (changes.library) {
      this.metadataParameters$.pipe(first()).subscribe((params) => {
        const userSettings = userSettings$.value;
        const fixedColumns = [this.imgColumn];
        if (!this.library) fixedColumns.push(this.libraryColumn);
        if (this.appLibrary) fixedColumns.push(this.appItemColumn);
        this.possibleColumns = [...fixedColumns].concat(
          params
            ?.filter(
              (p) =>
                p.fileNameParam ||
                p.createdByParam ||
                p.createdOnParam ||
                p.modifiedByParam ||
                p.modifiedOnParam ||
                !this.library ||
                this.library.configuredParams.some((c) => c.paramId == p.id)
            )
            .map(
              (p) =>
                new ColumnDef<LibraryItem>({
                  internalName: p.id,
                  displayName: p.title,
                  sortable: p.type != ColumnType.GroupUser,
                  filterable: false,
                  valueAccessor: this.itemValueAccessor(p.id, params),
                  width: userSettings.librarySettings.find(
                    (l) => l.libraryId == this.library?.id
                  )?.columnSizes[p.id],
                  cellClass: 'ellipsis',
                })
            ) ?? []
        );
        this.possibleColumns.sort((a, b) =>
          a.displayName.localeCompare(b.displayName)
        );

        this.view = userSettings.librarySettings
          .find((l) => l.libraryId == this.library?.id)
          ?.columnOrder.filter((c) =>
            this.possibleColumns.some((p) => p.internalName == c)
          );

        if (!this.view) {
          const special = params.filter(
            (p) =>
              p.fileNameParam ||
              p.createdByParam ||
              p.createdOnParam ||
              p.modifiedByParam ||
              p.modifiedOnParam
          );
          this.selectedColumns = [
            ...fixedColumns.map((c) => c.internalName),
            ...special.map((s) => s.id),
            ...(this.library?.configuredParams.map((p) => p.paramId) ?? []),
          ];
          this.view = this.selectedColumns;
          userSettings$.next(
            new UserSettings({
              ...userSettings,
              librarySettings: [
                ...userSettings.librarySettings,
                new LibraryUserSettings({
                  libraryId: this.library?.id,
                  columnOrder: this.view,
                  sortColumn: this.orderBy,
                  sortDirection: this.orderByDirection,
                  pageSize: this.pageSize,
                  columnSizes: this.possibleColumns
                    .filter((c) => c.width)
                    .reduce(
                      (acc, c) => ({ ...acc, [c.internalName]: c.width }),
                      {}
                    ),
                }),
              ],
            })
          );
          this.api.updateUserSettings(userSettings$.value).subscribe();
        } else {
          this.selectedColumns = [
            ...fixedColumns.map((c) => c.internalName),
            ...params.map((m) => m.id),
          ]
            .filter((c) => this.view!.includes(c))
            .sort((a, b) => this.view!.indexOf(a) - this.view!.indexOf(b));
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.reset$.next();
    this.reset$.complete();
    this.destroy$.next();
    this.destroy$.complete();
  }

  itemContextMenuFn = this.itemContextMenu.bind(this);
  itemContextMenu(item: LibraryItem) {
    return combineLatest([
      this.libraryFacade.libraries$,
      this.tilePageFacade.homePage$,
      groups$,
      users$,
      config$,
    ]).pipe(
      first(),
      map(([libs, homepage, groups, users, config]) => {
        if (this.appLibrary) {
          return [
            new ContextMenuAction<LibraryItem>({
              label: `${this.getTranslation('openIn')} ${this.library?.title}`,
              icon: 'open_in_new',
              disabled: !item.appItems?.length,
              children: item.appItems?.map(
                (i) =>
                  new ContextMenuAction<LibraryItem>({
                    label: i.name,
                    action: () => {
                      this.openIshtarApp(i);
                    },
                  })
              ),
            }),
          ];
        }
        let permissions: Permission[] = [];
        if (this.library)
          permissions = this.library.permissions.map((p) => p.permission);
        else if (item.library)
          permissions =
            libs
              .find((l) => l.id == item.library?.id)
              ?.permissions.map((p) => p.permission) ?? [];
        else permissions = homepage?.permissions.map((p) => p.permission) ?? [];
        const canEdit =
          !permissions.length ||
          permissions.some(
            (p) =>
              (p.groupUser.user?.id == this.msal.userId ||
                p.groupUser.group?.members?.some(
                  (m) => m.id == this.msal.userId
                )) &&
              p.permissionType != PermissionType.Read
          );
        const canDelete =
          !permissions.length ||
          permissions.some(
            (p) =>
              (p.groupUser.user?.id == this.msal.userId ||
                p.groupUser.group?.members?.some(
                  (m) => m.id == this.msal.userId
                )) &&
              p.deletePermission
          );
        const canUpload =
          !permissions.length ||
          permissions.some(
            (p) =>
              (p.groupUser.user?.id == this.msal.userId ||
                p.groupUser.group?.members?.some(
                  (m) => m.id == this.msal.userId
                )) &&
              p.uploadPermission
          );
        const canDownload =
          !permissions.length ||
          permissions.some(
            (p) =>
              (p.groupUser.user?.id == this.msal.userId ||
                p.groupUser.group?.members?.some(
                  (m) => m.id == this.msal.userId
                )) &&
              p.downloadPermission
          );
        const libsWithUploadPermissions = libs.filter(
          (l) =>
            l.id != this.library?.id &&
            (!l.permissions.length ||
              l.permissions.some(
                ({ permission }) =>
                  (permission.groupUser.user?.id == this.msal.userId ||
                    permission.groupUser.group?.members?.some(
                      (m) => m.id == this.msal.userId
                    )) &&
                  permission.uploadPermission
              ))
        );
        return [
          new ContextMenuAction<LibraryItem>({
            icon: 'file_open',
            label: this.getTranslation('open'),
            children: [
              new ContextMenuAction<LibraryItem>({
                action: () => {
                  this.openDocument(item);
                },
                label: this.getTranslation('openInBrowser'),
              }),
              new ContextMenuAction<LibraryItem>({
                action: () => {
                  this.openDocument(item, true);
                },
                label: this.getTranslation('openInApp'),
                disabled: !this.libDocumentService.hasOfficeScheme(
                  item.fileLocation.split('.').pop()!
                ),
              }),
            ],
          }),
          new ContextMenuAction<LibraryItem>({
            action: () => {
              this.openPreview(item);
            },
            icon: 'remove_red_eye',
            label: this.getTranslation('preview'),
          }),
          new ContextMenuAction<LibraryItem>({
            action: () => {
              this.snackbar.open(this.getTranslation('downloadStart'), 'OK', {
                duration: 3000,
              });
              this.downloadDocument(item);
            },
            icon: 'download',
            label: this.getTranslation('download'),
            disabled: !canDownload,
          }),
          new ContextMenuAction<LibraryItem>({
            icon: 'drive_file_move',
            label: this.getTranslation('move'),
            children: libsWithUploadPermissions.map(
              (l) =>
                new ContextMenuAction<LibraryItem>({
                  action: () => {
                    this.moveItems([item], l);
                  },
                  label: l.title,
                })
            ),

            disabled: !canEdit || libsWithUploadPermissions.length == 0,
          }),
          new ContextMenuAction<LibraryItem>({
            action: () => {
              this.copyItems([item]);
            },
            icon: 'copy',
            label: this.getTranslation('copy'),
            disabled: !canUpload || !canEdit,
          }),
          new ContextMenuAction<LibraryItem>({
            action: () => void PreviousVersionsDialogComponent
              .open(this.matDialog, item, translations$.value.versionHistory),
            icon: 'history',
            label: translations$.value.versionHistory
          }),
          new ContextMenuAction<LibraryItem>({
            action: () => {
              this.deleteItems([item]);
            },
            icon: 'delete',
            label: this.getTranslation('delete'),
            disabled: !canDelete,
          }),
          new ContextMenuAction<LibraryItem>({
            icon: 'share',
            label: this.getTranslation('share'),
            disabled:
              item.library && config.librarySharingOverrides[item.library.id]
                ? !config.librarySharingOverrides[item.library.id].sharing
                : !config.sharing,
            children: [
              new ContextMenuAction<LibraryItem>({
                label: this.getTranslation('shareWith'),
                children: (groups ?? [])
                  .concat(users?.filter((u) => u.id != this.msal.userId) ?? [])
                  .map(
                    (g) =>
                      new ContextMenuAction<LibraryItem>({
                        action: () => {
                          this.api
                            .createSharedWithItem([
                              new SharedItem({
                                itemId: item.id,
                                fromId: users?.find(
                                  (u) => u.id == this.msal.userId
                                )?.id,
                                toId: g.id,
                              }),
                            ])
                            .pipe(
                              catchError((e) => {
                                this.snackbar.open(
                                  this.getTranslation('sharingFailed'),
                                  'OK',
                                  {
                                    duration: 3000,
                                  }
                                );
                                throw e;
                              })
                            )
                            .subscribe(() => {
                              this.snackbar.open(
                                this.getTranslation('fileShared'),
                                'OK',
                                {
                                  duration: 3000,
                                }
                              );
                            });
                        },
                        label: g.displayName,
                      })
                  ),
              }),
              new ContextMenuAction<LibraryItem>({
                action: () => {
                  this.matDialog.open(SharedWithComponent, {
                    data: { item },
                    width: '450px',
                    minWidth: '350px',
                  });
                },
                label: this.getTranslation('sharedWith'),
              }),
              new ContextMenuAction<LibraryItem>({
                action: () => {
                  this.matDialog.open(ShareLinkDialogComponent, {
                    data: { item },
                    width: '450px',
                    minWidth: '350px',
                  });
                },
                label: this.getTranslation('shareLink'),
                disabled:
                  item.library &&
                  config.librarySharingOverrides[item.library.id]
                    ? !config.librarySharingOverrides[item.library.id]
                        .externalSharing
                    : !config.externalSharing,
              }),
            ],
          }),
        ];
      })
    );
  }

  updateMultiSelectActions(items: LibraryItem[]) {
    if (!items.length) {
      this.multiSelectActions = [];
      return;
    }
    combineLatest([
      this.libraryFacade.libraries$,
      this.tilePageFacade.homePage$,
    ])
      .pipe(first())
      .subscribe(([libs, homepage]) => {
        if (this.appLibrary)
          this.multiSelectActions = [
            // new ContextMenuAction<LibraryItem[]>({
            //   label: this.getTranslation('openAll'),
            //   icon: 'open_in_new',
            //   action: (items) => {
            //     items.forEach((item) => {
            //       this.openIshtarApp(item);
            //     });
            //   },
            // }),
          ];
        else {
          this.multiSelectActions = [
            new ContextMenuAction<LibraryItem[]>({
              label: this.getTranslation('openAll'),
              icon: 'file_open',
              action: () => {
                this.openDocuments(this.selectedItems);
              },
            }),
          ];
          if (
            items.some((item) => {
              let permissions: Permission[] = [];

              if (item.library)
                permissions =
                  libs
                    .find((l) => l.id == item.library?.id)
                    ?.permissions.map((p) => p.permission) ?? [];
              else
                permissions =
                  homepage?.permissions.map((p) => p.permission) ?? [];
              return (
                !permissions.length ||
                permissions.some(
                  (p) =>
                    (p.groupUser.user?.id == this.msal.userId ||
                      p.groupUser.group?.members?.some(
                        (m) => m.id == this.msal.userId
                      )) &&
                    p.permissionType != PermissionType.Read
                )
              );
            })
          )
            this.multiSelectActions.push(
              new ContextMenuAction<LibraryItem[]>({
                icon: 'edit',
                label: this.getTranslation('edit'),
                action: (items) => {
                  this.editItems(items);
                },
              })
            );
          if (
            items.some((item) => {
              let permissions: Permission[] = [];

              if (item.library)
                permissions =
                  libs
                    .find((l) => l.id == item.library?.id)
                    ?.permissions.map((p) => p.permission) ?? [];
              else
                permissions =
                  homepage?.permissions.map((p) => p.permission) ?? [];
              return (
                !permissions.length ||
                permissions.some(
                  (p) =>
                    (p.groupUser.user?.id == this.msal.userId ||
                      p.groupUser.group?.members?.some(
                        (m) => m.id == this.msal.userId
                      )) &&
                    p.downloadPermission
                )
              );
            })
          )
            this.multiSelectActions.push(
              new ContextMenuAction<LibraryItem[]>({
                action: () => {
                  this.downloadDocuments(this.selectedItems);
                },
                icon: 'download',
                label: this.getTranslation('download'),
                disabled: items.some((item) => {
                  const found = libs.find((l) => l.id == item.library?.id);
                  return (
                    found?.permissions.length &&
                    !found.permissions.some(
                      ({ permission }) =>
                        (permission.groupUser.user?.id == this.msal.userId ||
                          permission.groupUser.group?.members?.some(
                            (m) => m.id == this.msal.userId
                          )) &&
                        permission.downloadPermission
                    )
                  );
                }),
              })
            );
          const filteredLibs = libs.filter(
            (l) =>
              !l.isApp &&
              l.id != this.library?.id &&
              (!l.permissions.length ||
                l.permissions.some(
                  ({ permission }) =>
                    (permission.groupUser.user?.id == this.msal.userId ||
                      permission.groupUser.group?.members?.some(
                        (m) => m.id == this.msal.userId
                      )) &&
                    permission.uploadPermission
                ))
          );
          if (
            filteredLibs.length &&
            items.some((item) => {
              let permissions: Permission[] = [];

              if (item.library)
                permissions =
                  libs
                    .find((l) => l.id == item.library?.id)
                    ?.permissions.map((p) => p.permission) ?? [];
              else
                permissions =
                  homepage?.permissions.map((p) => p.permission) ?? [];
              return (
                !permissions.length ||
                permissions.some(
                  (p) =>
                    (p.groupUser.user?.id == this.msal.userId ||
                      p.groupUser.group?.members?.some(
                        (m) => m.id == this.msal.userId
                      )) &&
                    p.permissionType != PermissionType.Read
                )
              );
            })
          )
            this.multiSelectActions.push(
              new ContextMenuAction<LibraryItem[]>({
                icon: 'drive_file_move',
                label: this.getTranslation('move'),
                children: filteredLibs.map(
                  (l) =>
                    new ContextMenuAction<LibraryItem[]>({
                      action: () => {
                        this.moveItems(this.selectedItems, l);
                      },
                      label: l.title,
                    })
                ),
              })
            );
          if (
            items.some((item) => {
              let permissions: Permission[] = [];

              if (item.library)
                permissions =
                  libs
                    .find((l) => l.id == item.library?.id)
                    ?.permissions.map((p) => p.permission) ?? [];
              else
                permissions =
                  homepage?.permissions.map((p) => p.permission) ?? [];
              return (
                !permissions.length ||
                permissions.some(
                  (p) =>
                    (p.groupUser.user?.id == this.msal.userId ||
                      p.groupUser.group?.members?.some(
                        (m) => m.id == this.msal.userId
                      )) &&
                    p.uploadPermission
                )
              );
            })
          )
            this.multiSelectActions.push(
              new ContextMenuAction<LibraryItem[]>({
                action: () => {
                  this.copyItems(this.selectedItems);
                },
                icon: 'copy',
                label: this.getTranslation('copy'),
              })
            );
          if (
            items.some((item) => {
              let permissions: Permission[] = [];

              if (item.library)
                permissions =
                  libs
                    .find((l) => l.id == item.library?.id)
                    ?.permissions.map((p) => p.permission) ?? [];
              else
                permissions =
                  homepage?.permissions.map((p) => p.permission) ?? [];
              return (
                !permissions.length ||
                permissions.some(
                  (p) =>
                    (p.groupUser.user?.id == this.msal.userId ||
                      p.groupUser.group?.members?.some(
                        (m) => m.id == this.msal.userId
                      )) &&
                    p.deletePermission
                )
              );
            })
          )
            this.multiSelectActions.push(
              new ContextMenuAction<LibraryItem[]>({
                action: () => {
                  this.deleteItems(this.selectedItems);
                },
                icon: 'delete',
                label: this.getTranslation('delete'),
                disabled: items.some((item) => {
                  const found = libs.find((l) => l.id == item.library?.id);
                  return (
                    found?.permissions.length &&
                    !found.permissions.some(
                      ({ permission }) =>
                        (permission.groupUser.user?.id == this.msal.userId ||
                          permission.groupUser.group?.members?.some(
                            (m) => m.id == this.msal.userId
                          )) &&
                        permission.deletePermission
                    )
                  );
                }),
              })
            );
        }
      });
  }

  moveItemsInternal(items: LibraryItem[], library: Library) {
    return forkJoin(
      items.map((item) => {
        return this.api
          .moveLibraryFile(
            item.sharepointId,
            library.sharepointUrl ?? 'IshtarDMSLibraryItems'
          )
          .pipe(map((id) => [item, id] as [LibraryItem, string]));
      })
    ).pipe(
      switchMap((items) =>
        this.libraryFacade.updateLibraryItemsForMove$(
          items.map(
            ([item, id]) =>
              new LibraryItem({
                ...item,
                linkedItems: [],
                appItems: [],
                metadata: [],
                libraryId: library.id,
                library: undefined,
                sharepointId: id,
              })
          )
        )
      )
    );
  }
  moveItems(items: LibraryItem[], library: Library) {
    this.previewItem = undefined;
    this.matDialog.open(MoveItemsDialogComponent, {
      data: {
        items,
        library,
        confirmAction: (itemsToMove: LibraryItem[]) => {
          this.loading = true;
          this.moveItemsInternal(itemsToMove, library)
            .pipe(delay(100))
            .subscribe(() => this.getItems());
        },
      },
    });
  }

  copyItemsInternal(
    items: LibraryItem[],
    fileNameMap: { [key: string]: string }
  ) {
    return forkJoin(
      items.map((item) => {
        const fileName = item.fileLocation.split('/').pop();
        return this.api
          .copyLibraryFile(
            item.sharepointId,
            [fileNameMap[item.id], fileName?.split('.').pop()].join('.')
          )
          .pipe(map((id) => [item, id] as [LibraryItem, string]));
      })
    ).pipe(
      withLatestFrom(combineLatest([users$, this.metadataFacade.fixedParams$])),
      switchMap(([items, [users, fixedParams]]) =>
        this.libraryFacade.createLibraryItems$(
          items.map(
            ([item, id]) =>
              new LibraryItem({
                ...item,
                title: [
                  fileNameMap[item.id],
                  item.fileLocation.split('/').pop()?.split('.').pop(),
                ].join('.'),
                id: v4(),
                library: undefined,
                appItems: [],
                linkedItems: [],
                sharepointId: id,
                metadata: item.metadata.map((m) => {
                  const fixedParam = fixedParams.find(
                    (p) => p.id == m.metadataParameter.id
                  );
                  if (fixedParam) {
                    if (fixedParam.fileNameParam)
                      return new Metadata({
                        ...m,
                        id: v4(),
                        metadataParameter: undefined,
                        item: undefined,
                        value: fileNameMap[item.id],
                        metadataParameterId: fixedParam.id,
                      });
                    else if (fixedParam.createdByParam)
                      return new Metadata({
                        ...m,
                        id: v4(),
                        metadataParameter: undefined,
                        item: undefined,
                        groupUserValue: users?.find(
                          (u) => u.id == this.msal.userId
                        ),
                        metadataParameterId: fixedParam.id,
                      });
                    else if (fixedParam.createdOnParam)
                      return new Metadata({
                        ...m,
                        id: v4(),
                        metadataParameter: undefined,
                        item: undefined,
                        dateTimeValue: DateTime.now().toUTC(0, {
                          keepLocalTime: true,
                        }),
                        metadataParameterId: fixedParam.id,
                      });
                    else if (fixedParam.modifiedByParam)
                      return new Metadata({
                        ...m,
                        id: v4(),
                        metadataParameter: undefined,
                        item: undefined,
                        groupUserValue: users?.find(
                          (u) => u.id == this.msal.userId
                        ),
                        metadataParameterId: fixedParam.id,
                      });
                    else if (fixedParam.modifiedOnParam)
                      return new Metadata({
                        ...m,
                        id: v4(),
                        metadataParameter: undefined,
                        item: undefined,
                        dateTimeValue: DateTime.now().toUTC(0, {
                          keepLocalTime: true,
                        }),
                        metadataParameterId: fixedParam.id,
                      });
                  }
                  return new Metadata({
                    ...m,
                    metadataParameter: undefined,
                    item: undefined,
                    id: v4(),
                    metadataParameterId: m.metadataParameter.id,
                  });
                }),
              })
          )
        )
      )
    );
  }

  copyItems(items: LibraryItem[]) {
    this.previewItem = undefined;
    this.matDialog.open(CopyItemsDialogComponent, {
      data: {
        items,
        confirmAction: (
          itemsToMove: LibraryItem[],
          fileNameMap: { [key: string]: string }
        ) => {
          this.loading = true;
          this.copyItemsInternal(itemsToMove, fileNameMap)
            .pipe(delay(100))
            .subscribe(() => this.getItems());
        },
      },
    });
  }

  deleteItemsInternal(items: LibraryItem[]) {
    return forkJoin(
      items.map((item) =>
        this.api.deleteLibraryFile(item.sharepointId).pipe(
          map((b) => [item, b] as [LibraryItem, boolean]),
          catchError((e: HttpErrorResponse) => {
            if (e.status == 400 && e.error.includes('is locked')) {
              this.ishtarCommunicationService.addNotification(
                new Notification({
                  type: NotificationType.Error,
                  label: `${this.getTranslation(
                    'failedToDeleteDocument'
                  )} '${item.fileLocation
                    .split('/')
                    .pop()}' ${this.getTranslation('fileLockedByAnotherUser')}`,
                })
              );
              return of([item, false] as [LibraryItem, boolean]);
            } else return of([item, true] as [LibraryItem, boolean]);
          })
        )
      )
    ).pipe(
      switchMap((items) => {
        if (items.some((i) => !i[1]))
          this.snackbar.open(this.getTranslation('deleteFilesError'), 'OK', {
            panelClass: 'app-notification-error',
            duration: 3000,
          });
        return this.libraryFacade.deleteLibraryItems$(
          items.filter(([_, b]) => b).map(([item]) => item.id)
        );
      }),
      delay(200)
    );
  }

  deleteItems(items: LibraryItem[]) {
    this.previewItem = undefined;
    this.matDialog.open(DeleteItemsDialogComponent, {
      data: {
        items,
        confirmAction: (itemsToDelete: LibraryItem[]) => {
          this.loading = true;
          this.deleteItemsInternal(itemsToDelete).subscribe(() =>
            this.removeItem({
              reloadPage: true,
            })
          );
        },
      },
    });
  }

  editItems(items: LibraryItem[]) {
    this.matDialog.open(EditItemsDialogComponent, {
      data: {
        items,
        library: this.library,
        confirmAction: (
          items: LibraryItem[],
          value: { [key: string]: MetadataFilterType | undefined },
          overwriteInfo: { [key: string]: boolean | undefined }
        ) => {
          this.loading = true;
          combineLatest([this.metadataFacade.metadataParams$, users$])
            .pipe(first())
            .subscribe(([params, users]) => {
              const updatedItems = items.map((item) => {
                const existing = item.metadata;
                const newMetadata = existing
                  .filter(
                    (m) =>
                      !(
                        Object.keys(value).includes(m.metadataParameter.id) &&
                        overwriteInfo[m.metadataParameter.id]
                      ) &&
                      !Object.entries(value).some(
                        ([k, v]) =>
                          Array.isArray(v) &&
                          v.some(
                            (val) =>
                              m.metadataParameter.id === k &&
                              (m.groupUserValue === val || m.value === val)
                          )
                      )
                  )
                  .concat(
                    Object.entries(value).reduce((acc, [k, v]) => {
                      if (v == undefined) return acc;
                      const param = params.find((p) => p.id == k);
                      if (Array.isArray(v)) {
                        if (param?.type == ColumnType.GroupUser)
                          return [
                            ...acc,
                            ...v.map(
                              (v) =>
                                new Metadata({
                                  metadataParameterId: param.id,
                                  itemId: item.id,
                                  groupUserValue: v as any,
                                })
                            ),
                          ];
                        return [
                          ...acc,
                          ...v.map(
                            (v) =>
                              new Metadata({
                                metadataParameterId: param!.id,
                                itemId: item.id,
                                value: v as any,
                              })
                          ),
                        ];
                      }
                      if (param?.type == ColumnType.DateTime)
                        return [
                          ...acc,
                          new Metadata({
                            id: existing.find(
                              (e) => e.metadataParameter.id == k
                            )?.id,
                            metadataParameterId: param.id,
                            itemId: item.id,
                            dateTimeValue: param.modifiedOnParam
                              ? DateTime.now()
                              : (v as DateTime),
                          }),
                        ];
                      else if (param?.type == ColumnType.Number)
                        return [
                          ...acc,
                          new Metadata({
                            id: existing.find((e) => e.metadataParameterId == k)
                              ?.id,
                            metadataParameterId: param.id,
                            itemId: item.id,
                            numberValue: v as any,
                          }),
                        ];
                      else if (param?.type == ColumnType.GroupUser)
                        return [
                          ...acc,
                          new Metadata({
                            id: existing.find((e) => e.metadataParameterId == k)
                              ?.id,
                            metadataParameterId: param.id,
                            itemId: item.id,
                            groupUserValue: param.modifiedByParam
                              ? users?.find(
                                  (u) => u.user?.mail == this.msal.email
                                ) ?? (v as any)
                              : (v as any),
                          }),
                        ];
                      else
                        return [
                          ...acc,
                          new Metadata({
                            id: existing.find((e) => e.metadataParameterId == k)
                              ?.id,
                            metadataParameterId: param!.id,
                            itemId: item.id,
                            value: v as any,
                          }),
                        ];
                    }, [] as Metadata[])
                  )
                  .map(
                    (m) =>
                      new Metadata({
                        ...m,
                        metadataParameter: undefined,
                        item: undefined,
                      })
                  );
                const modifiedByParam = newMetadata.findIndex(
                  (m) =>
                    params.find((p) => p.id == m.metadataParameterId)
                      ?.modifiedByParam
                );
                if (modifiedByParam != -1)
                  newMetadata.splice(
                    modifiedByParam,
                    1,
                    new Metadata({
                      ...newMetadata[modifiedByParam],
                      groupUserValue: users?.find(
                        (u) => u.user?.mail == this.msal.email
                      ),
                    })
                  );
                const modifiedOnParam = newMetadata.findIndex(
                  (m) =>
                    params.find((p) => p.id == m.metadataParameterId)
                      ?.modifiedOnParam
                );
                if (modifiedOnParam != -1)
                  newMetadata.splice(
                    modifiedOnParam,
                    1,
                    new Metadata({
                      ...newMetadata[modifiedOnParam],
                      dateTimeValue: DateTime.now(),
                    })
                  );

                return new LibraryItem({
                  ...item,
                  metadata: newMetadata,
                });
              });
              this.libraryFacade
                .updateLibraryItems$(
                  updatedItems.map(
                    (i) => new LibraryItem({ ...i, library: undefined })
                  )
                )
                .subscribe((items) => {
                  items.forEach((item) => this.updateItem(item));
                  this.selectedItems = this.items.filter((i) =>
                    this.selectedItems.some((item) => item.id == i.id)
                  );
                  this.loading = false;
                });
            });
        },
      },
    });
  }

  getItems(
    data: {
      orderBy: string;
      orderByDirection: 'asc' | 'desc' | undefined;
      filter: { [key: string]: string };
      pageSize: number;
      page: number;
      resetPaging?: boolean;
      showLoader?: boolean;
      callback?: () => void;
    } = {
      orderBy: this.orderBy,
      orderByDirection: this.orderByDirection,
      filter: this.filterValue,
      pageSize: this.pageSize,
      page: this.page,
      showLoader: true,
      resetPaging: true,
    }
  ) {
    this.loading = data.showLoader ?? true;
    if (data.resetPaging) {
      this.totalRecordCount = 0;
      this.reset$.next();
      this.pagingCalls = 1;
    } else {
      this.pagingCalls++;
    }
    this.lastPageCall = data.callback;
    this.libraryFacade
      .getLibraryItems$(
        data.orderBy,
        data.orderByDirection,
        { ...data.filter },
        data.pageSize,
        data.page,
        this.library?.id,
        () => this.reset$.asObservable()
      )
      .pipe(first(), withLatestFrom(this.metadataFacade.metadataParams$))
      .subscribe(([items, params]) => {
        this.pagingCalls--;
        this.metadataParams = params.map((p) => new MetadataParam(p));
        this.items = (
          data.resetPaging
            ? [...items.result]
            : [...this.items, ...items.result]
        ).map((i) => new LibraryItem(i));
        this.pagedItems = this.items.filter(
          (_, index) =>
            index >= this.page * this.pageSize &&
            index < (this.page + 1) * this.pageSize
        );
        if (this.pagingCalls == 0) {
          this.loading = false;
          if (this.lastPageCall) this.lastPageCall();
        }
        this.totalRecordCount = items.totalRecordCount;
        if (this.items.length == 0) return;
        const fixedColumns = [this.imgColumn];
        if (!this.library) fixedColumns.push(this.libraryColumn);
        if (this.appLibrary) fixedColumns.push(this.appItemColumn);
        this.selectedColumns = [
          ...fixedColumns.map((c) => c.internalName),
          ...params.map((p) => p.id),
        ]
          .filter((c) => this.view!.includes(c))
          .sort((a, b) => this.view!.indexOf(a) - this.view!.indexOf(b));

        const missing = this.selectedColumns.filter(
          (c) => !this.possibleColumns.some((p) => p.internalName == c)
        );
        if (missing.length > 0) {
          const userSettings = userSettings$.value;
          this.possibleColumns = [
            ...this.possibleColumns,
            ...missing.map((m) => {
              const param = this.metadataParams.find((p) => p.id == m)!;
              return new ColumnDef<LibraryItem>({
                internalName: param.id,
                displayName: param.title,
                sortable: param.type != ColumnType.GroupUser,
                filterable: false,
                valueAccessor: this.itemValueAccessor(
                  param.id,
                  this.metadataParams
                ),
                width: userSettings.librarySettings.find(
                  (l) => l.libraryId == this.library?.id
                )?.columnSizes[param.id],
                cellClass: 'ellipsis',
              });
            }),
          ];
        }
      });
  }

  itemValueAccessor(metadataParamId: string, params: MetadataParam[]) {
    let resultFn: (item: LibraryItem) => any = () => undefined;
    this.metadataParameters$
      .pipe(
        first(),
        map((params) => params.find((p) => p.id == metadataParamId))
      )
      .subscribe((param) => {
        switch (param?.type) {
          case ColumnType.Choice:
            resultFn = (item: LibraryItem) =>
              item.metadata
                .filter((m) => m.metadataParameter.id == metadataParamId)
                .map((m) => param.choices.find((c) => c.id == m.value)?.value)
                .join(', ');
            break;
          case ColumnType.ConsolidatedChoice:
            resultFn = (item: LibraryItem) =>
              item.metadata
                .filter(
                  (m) => m.metadataParameter.id == metadataParamId && m.value
                )
                .map((m) => {
                  if (m.value) {
                    const [paramId, choiceId] = m.value.split('.');
                    const choiceParam = params.find((p) => p.id == paramId);
                    return `(${choiceParam?.title}) ${
                      choiceParam?.choices.find((c) => c.id == choiceId)?.value
                    }`;
                  }
                  return undefined;
                })
                .join(', ');
            break;
          case ColumnType.DateTime:
            resultFn = (item: LibraryItem) => {
              const val = item.metadata.find(
                (m) => m.metadataParameter.id == metadataParamId
              )?.dateTimeValue;

              return val
                ? val.toFormat(param.format ?? 'dd/MM/yyyy HH:mm')
                : undefined;
            };
            break;
          case ColumnType.Number:
            resultFn = (item: LibraryItem) =>
              item.metadata.find(
                (m) => m.metadataParameter.id == metadataParamId
              )?.numberValue;
            break;
          case ColumnType.GroupUser:
            resultFn = (item: LibraryItem) =>
              item.metadata
                .filter((m) => m.metadataParameter.id == metadataParamId)
                .map((m) => m.groupUserValue?.displayName)
                .join(', ');
            break;
          default:
            resultFn = (item: LibraryItem) =>
              item.metadata.find(
                (m) => m.metadataParameter.id == metadataParamId
              )?.value;
        }
      });
    return resultFn;
  }

  pageChange(event: { pagesize: number; page: number }) {
    if (this.pageSize != event.pagesize) {
      const needNewItems =
        event.pagesize > this.pageSize * (this.highestPage + 1);
      this.highestPage = Math.floor(
        (this.pageSize * (this.highestPage + 1)) / event.pagesize
      );
      this.pageSize = event.pagesize;
      this.userSettingsUpdateSubject.next();
      this.page = 0;
      if (needNewItems)
        this.getItems({
          orderBy: this.orderBy,
          orderByDirection: this.orderByDirection,
          filter: this.filterValue,
          pageSize: this.pageSize,
          page: this.page,
          resetPaging: true,
        });
      else
        this.pagedItems = this.items.filter(
          (_, index) =>
            index >= this.page * this.pageSize &&
            index < (this.page + 1) * this.pageSize
        );
    } else {
      if (event.page > this.highestPage)
        this.getItems({
          orderBy: this.orderBy,
          orderByDirection: this.orderByDirection,
          filter: this.filterValue,
          pageSize: this.pageSize,
          page: event.page,
          resetPaging: false,
          callback: () => (this.highestPage = event.page),
        });
      else
        this.pagedItems = this.items.filter(
          (_, index) =>
            index >= event.page * this.pageSize &&
            index < (event.page + 1) * this.pageSize
        );
      this.page = event.page;
    }
  }

  sortChange(event: {
    sortedColumn: string;
    sortDirection: '' | 'asc' | 'desc';
  }) {
    this.orderBy = event.sortedColumn;
    this.orderByDirection =
      event.sortDirection == ''
        ? undefined
        : (event.sortDirection as 'asc' | 'desc');
    if (this.initialized) {
      this.userSettingsUpdateSubject.next();
    }

    this.page = 0;
    this.highestPage = 0;
    this.getItems({
      orderBy: this.orderBy,
      orderByDirection: this.orderByDirection,
      filter: this.filterValue,
      pageSize: this.pageSize,
      page: this.page,
      resetPaging: true,
    });
  }
  columnChange(columns: string[]) {
    this.items = [...this.items];
    this.pagedItems = this.items.filter(
      (_, index) =>
        index >= this.page * this.pageSize &&
        index < (this.page + 1) * this.pageSize
    );
    this.view = columns;
    if (this.initialized) {
      this.userSettingsUpdateSubject.next();
    }
  }

  columnWidthChange(change: { column: string; width: number }) {
    if (change.column == 'library') this.libraryColumn.width = change.width;
    else if (change.column == 'appItem')
      this.appItemColumn.width = change.width;
    else
      this.possibleColumns.find((c) => c.internalName == change.column)!.width =
        change.width;
    if (this.initialized) {
      this.userSettingsUpdateSubject.next();
    }
  }

  openPreview(item: LibraryItem) {
    this.previewItem = item;
    this.selectedItems = [item];
  }
  openDocument(item: LibraryItem, app = false) {
    const url = this.functions.getSPValueUrlById(item.sharepointId, true);
    const extension = item.fileLocation.split('.').pop()!;
    if (app) {
      forkJoin([
        this.libraryFacade.libraries$.pipe(first()),
        this.tilePageFacade.homePage$.pipe(first()),
      ])
        .pipe(
          switchMap(([libs, homepage]) => {
            let permissions: Permission[] = [];
            if (this.library)
              permissions = this.library.permissions.map((p) => p.permission);
            else if (item.library)
              permissions =
                libs
                  .find((l) => l.id == item.library?.id)
                  ?.permissions.map((p) => p.permission) ?? [];
            else
              permissions =
                homepage?.permissions.map((p) => p.permission) ?? [];
            const canEdit =
              !permissions.length ||
              permissions.some(
                (p) =>
                  (p.groupUser.user?.id == this.msal.userId ||
                    p.groupUser.group?.members?.some(
                      (m) => m.id == this.msal.userId
                    )) &&
                  p.permissionType != PermissionType.Read
              );
            return this.libDocumentService.provideOfficeScheme(
              item.sharepointId,
              extension,
              canEdit
            );
          })
        )
        .subscribe((appUrl) => {
          window.open(appUrl ? appUrl : url, '_blank');
        });
    } else {
      this.libDocumentService
        .providePreviewUrl(item.sharepointId, extension)
        .subscribe(({ data, type }) => {
          let newWindow: Window | null;

          if (type == PreviewType.Iframe) {
            const url = new URL(data);
            url.searchParams.delete('embedded');
            newWindow = window.open(url.toString(), '_blank');
            this.setTabTitleOnNewTab(newWindow, url.toString(), item.title);
          } else {
            newWindow = window.open(url, '_blank');
            this.setTabTitleOnNewTab(newWindow, url.toString(), item.title);
          }
        });
    }
  }
  setTabTitleOnNewTab(newWindow: Window | null, url: string, title: string) {
    if(newWindow){
      newWindow.document.write(`<iframe src="${url}" frameborder="0" width=“100%” height=“100%” allowfullscreen style="width: 100%; height: 100%"></iframe>`);
      newWindow.document.title = title;
    }
  }

  openDocuments(items: LibraryItem[], app = false) {
    items.forEach((item) => this.openDocument(item, app));
  }

  downloadDocument(item: LibraryItem) {
    let relativeUrl = item.fileLocation.substring(
      item.fileLocation.indexOf('sharepoint.com/') + 14
    );
    if (relativeUrl.startsWith('//')) relativeUrl = relativeUrl.substring(1);

    const iframe = document.createElement('iframe');
    iframe.style.visibility = 'collapse';
    document.body.append(iframe);
    const url = this.functions.getSPValueUrl(relativeUrl);
    const queryParams = new URLSearchParams(url.split('?').pop());
    let form = `<form action="${url.replace(/"/g, '"')}" method="GET">`;
    queryParams.forEach(
      (val, key) => (form += `<input hidden name="${key}" value="${val}">`)
    );
    form += '</form>';

    iframe.contentDocument?.write(form);
    iframe.contentDocument?.forms[0].submit();

    setTimeout(() => iframe.remove(), 2000);
  }

  downloadDocuments(items: LibraryItem[]) {
    //TODO: Zip on backend
    combineLatest([
      this.libraryFacade.libraries$.pipe(first()),
      this.tilePageFacade.homePage$.pipe(first()),
    ]).subscribe(([libs, homepage]) => {
      items.forEach((item) => {
        let permissions: Permission[] = [];
        if (this.library)
          permissions = this.library.permissions.map((p) => p.permission);
        else if (item.library)
          permissions =
            libs
              .find((l) => l.id == item.library?.id)
              ?.permissions.map((p) => p.permission) ?? [];
        else permissions = homepage?.permissions.map((p) => p.permission) ?? [];
        const canDownload =
          !permissions.length ||
          permissions.some(
            (p) =>
              (p.groupUser.user?.id == this.msal.userId ||
                p.groupUser.group?.members?.some(
                  (m) => m.id == this.msal.userId
                )) &&
              p.downloadPermission
          );
        if (canDownload) this.downloadDocument(item);
        else
          this.ishtarCommunicationService.addNotification(
            new Notification({
              type: NotificationType.Warning,
              label: `${this.getTranslation(
                'downloadFailed'
              )} ${item.fileLocation.split('/').pop()}' ${this.getTranslation(
                'insufficientPermissionsReason'
              )}`,
            })
          );
      });
    });
  }

  updateItem(item: LibraryItem) {
    this.items.splice(
      this.items.findIndex((i) => i.id == item.id),
      1,
      item
    );
    this.selectedItems.splice(
      this.selectedItems.findIndex((i) => i.id == item.id),
      1,
      item
    );
    this.pagedItems = this.items.filter(
      (_, index) =>
        index >= this.page * this.pageSize &&
        index < (this.page + 1) * this.pageSize
    );
    if (this.previewItem?.id == item.id) this.previewItem = item;
  }

  removeItem(data: { reloadPage: boolean }) {
    this.previewItem = undefined;
    if (data.reloadPage) {
      this.getItems();
    }
  }

  private apps: { [key: string]: string } = {
    IshtarTask: AppComponent.IshtarTasksAppName,
  };

  openIshtarApp(appItem: AppItemInfo) {
    if (appItem.tableName) {
      const app = this.apps[appItem.tableName];
      if (app) {
        this.api
          .getAppInfo(app)
          .pipe(withLatestFrom(this.route.queryParamMap))
          .subscribe(([info, params]) => {
            window.open(
              `${info.appUrl}?tenantId=${params.get('tenantId')}&itemId=${
                appItem.value
              }`,
              '_blank'
            );
          });
      }
    }
  }

  updateUserSettings() {
    const userSettings = userSettings$.value;
    userSettings$.next(
      new UserSettings({
        ...userSettings,
        librarySettings: [
          ...userSettings.librarySettings.filter(
            (l) => l.libraryId != this.library?.id
          ),
          new LibraryUserSettings({
            libraryId: this.library?.id,
            columnOrder: this.view,
            sortColumn: this.orderBy,
            sortDirection: this.orderByDirection,
            pageSize: this.pageSize,
            columnSizes: [
              ...this.possibleColumns,
              this.appItemColumn,
              this.libraryColumn,
            ]
              .filter((c) => c.width)
              .reduce((acc, c) => ({ ...acc, [c.internalName]: c.width }), {}),
          }),
        ],
      })
    );
    this.api.updateUserSettings(userSettings$.value).subscribe();
  }
}
