import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CoreModule } from 'src/app/core/core.module';
import { config$ } from 'src/app/core/data/data.observables';
import { TileAction } from 'src/app/core/domain/enums/tile-action.enum';
import { TilePageSegment } from 'src/app/core/domain/models/tile-page-segment.model';
import { TilePage } from 'src/app/core/domain/models/tile-page.model';
import { TileComponent } from './tile/tile.component';
import { Tile } from 'src/app/core/domain/models/tile.model';
import { BaseEntity } from 'processdelight-angular-components';

@Component({
  standalone: true,
  selector: 'app-tile-page',
  templateUrl: './tile-page.component.html',
  styleUrls: ['./tile-page.component.scss'],
  imports: [CoreModule, TileComponent],
})
export default class TilePageComponent implements OnChanges {
  @Input() page?: TilePage;

  config = config$.value;

  TileAction = TileAction;

  entityTrackBy = (_index: number, item: BaseEntity) => item.id;

  segments: TilePageSegment[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page) {
      this.segments =
        this.page?.segments
          .map((s) => s.tilePageSegment)
          .filter((s) => !s.disabled)
          .sort((a, b) => a.position - b.position) ?? [];
    }
  }

  getTiles(segment: TilePageSegment) {
    return segment.tiles
      .map((t) => t.tile)
      .filter((t) => !t.disabled)
      .sort((a, b) => a.position - b.position);
  }

  calcTileSize(containerWidth: number) {
    return Math.min(containerWidth / (this.config.lineSize + 1), 80);
  }
}
