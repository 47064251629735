import { ObserversModule } from '@angular/cdk/observers';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { DateTime } from 'luxon';
import {
  Subject,
  combineLatest,
  debounceTime,
  delay,
  filter,
  first,
  forkJoin,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { CoreModule } from 'src/app/core/core.module';
import {
  groupUsers$,
  translations$,
  userSettings$,
} from 'src/app/core/data/data.observables';
import { ColumnType } from 'src/app/core/domain/enums/column-type.enum';
import { MetadataParam } from 'src/app/core/domain/models/metadata-param.model';
import { MetadataFacade } from 'src/app/core/store/metadata/metadata.facade';
import { Library } from '../../domain/models/library.model';
import {
  DateStartEnd,
  MetadataFilterType,
  MetadataType,
  compareMetadataValues,
} from '../../helper/metadata.functions';
import { ApiService } from '../../services/api.service';
import { LibraryFacade } from '../../store/library/library.facade';
import { AdaptableInputComponent } from './adaptable-input/adaptable-input.component';
import { TilePickerComponent } from './tile-picker/tile-picker.component';
import { GroupUser } from 'processdelight-angular-components';

@Component({
  standalone: true,
  selector: 'app-metadata-inputs',
  templateUrl: './metadata-inputs.component.html',
  styleUrls: ['./metadata-inputs.component.scss'],
  imports: [
    CoreModule,
    MatGridListModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    ReactiveFormsModule,
    TilePickerComponent,
    AdaptableInputComponent,
    ObserversModule,
  ],
})
export class MetadataInputsComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() library?: Library;
  @Input() value?: {
    [key: string]: MetadataFilterType;
  };
  @Input() previousValue?: {
    [key: string]: MetadataFilterType;
  };
  @Input() filter = false;
  @Input() edit = false;
  @Input() disabled = false;
  @Input() buttonTemplate?: TemplateRef<{
    value: {
      [key: string]: MetadataFilterType;
    };
    clearValue: () => void;
  }>;
  @Input() maxHeight?: string;
  @Input() admin = false;
  @Input() copy = false;
  @Input() extension?: string;

  @Output() valueChanges = new EventEmitter<{
    [key: string]: MetadataFilterType;
  }>();

  @Output() formValid = new EventEmitter<boolean>();
  @Output() addedParam = new EventEmitter<MetadataParam>();

  metadata$ = this.metadataFacade.metadataParams$;

  shownMetadata: MetadataParam[] = [];

  groupUsers$ = groupUsers$;

  form = new FormGroup<{
    [key: string]: FormControl<MetadataFilterType | null>;
  }>({});

  destroy$ = new Subject<void>();

  blockNextTileChange = false;

  tileObjectUrl?: string;

  @ViewChild('container') containerRef?: ElementRef;
  private resizeObserver?: ResizeObserver;

  get formControlsLength() {
    return Object.keys(this.form.controls).length;
  }

  paramTrackBy = (_: number, p: MetadataParam) => p.id;

  ColumnType = ColumnType;

  fixedParams: MetadataParam[] = [];
  fileNameParam?: MetadataParam;

  constructor(
    private readonly metadataFacade: MetadataFacade,
    private readonly libraryFacade: LibraryFacade,
    private readonly api: ApiService,
    private readonly cd: ChangeDetectorRef
  ) {}

  @HostListener('window:resize')
  onResize() {
    this._cols = undefined;
    this._emptyTiles = undefined;
  }

  getTranslation(label: string): string {
    return translations$.value[label];
  }

  ngOnInit(): void {
    if (this.admin) this.metadata$ = this.metadataFacade.adminMetadataParams$;
    combineLatest([
      this.metadata$,
      this.metadataFacade.fixedParams$.pipe(first()),
      this.groupUsers$.pipe(first()),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([params, fixedParams, groupUsers]) => {
        this.cd.detach();
        this.fixedParams = fixedParams;
        this.fileNameParam = fixedParams.find((p) => p.fileNameParam);
        this.shownMetadata = [
          ...(this.edit
            ? fixedParams
            : this.fileNameParam
            ? [this.fileNameParam]
            : []),
        ];
        const userSettings = userSettings$.value;
        const libSettings = userSettings.librarySettings.find(
          (ls) => ls.libraryId == this.library?.id
        );
        this.shownMetadata.push(
          ...params.filter(
            (p) =>
              !this.shownMetadata.some((m) => m.id == p.id) &&
              (this.value?.[p.id] ||
                (this.library
                  ? this.library.configuredParams.some(
                      (cp) => cp.paramId == p.id
                    )
                  : !fixedParams.some((m) => m.id == p.id)))
          )
        );
        this.shownMetadata.sort((a, b) => {
          if (!libSettings) return 1;
          const aIndex = libSettings.columnOrder.findIndex((c) => c == a.id);
          const bIndex = libSettings.columnOrder.findIndex((c) => c == b.id);
          if (aIndex == -1) return 1;
          if (bIndex == -1) return -1;
          return aIndex - bIndex;
        });
        this.shownMetadata.forEach((p) =>
          this.form.addControl(
            p.id,
            p.type == ColumnType.DateTime && this.filter
              ? new FormControl<DateStartEnd | null>({
                  start: this.mapValue(
                    p,
                    groupUsers ?? [],
                    (
                      this.value?.[p.id] as {
                        start: DateTime;
                      }
                    )?.start
                  ) as DateTime,
                  end: this.mapValue(
                    p,
                    groupUsers ?? [],
                    (
                      this.value?.[p.id] as {
                        end: DateTime;
                      }
                    )?.end
                  ) as DateTime,
                })
              : new FormControl<MetadataType | null>(
                  {
                    value: this.mapValue(
                      p,
                      groupUsers ?? [],
                      this.value?.[p.id] as any
                    ) as MetadataType,
                    disabled:
                      !this.filter &&
                      (p.createdByParam ||
                        p.createdOnParam ||
                        p.modifiedByParam ||
                        p.modifiedOnParam),
                  },
                  (!this.filter && p.fileNameParam) ||
                  (p.required &&
                    !(
                      p.createdByParam ||
                      p.createdOnParam ||
                      p.modifiedByParam ||
                      p.modifiedOnParam
                    ) &&
                    (!this.library ||
                      this.library.configuredParams.some(
                        (cp) => cp.paramId == p.id
                      )))
                    ? [Validators.required]
                    : []
                )
          )
        );
        this.formValid.next(this.form.valid);

        if (this.disabled) this.form.disable();
        this.cd.detectChanges();
        this.cd.reattach();

        this.form.valueChanges
          .pipe(takeUntil(this.destroy$), debounceTime(500))
          .subscribe(() => {
            const mappedVal = Object.fromEntries(
              Object.entries(this.form.getRawValue())
                .filter(([_, val]) => {
                  if (
                    val &&
                    typeof val == 'object' &&
                    'start' in val &&
                    'end' in val
                  )
                    return val.start != undefined || val.end != undefined;
                  else return val != undefined;
                })
                .map((val) => val as [string, MetadataFilterType])
            );
            this.value = mappedVal;
            this.valueChanges.next(mappedVal);
            const pendingControls = Object.values(this.form.controls).filter(
              (c) => c.status == 'PENDING'
            );
            if (pendingControls.length > 0) {
              forkJoin(
                pendingControls.map((c) =>
                  c.statusChanges.pipe(
                    filter((s) => s !== 'PENDING'),
                    first()
                  )
                )
              )
                .pipe(first(), delay(100))
                .subscribe((x) => {
                  this.formValid.next(this.form.valid);
                });
            } else {
              this.formValid.next(this.form.valid);
            }
          });
      });
    userSettings$.pipe(takeUntil(this.destroy$)).subscribe((userSettings) => {
      const libSettings = userSettings.librarySettings.find(
        (ls) => ls.libraryId == this.library?.id
      );
      if (libSettings) {
        this.shownMetadata.sort((a, b) => {
          if (!libSettings) return 1;
          const aIndex = libSettings.columnOrder.findIndex((c) => c == a.id);
          const bIndex = libSettings.columnOrder.findIndex((c) => c == b.id);
          if (aIndex == -1) return 1;
          if (bIndex == -1) return -1;
          return aIndex - bIndex;
        });
      }
    });
  }

  ngAfterViewInit() {
    if (this.containerRef) {
      this.resizeObserver = new ResizeObserver((entries) =>
        entries.length ? this.onResize() : undefined
      );
      this.resizeObserver.observe(this.containerRef.nativeElement);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.value &&
      !compareMetadataValues(
        changes.value.currentValue,
        changes.value.previousValue
      )
    ) {
      if (
        this.value &&
        Object.keys(this.value).length &&
        Object.keys(this.form.controls).length
      ) {
        combineLatest([
          this.metadataFacade.metadataParams$.pipe(first()),
          this.groupUsers$.pipe(first()),
        ]).subscribe(([params, groupUsers]) => {
          const controlKeys = Object.keys(this.form.controls);
          const val = Object.fromEntries(
            Object.entries(this.value!)
              .filter(([k]) => controlKeys.includes(k))
              .map(([k, v]) => [
                k,
                this.mapValue(
                  params.find((p) => p.id == k)!,
                  groupUsers ?? [],
                  v as MetadataType
                ),
              ])
          );
          Object.keys(this.form.controls).forEach((k) => {
            if (val[k] == undefined) val[k] = null;
          });
          this.form.setValue(val);
          this.form.markAsPristine();
        });
      } else {
        this.form.reset();
      }
    }
    if (
      this.fileNameParam &&
      changes.copy &&
      changes.copy.currentValue !== changes.copy.previousValue
    ) {
      const fileNameControl = this.form.get(this.fileNameParam.id);
      if (fileNameControl) {
        if (this.copy) {
          this.form.get(this.fileNameParam.id)?.setAsyncValidators([
            (control) =>
              this.libraryFacade.libraries$.pipe(
                first(),
                switchMap((libs) =>
                  this.api.checkFileName(
                    libs.find((lib) => lib.id === this.library?.id)
                      ?.sharepointUrl ?? 'IshtarDMSLibraryItems',
                    [control.value!, this.extension].join('.')
                  )
                ),
                map((res) => (res ? { nameExists: true } : null))
              ),
          ]);
        } else {
          this.form.get(this.fileNameParam.id)?.clearAsyncValidators();
        }
        fileNameControl.updateValueAndValidity();
      }
    }
  }

  ngOnDestroy() {
    if (this.containerRef)
      this.resizeObserver?.unobserve(this.containerRef.nativeElement);
    this.resizeObserver?.disconnect();
    this.destroy$.next();
    this.destroy$.complete();
  }

  getGridColSize(count: number) {
    return Math.max(1, Math.min(12, Math.floor(12 / count)));
  }

  mapValue(
    param: MetadataParam,
    groupUsers: GroupUser[],
    value?: MetadataFilterType
  ): MetadataFilterType | null {
    if (!value) return null;
    switch (param?.type) {
      case ColumnType.GroupUser:
        return !this.filter && param.multi
          ? (value as GroupUser[])
              .map((v) =>
                v
                  ? groupUsers.find((g) => g.id == new GroupUser(v).id)
                  : undefined
              )
              .filter((v) => !!v)
              .map((v) => v as GroupUser)
          : groupUsers.find(
              (g) => g.id == new GroupUser(value as GroupUser).id
            ) ?? null;
      case ColumnType.DateTime: {
        if (typeof value == 'object') return value;
        return DateTime.fromISO(value as string);
      }
      default:
        return value;
    }
  }

  public addMetadataParamToView(metadataParam: MetadataParam) {
    if (!this.shownMetadata.some((m) => m.id == metadataParam.id)) {
      this.form.addControl(metadataParam.id, new FormControl(null));
      this.shownMetadata.push(metadataParam);
      this._emptyTiles = undefined;
    }
  }

  private _cols?: number;

  calcCols(containerWidth: number) {
    return this._cols != undefined
      ? this._cols
      : (this._cols = Math.max(1, Math.floor(containerWidth / 280)));
  }

  calcSize(containerWidth: number, param: MetadataParam) {
    return Math.min(this.calcCols(containerWidth), 1);
  }

  private _emptyTiles?: number[];

  calcEmptyTiles(containerWidth: number, params: MetadataParam[]) {
    if (this._emptyTiles != undefined) return this._emptyTiles;
    const sum = (...n: number[]) => n.reduce((acc, v) => acc + v, 0);
    const cols = this.calcCols(containerWidth);
    const sizes = params.map((p) => this.calcSize(containerWidth, p));
    while (sum(...sizes) > cols) {
      const collection: number[] = [];
      while (sizes.length && sum(...collection) + sizes[0] <= cols)
        collection.push(sizes.shift()!);
    }
    const lastSum = sum(...sizes);
    return (this._emptyTiles = (
      [].constructor(
        lastSum == cols ? cols - 1 : cols - lastSum - 1
      ) as number[]
    ).fill(0));
  }

  clearControl(controlName: string) {
    this.form.controls[controlName].reset();
  }

  clearValue() {
    this.value = {};
  }

  markAsPristine() {
    this.form.markAsPristine();
  }

  hasValue(paramId: string) {
    return this.value?.[paramId] != undefined;
  }
}
