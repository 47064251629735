import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  ActionBarComponent,
  ContextMenuAction,
} from 'processdelight-angular-components';
import {
  Observable,
  Subject,
  first,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { CoreModule } from 'src/app/core/core.module';
import { languages$, translations$ } from 'src/app/core/data/data.observables';
import { MetadataChoiceTranslation } from 'src/app/core/domain/models/metadata-choice-translation.model';
import { MetadataChoice } from 'src/app/core/domain/models/metadata-choice.model';
import { MetadataParam } from 'src/app/core/domain/models/metadata-param.model';
import { MetadataFacade } from 'src/app/core/store/metadata/metadata.facade';

@Component({
  standalone: true,
  selector: 'app-add-choice-popup',
  templateUrl: './add-choice-popup.component.html',
  styleUrls: ['./add-choice-popup.component.scss'],
  imports: [
    ActionBarComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    CoreModule,
    MatInputModule,
  ],
})
export class AddChoicePopupComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  actions: ContextMenuAction<unknown>[] = [];
  iconActions: ContextMenuAction<unknown>[] = [];
  choiceForm!: FormGroup;
  languages = languages$.value;
  destroys$ = new Subject<void>();

  saveButtonsDisabled$?: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      value: string;
      choiceParam: MetadataParam;
    },
    public addChoiceDialogRef: MatDialogRef<AddChoicePopupComponent>,
    private metadataFacade: MetadataFacade
  ) {}

  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  get translationArrayControls() {
    return (this.choiceForm.get('translations') as FormArray).controls;
  }

  ngOnInit(): void {
    this.choiceForm = new FormGroup({
      value: new FormControl(this.data.value ?? '', Validators.required),
      translations: new FormArray(
        this.data.choiceParam.languages
          ? this.data.choiceParam.languages.map(
              (l) =>
                new FormGroup({
                  language: new FormControl(l.languageId),
                  value: new FormControl(''),
                })
            )
          : []
      ),
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.saveButtonsDisabled$ = this.choiceForm.valueChanges.pipe(
        startWith(this.choiceForm.value),
        takeUntil(this.destroys$),
        switchMap(() =>
          this.canAddItem$(this.choiceForm.get('value')?.value).pipe(
            map((v) => !v || this.choiceForm.invalid)
          )
        )
      );
      this.iconActions.push(
        new ContextMenuAction({
          icon: 'close',
          label: 'close',
          action: () => this.addChoiceDialogRef.close(),
        })
      );
      this.actions = [
        new ContextMenuAction({
          icon: 'save',
          label: this.getTranslation$('save'),
          action: () => this.onSave(),
          disabled: this.saveButtonsDisabled$,
        }),
        new ContextMenuAction({
          icon: 'close',
          label: this.getTranslation$('cancel'),
          action: () => this.addChoiceDialogRef.close(),
        }),
      ];
    }, 0);
  }

  ngOnDestroy(): void {
    this.destroys$.next();
    this.destroys$.complete();
  }

  canAddItem$(value: string) {
    return this.metadataFacade.adminMetadataParams$.pipe(
      first(),
      map((params) => {
        const param = params.find((p) => p.id == this.data.choiceParam.id);
        return (
          param?.choices?.every(
            (c) => c.value.toLowerCase().trim() !== value.toLowerCase().trim()
          ) ?? false
        );
      })
    );
  }

  onSave() {
    const translations = (this.choiceForm.get('translations') as FormArray)
      .controls;
    const metadataChoice = new MetadataChoice({
      metadataParameterId: this.data.choiceParam.id,
      value: this.choiceForm.get('value')?.value,
      position: this.data.choiceParam.choices.length,
      translations: translations
        ? translations.map(
            (c) =>
              new MetadataChoiceTranslation({
                translation: c.get('value')?.value,
                languageId: c.get('language')?.value,
              })
          )
        : [],
    });
    this.addChoiceDialogRef.close(metadataChoice);
  }

  getLanguage(index: number) {
    const language = this.languages.find(
      (l) =>
        l.id ==
        (this.choiceForm.get('translations') as FormArray).controls[index].get(
          'language'
        )?.value
    );
    return language?.title + ' (' + language?.code + ')';
  }
}
