import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { translations$ } from '../../data/data.observables';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-data-modified-popup',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatDialogModule, MatButtonModule],
  templateUrl: './data-modified-popup.component.html',
  styleUrls: ['./data-modified-popup.component.scss'],
})
export class DataModifiedPopupComponent {
  confirmAction?: () => void;
  title = this.getTranslation$('unsavedChangesExternalChange');
  msg = this.getTranslation$('unsavedChangesExternalChangeMessage');
  confirmText = this.getTranslation$('confirm');
  showCancel = true;
  cancelText = this.getTranslation$('cancel');
  confirmIcon = 'check';
  cancelIcon = 'close';
  confirmData?: any;
  cancelData?: any;

  getTranslation(label: string): string {
    return translations$.value[label];
  }
  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  constructor(
    public dialogRef: MatDialogRef<DataModifiedPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public confirmPopupData: {
      title: Observable<string>;
      msg?: Observable<string>;
      confirmText?: Observable<string>;
      cancelText?: Observable<string>;
      showCancel?: boolean;
      confirmIcon?: string;
      cancelIcon?: string;
      confirmData?: any;
      cancelData?: any;
      confirmAction?: () => void;
    }
  ) {
    this.confirmAction = confirmPopupData.confirmAction;
    this.msg = confirmPopupData.msg || this.msg;
    this.confirmText = confirmPopupData.confirmText || this.confirmText;
    this.cancelText = confirmPopupData.cancelText || this.cancelText;
    this.confirmIcon = confirmPopupData.confirmIcon || this.confirmIcon;
    this.cancelIcon = confirmPopupData.cancelIcon || this.cancelIcon;
    this.showCancel = confirmPopupData.showCancel ?? this.showCancel;
    this.title = confirmPopupData.title;
    this.confirmData = confirmPopupData.confirmData;
    this.cancelData = confirmPopupData.cancelData;
  }

  confirm() {
    if (this.confirmAction) this.confirmAction();
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
