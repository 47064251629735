import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { LibraryItem } from 'src/app/core/domain/models/item.model';
import { MoveItemsDialogComponent } from '../move-items-dialog/move-items-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { LibraryFacade } from 'src/app/core/store/library/library.facade';
import { combineLatest, first, map } from 'rxjs';
import { Permission } from 'src/app/core/domain/models/permission.model';
import { TilePageFacade } from 'src/app/core/store/tilepage/tilepage.facade';
import { MicrosoftAuthenticationService } from 'processdelight-angular-components';
import { LibraryIconService } from 'src/app/core/services/library-icon.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MetadataFacade } from 'src/app/core/store/metadata/metadata.facade';
import { translations$ } from 'src/app/core/data/data.observables';

@Component({
  selector: 'app-delete-items-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatListModule,
  ],
  templateUrl: './delete-items-dialog.component.html',
  styleUrls: ['./delete-items-dialog.component.scss'],
})
export class DeleteItemsDialogComponent implements OnInit {
  confirmAction!: (items: LibraryItem[]) => void;
  items: LibraryItem[] = [];
  itemsWithNoPermissions: LibraryItem[] = [];

  fileNameMap: { [key: string]: string } = {};

  constructor(
    private dialogRef: MatDialogRef<MoveItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      confirmAction: (items: LibraryItem[]) => void;
      items: LibraryItem[];
    },
    private readonly libraryFacade: LibraryFacade,
    private readonly tilePageFacade: TilePageFacade,
    private readonly metadataFacade: MetadataFacade,
    private readonly msal: MicrosoftAuthenticationService,
    private readonly libraryIconService: LibraryIconService,
    private readonly domSanitizer: DomSanitizer
  ) {}

  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  ngOnInit(): void {
    this.confirmAction = this.data.confirmAction;
    combineLatest([
      this.libraryFacade.libraries$.pipe(first()),
      this.tilePageFacade.homePage$.pipe(first()),
      this.metadataFacade.fileNameParam$.pipe(first()),
    ]).subscribe(([libs, homepage, fileNameParam]) => {
      this.data.items.forEach((item) => {
        this.fileNameMap[item.id] =
          item.metadata.find((m) => m.metadataParameter.id == fileNameParam?.id)
            ?.value ?? '';
        let permissions: Permission[] = [];
        if (item.library)
          permissions =
            libs
              .find((l) => l.id == item.library?.id)
              ?.permissions.map((p) => p.permission) ?? [];
        else permissions = homepage?.permissions.map((p) => p.permission) ?? [];
        if (
          !permissions.length ||
          permissions.some(
            (p) =>
              (p.groupUser.user?.id == this.msal.userId ||
                p.groupUser.group?.members?.some(
                  (m) => m.id == this.msal.userId
                )) &&
              p.deletePermission
          )
        ) {
          this.items.push(item);
        } else {
          this.itemsWithNoPermissions.push(item);
        }
      });
    });
  }

  getIcon(item: LibraryItem) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.libraryIconService.provideIcon(item.fileLocation)!
    );
  }

  confirm() {
    this.confirmAction(this.items);
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
