import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { BaseFacade } from '../base.facade';
import {
  createNotification,
  createNotificationResolved,
  deleteNotification,
  getNotifications,
  resetSlice,
  updateNotification,
} from './notification.actions';
import { notificationSelectors } from './notification.selectors';
import {
  ApplicationNotification,
  CreateNotificationRequest,
} from 'processdelight-angular-components';

@Injectable({
  providedIn: 'root',
})
export class NotificationFacade extends BaseFacade {
  notifications$ = this._store.pipe(
    select(notificationSelectors.getNotifications)
  );
  constructor(store: Store<AppState>) {
    super(store);
  }

  getNotifications$() {
    return this.dispatchAction$(getNotifications);
  }

  createNotification$(notification: CreateNotificationRequest) {
    return this.dispatchAction$(createNotification, { notification });
  }

  createNotificationResolved(notification: ApplicationNotification) {
    this._store.dispatch(
      createNotificationResolved({ notification: notification })
    );
  }

  updateNotification$(notification: CreateNotificationRequest) {
    return this.dispatchAction$(updateNotification, { notification });
  }

  deleteNotification$(notificationId: string) {
    return this.dispatchAction$(deleteNotification, { notificationId });
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
