import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Notification,
  NotificationService,
  NotificationType,
} from 'processdelight-angular-components';
import {
  catchError,
  map,
  mergeMap,
  of,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { translations$ } from '../../data/data.observables';
import { ApiService } from '../../services/api.service';
import { MetadataFacade } from '../metadata/metadata.facade';
import {
  createLibrary,
  createLibraryItems,
  createLibraryItemsResolved,
  createLibraryPermission,
  createLibraryPermissionResolved,
  createLibraryResolved,
  deleteLibrary,
  deleteLibraryItems,
  deleteLibraryItemsResolved,
  deleteLibraryPermission,
  deleteLibraryPermissionResolved,
  deleteLibraryResolved,
  getLibraries,
  getLibrariesResolved,
  getLibraryItems,
  getLibraryItemsResolved,
  getLibraryTriggers,
  getLibraryTriggersResolved,
  getSharedItems,
  updateLibrary,
  updateLibraryItems,
  updateLibraryItemsForMove,
  updateLibraryItemsResolved,
  updateLibraryPermission,
  updateLibraryPermissionResolved,
  updateLibraryResolved,
} from './library.actions';
import { LibraryItem } from '../../domain/models/item.model';
import { DateTime } from 'luxon';

@Injectable({ providedIn: 'root' })
export class LibraryEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private snackbar: MatSnackBar,
    private metadataFacade: MetadataFacade,
    private notifications: NotificationService
  ) {}

  getTranslation(label: string): string {
    return translations$.value[label];
  }

  getLibraries = createEffect(() =>
    this.actions$.pipe(
      ofType(getLibraries),
      switchMap(({ callback, error }) =>
        this.api.getLibraries().pipe(
          tap((result) => (callback ? callback(result) : undefined)),
          switchMap((libraries) => of(getLibrariesResolved({ libraries }))),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );

  createLibrary = createEffect(() =>
    this.actions$.pipe(
      ofType(createLibrary),
      mergeMap(({ library, callback, error }) =>
        this.api.createLibrary(library).pipe(
          tap((library) => (callback ? callback(library) : undefined)),
          switchMap((library) => {
            this.snackbar
              .open(this.getTranslation('libraryCreated'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(createLibraryResolved({ library }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorCreateLibrary'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );

  updateLibrary = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLibrary),
      mergeMap(({ library, callback, error }) =>
        this.api.updateLibrary(library).pipe(
          tap((library) => (callback ? callback(library) : undefined)),
          switchMap((library) => {
            this.snackbar
              .open(this.getTranslation('libraryUpdated'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(updateLibraryResolved({ library }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorUpdateLibrary'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );

  deleteLibrary = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLibrary),
      mergeMap(({ libraryId, callback, error }) =>
        this.api.deleteLibrary(libraryId).pipe(
          tap(() => (callback ? callback(libraryId) : undefined)),
          mergeMap(() => {
            this.snackbar
              .open(this.getTranslation('libraryDeleted'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(deleteLibraryResolved({ libraryId }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorDeleteLibrary'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  createLibraryPermission = createEffect(() =>
    this.actions$.pipe(
      ofType(createLibraryPermission),
      mergeMap(({ permission, libraryId, callback, error }) =>
        this.api.createLibraryPermission(permission, libraryId).pipe(
          tap((permission) => (callback ? callback(permission) : undefined)),
          mergeMap((permission) => {
            this.snackbar
              .open(this.getTranslation('permissionCreated'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(createLibraryPermissionResolved({ permission }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorCreatePermission'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  updateLibraryPermission = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLibraryPermission),
      mergeMap(({ permission, callback, error }) =>
        this.api.updateLibraryPermissions([permission]).pipe(
          tap(() => (callback ? callback(permission) : undefined)),
          mergeMap(() => {
            this.snackbar
              .open(this.getTranslation('permissionUpdated'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(updateLibraryPermissionResolved({ permission }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorPermissionUpdate'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  deleteLibraryPermission = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLibraryPermission),
      mergeMap(({ permissionId, callback, error }) =>
        this.api.deleteLibraryPermissions([permissionId]).pipe(
          tap(() => (callback ? callback(permissionId) : undefined)),
          mergeMap(() => {
            this.snackbar
              .open(this.getTranslation('permissionDeleted'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(deleteLibraryPermissionResolved({ permissionId }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorDeletePermission'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );

  getSharedItems = createEffect(() =>
    this.actions$.pipe(
      ofType(getSharedItems),
      mergeMap(
        ({
          orderBy,
          orderByDirection,
          filters,
          pageSize,
          page,
          callback,
          error,
        }) =>
          this.api
            .getSharedItems(orderBy, orderByDirection, filters, pageSize, page)
            .pipe(
              tap((result) => (callback ? callback(result) : undefined)),
              switchMap((result) => of(getLibraryItemsResolved(result))),
              catchError((e) => {
                if (error) error(e);
                return [];
              })
            )
      )
    )
  );
  getLibraryItems = createEffect(() =>
    this.actions$.pipe(
      ofType(getLibraryItems),
      mergeMap(
        ({
          orderBy,
          orderByDirection,
          filters,
          pageSize,
          page,
          libraryId,
          reset$,
          callback,
          error,
        }) =>
          this.api
            .getLibraryItems(
              orderBy,
              orderByDirection,
              filters,
              pageSize,
              page,
              libraryId
            )
            .pipe(
              reset$ ? takeUntil(reset$()) : tap(),
              tap((result) => (callback ? callback(result) : undefined)),
              switchMap((result) => of(getLibraryItemsResolved(result))),
              catchError((e) => {
                if (error) error(e);
                return [];
              })
            )
      )
    )
  );
  createLibraryItems = createEffect(() =>
    this.actions$.pipe(
      ofType(createLibraryItems),
      mergeMap(({ items, callback, error }) =>
        this.api.createLibraryItems(items).pipe(
          tap((items) => (callback ? callback(items) : undefined)),
          mergeMap((items) => {
            this.snackbar
              .open(this.getTranslation('documentsUploaded'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(createLibraryItemsResolved({ items }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorUploadingDocuments'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  updateLibraryItemsForMove = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLibraryItemsForMove),
      mergeMap(({ items, callback, error }) =>
        this.api.updateLibraryItemsForMove(items).pipe(
          tap((items) => (callback ? callback(items) : undefined)),
          mergeMap((updated) => {
            this.snackbar.open('Documents updated', 'Ok', {
              panelClass: 'app-notification-success',
              duration: 3000,
            });
            return of(updateLibraryItemsResolved({ items: updated }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorUpdateDocuments'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  updateLibraryItems = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLibraryItems),
      withLatestFrom(this.metadataFacade.fileNameParam$),
      mergeMap(([{ items, callback, error }, fileNameParam]) =>
        this.api.updateLibraryItems(items).pipe(
          map((newItems) =>
            newItems.map(
              (i) =>
                new LibraryItem({
                  ...i,
                  appItems: items.find((x) => x.id == i.id)?.appItems,
                })
            )
          ),
          tap((items) => (callback ? callback(items) : undefined)),
          mergeMap((updated) => {
            this.snackbar.open('Documents updated', 'Ok', {
              panelClass: 'app-notification-success',
              duration: 3000,
            });
            const failedFileNameUpdates = items.filter(
              (i) =>
                i.metadata.find(
                  (m) => m.metadataParameterId == fileNameParam?.id
                )?.value !==
                updated
                  .find((u) => u.id === i.id)
                  ?.metadata.find(
                    (m) => m.metadataParameterId == fileNameParam?.id
                  )?.value
            );
            if (failedFileNameUpdates.length) {
              this.snackbar.open(
                this.getTranslation('errorUpdateFiles'),
                'Ok',
                {
                  panelClass: 'app-notification-error',
                  duration: 3000,
                }
              );
              failedFileNameUpdates.forEach((f) => {
                this.notifications.addNotification(
                  new Notification({
                    type: NotificationType.Error,
                    label: `${this.getTranslation('errorUpdateFileName')} '${
                      f.metadata.find(
                        (m) => m.metadataParameterId == fileNameParam?.id
                      )?.value
                    }' ${this.getTranslation('fileLockedByAnotherUser')}`,
                  })
                );
              });
            }
            return of(updateLibraryItemsResolved({ items: updated }));
          }),
          catchError((e) => {
            console.error(e);
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorUpdateDocuments'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  deleteLibraryItems = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteLibraryItems),
      mergeMap(({ itemIds, callback, error }) =>
        this.api.deleteLibraryItems(itemIds).pipe(
          tap(() => (callback ? callback(itemIds) : undefined)),
          mergeMap(() => {
            if (itemIds.length == 0) {
              return of(deleteLibraryItemsResolved({ itemIds }));
            }
            this.snackbar
              .open(this.getTranslation('documentsDeleted'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(deleteLibraryItemsResolved({ itemIds }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorDeleteDocuments'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );

  getLibraryTriggers = createEffect(() =>
    this.actions$.pipe(
      ofType(getLibraryTriggers),
      mergeMap(({ callback, error }) =>
        this.api.getLibraryTriggers().pipe(
          tap((items) => (callback ? callback(items) : undefined)),
          mergeMap((items) => of(getLibraryTriggersResolved({ items }))),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open('Error loading library triggers', 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
}
