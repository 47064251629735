<div class="header_wrapper">
	<h2 mat-dialog-title>{{ title }}</h2>
	<mat-icon class="clickable" (click)="dialogRef.close()">close</mat-icon>
</div>

<div mat-dialog-content class="table-container">
	<div class="table-wrapper scrollbar horizontal-scrollbar">
		<table mat-table [dataSource]="dataSource" class="tableGeneral mat-elevation-z8">
		
			<!-- Version Label Column -->
			<ng-container matColumnDef="versionLabel">
			<th mat-header-cell *matHeaderCellDef>
				{{
					(translations.version | capitalizeFirst)
					+ ' ' + translations.label
				}}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.versionLabel }}</td>
			</ng-container>
		
			<!-- Created At Column -->
			<ng-container matColumnDef="createdAt">
			<th mat-header-cell *matHeaderCellDef>
				{{ translations.createdOn }}
			</th>
			<td mat-cell *matCellDef="let element">{{ element.createdAt | date:'MM/dd/yyyy HH:mm' }}</td>
			</ng-container>

			<!-- Actions Column -->
			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef class="actions_header">
					{{ translations.action }}
				</th>
				<td mat-cell *matCellDef="let element">
					<div mat-dialog-actions>
						<button
							mat-icon-button
							(click)="openVersion(element)"
							[disabled]="isOpeningSelectedDoc"
							matTooltip="{{ translations.open }}">
							<mat-icon class="material-icons-outlined">remove_red_eye</mat-icon>
						</button>
					
						<button
							mat-icon-button
							(click)="restoreVersion(element)"
							[disabled]="isRestoringVersion"
							matTooltip="{{ translations.restore }}">
							<mat-icon class="material-icons-outlined">history</mat-icon>
						</button>
					</div>
				</td>
			</ng-container>
		
			<!-- Header Row -->
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

			<!-- Data Rows -->
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

			<!-- No Data Row -->
			<tr class="no-data-row" *matNoDataRow>
			<td colspan="3" class="no-data-message">
				{{ isLoadingVersions ? translations.loadingData : translations.noVersions }}
			</td>
			</tr>
		</table>
	</div>
	<mat-paginator [pageSizeOptions]="[5, 10, 20]"></mat-paginator>
</div>