import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import {
  createSegment,
  createSegmentResolved,
  createTile,
  createTilePage,
  createTilePagePermission,
  createTilePagePermissionResolved,
  createTilePageResolved,
  createTileResolved,
  deleteSegment,
  deleteSegmentResolved,
  deleteTile,
  deleteTilePage,
  deleteTilePagePermissions,
  deleteTilePagePermissionsResolved,
  deleteTilePageResolved,
  deleteTileResolved,
  getTilePages,
  getTilePagesResolved,
  updateMetadataFilters,
  updateMetadataFiltersResolved,
  updateSegment,
  updateSegmentResolved,
  updateSegments,
  updateSegmentsResolved,
  updateTile,
  updateTilePage,
  updateTilePagePermissions,
  updateTilePagePermissionsResolved,
  updateTilePageResolved,
  updateTileResolved,
  updateTiles,
  updateTilesResolved,
} from './tilepage.actions';
import { translations$ } from '../../data/data.observables';

@Injectable({ providedIn: 'root' })
export class TilePageEffects {
  constructor(
    private actions$: Actions,
    private api: ApiService,
    private snackbar: MatSnackBar
  ) {}

  getTranslation(label: string): string {
    return translations$.value[label];
  }

  getTilePages = createEffect(() =>
    this.actions$.pipe(
      ofType(getTilePages),
      switchMap(({ callback, error }) =>
        this.api.getTilePages().pipe(
          tap((pages) => (callback ? callback(pages) : undefined)),
          switchMap((pages) => of(getTilePagesResolved({ pages }))),
          catchError((e) => {
            if (error) error(e);

            return [];
          })
        )
      )
    )
  );
  createTilePage = createEffect(() =>
    this.actions$.pipe(
      ofType(createTilePage),
      mergeMap(({ page, callback, error }) =>
        this.api.createTilePage(page).pipe(
          tap((page) => (callback ? callback(page) : undefined)),
          mergeMap((page) => {
            this.snackbar
              .open(this.getTranslation('tilePageCreated'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(createTilePageResolved({ page }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorCreateTilePage'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);

            return [];
          })
        )
      )
    )
  );
  updateTilePage = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTilePage),
      mergeMap(({ page, callback, error }) =>
        this.api.updateTilePage(page).pipe(
          tap((page) => (callback ? callback(page) : undefined)),
          mergeMap((page) => {
            this.snackbar
              .open(this.getTranslation('tilePageUpdated'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(updateTilePageResolved({ page }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorUpdatingTilePage'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  deleteTilePage = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTilePage),
      mergeMap(({ tilePageId, callback, error }) =>
        this.api.deleteTilePage(tilePageId).pipe(
          tap(() => (callback ? callback(tilePageId) : undefined)),
          mergeMap(() => {
            this.snackbar
              .open(this.getTranslation('tilePageDeleted'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(deleteTilePageResolved({ tilePageId }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorDeleteTilePage'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  createSegment = createEffect(() =>
    this.actions$.pipe(
      ofType(createSegment),
      mergeMap(({ segment, tilePageId, callback, error }) =>
        this.api.createSegment(segment, tilePageId).pipe(
          tap((segment) => (callback ? callback(segment) : undefined)),
          mergeMap((segment) => {
            this.snackbar
              .open(this.getTranslation('lineCreated'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(createSegmentResolved({ segment, tilePageId }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorCreatingLine'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  updateSegment = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSegment),
      mergeMap(
        ({ segment, tilePageIds, previousTilePageIds, callback, error }) =>
          this.api
            .updateSegment(segment, tilePageIds, previousTilePageIds)
            .pipe(
              tap((segment) => (callback ? callback(segment) : undefined)),
              mergeMap((segment) => {
                this.snackbar
                  .open(this.getTranslation('lineUpdated'), 'Ok', {
                    panelClass: 'app-notification-success',
                  })
                  ._dismissAfter(3000);
                return of(
                  updateSegmentResolved({
                    segment,
                    tilePageIds,
                    previousTilePageIds,
                  })
                );
              }),
              catchError((e) => {
                if (error) error(e);
                this.snackbar
                  .open(this.getTranslation('errorUpdatingLine'), 'Ok', {
                    panelClass: 'app-notification-error',
                  })
                  ._dismissAfter(3000);
                return [];
              })
            )
      )
    )
  );
  updateSegments = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSegments),
      mergeMap(({ segments, tilePageId, callback, error }) =>
        this.api.updateSegments(segments).pipe(
          tap((segments) => (callback ? callback(segments) : undefined)),
          mergeMap((segments) => {
            this.snackbar
              .open(this.getTranslation('linesUpdated'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(updateSegmentsResolved({ segments, tilePageId }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorUpdatingLines'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  deleteSegment = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSegment),
      mergeMap(({ segmentId, callback, error }) =>
        this.api.deleteSegment(segmentId).pipe(
          tap(() => (callback ? callback(segmentId) : undefined)),
          mergeMap(() => {
            this.snackbar
              .open(this.getTranslation('lineDeleted'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(deleteSegmentResolved({ segmentId }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorDeletingLine'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  createTile = createEffect(() =>
    this.actions$.pipe(
      ofType(createTile),
      mergeMap(({ tile, segmentId, callback, error }) =>
        this.api.createTile(tile, segmentId).pipe(
          tap((tile) => (callback ? callback(tile) : undefined)),
          mergeMap((tile) => {
            this.snackbar
              .open(this.getTranslation('tileCreated'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(createTileResolved({ tile, segmentId }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorCreatingTile'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  updateTile = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTile),
      mergeMap(({ tile, segmentIds, previousSegmentIds, callback, error }) =>
        this.api.updateTile(tile, segmentIds, previousSegmentIds).pipe(
          tap((tile) => (callback ? callback(tile) : undefined)),
          mergeMap((tile) => {
            this.snackbar
              .open(this.getTranslation('tileUpdated'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(
              updateTileResolved({ tile, segmentIds, previousSegmentIds })
            );
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorUpdatingTile'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  updateTiles = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTiles),
      mergeMap(({ tiles, callback, error }) =>
        this.api.updateTiles(tiles).pipe(
          tap((tiles) => (callback ? callback(tiles) : undefined)),
          mergeMap((tiles) => {
            this.snackbar
              .open(this.getTranslation('tileUpdated'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(updateTilesResolved({ tiles }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorUpdatingTiles'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  deleteTile = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTile),
      mergeMap(({ tileId, callback, error }) =>
        this.api.deleteTile(tileId).pipe(
          tap(() => (callback ? callback(tileId) : undefined)),
          mergeMap(() => {
            this.snackbar
              .open(this.getTranslation('tileDeleted'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(deleteTileResolved({ tileId }));
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorDeleteTile'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  createTilePagePermission = createEffect(() =>
    this.actions$.pipe(
      ofType(createTilePagePermission),
      mergeMap(({ permission, tilePageId, callback, error }) =>
        this.api.createTilePagePermission(permission, tilePageId).pipe(
          tap((permission) => (callback ? callback(permission) : undefined)),
          mergeMap((permission) =>
            of(createTilePagePermissionResolved({ permission, tilePageId }))
          ),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(
                this.getTranslation('errorCreateTilePagePermission'),
                'Ok',
                {
                  panelClass: 'app-notification-error',
                }
              )
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  updateTilePagePermissions = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTilePagePermissions),
      mergeMap(({ permissions, callback, error }) =>
        this.api.updateTilePagePermissions(permissions).pipe(
          tap((permissions) => (callback ? callback(permissions) : undefined)),
          mergeMap((permissions) =>
            of(updateTilePagePermissionsResolved({ permissions }))
          ),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(
                this.getTranslation('errorUpdateTilePagePermission'),
                'Ok',
                {
                  panelClass: 'app-notification-error',
                }
              )
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );
  deleteTilePagePermissions = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTilePagePermissions),
      mergeMap(({ permissionIds, callback, error }) =>
        this.api.deleteTilePagePermissions(permissionIds).pipe(
          mergeMap(() =>
            of(deleteTilePagePermissionsResolved({ permissionIds }))
          ),
          tap(() => (callback ? callback(permissionIds) : undefined)),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(
                this.getTranslation('errorDeleteTilePagePermission'),
                'Ok',
                {
                  panelClass: 'app-notification-error',
                }
              )
              ._dismissAfter(3000);
            return [];
          })
        )
      )
    )
  );

  updateMetadataFilter = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMetadataFilters),
      mergeMap(({ tileId, previousFilters, filters, callback, error }) => {
        const toCreate = filters.filter((m) => !m.id);
        const toUpdate = filters.filter((m) =>
          previousFilters.some((p) => p.id == m.id)
        );
        const toDelete = previousFilters.filter(
          (m) => !filters.some((p) => p.id == m.id)
        );
        return forkJoin([
          toCreate.length ? this.api.createMetadataFilter(toCreate) : of([]),
          toUpdate.length ? this.api.updateMetadataFilter(toUpdate) : of([]),
          toDelete.length
            ? this.api.deleteMetadataFilter(toDelete.map((d) => d.id))
            : of(true),
        ]).pipe(
          tap(([created, updated]) =>
            callback ? callback([...created, ...updated]) : undefined
          ),
          switchMap(([created, updated]) => {
            this.snackbar
              .open(this.getTranslation('filterUpdated'), 'Ok', {
                panelClass: 'app-notification-success',
              })
              ._dismissAfter(3000);
            return of(
              updateMetadataFiltersResolved({
                tileId,
                filters: [...created, ...updated],
              })
            );
          }),
          catchError((e) => {
            if (error) error(e);
            this.snackbar
              .open(this.getTranslation('errorUpdateFilter'), 'Ok', {
                panelClass: 'app-notification-error',
              })
              ._dismissAfter(3000);
            return [];
          })
        );
      })
    )
  );
}
