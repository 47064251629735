import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';

import { environment } from './environments/environment';

import {
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom, inject } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { EffectsModule, provideEffects } from '@ngrx/effects';
import { StoreModule, provideStore } from '@ngrx/store';
import {
  StoreDevtoolsModule,
  provideStoreDevtools,
} from '@ngrx/store-devtools';
import {
  APP_STARTUP,
  ISHTAR365_APP_IDENTIFIER,
  ISHTAR365_CLIENTID,
  ISHTAR365_FUNCTIONS_SCOPE,
  ISHTAR365_FUNCTIONS_URL,
  ISHTAR365_INITIAL_ROUTE,
  ISHTAR365_INSTANCE,
  ISHTAR365_LAST_ROUTE_KEY,
  ISHTAR365_URL,
  MsalModule,
  SessionExpiredModule,
  microsoftAuthenticationInterceptor,
} from 'processdelight-angular-components';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { StartUpService } from './app/core/services/startup.service';
if (environment.production) enableProdMode();
bootstrapApplication(AppComponent, {
  providers: [
    { provide: ISHTAR365_CLIENTID, useValue: environment.clientId },
    { provide: ISHTAR365_INSTANCE, useValue: environment.instance },
    { provide: ISHTAR365_URL, useValue: environment.ishtar365 },
    { provide: ISHTAR365_APP_IDENTIFIER, useFactory: () => location.origin },
    { provide: ISHTAR365_FUNCTIONS_URL, useValue: environment.ishtarFunctions },
    {
      provide: ISHTAR365_FUNCTIONS_SCOPE,
      useValue: environment.ishtarFunctionsScope,
    },
    { provide: ISHTAR365_LAST_ROUTE_KEY, useValue: environment.lastRouteKey },
    { provide: ISHTAR365_INITIAL_ROUTE, useValue: ['page'] },
    {
      provide: APP_STARTUP,
      useFactory: () => {
        const service = inject(StartUpService);
        return () => service.boot();
      },
    },
    provideRouter(routes),
    provideStore({}),
    provideEffects([]),
    provideStoreDevtools({ logOnly: false }),
    provideHttpClient(
      withInterceptors([microsoftAuthenticationInterceptor('')])
    ),
    importProvidersFrom(
      StoreModule.forRoot({}),
      EffectsModule.forRoot([]),
      StoreDevtoolsModule.instrument({ logOnly: environment.production }),
      MsalModule,
      MatNativeDateModule,
      SessionExpiredModule,
      HttpClientModule
    ),
    provideAnimations(),
  ],
});
