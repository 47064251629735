import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  AdditionUnit,
  ChannelType,
  Frequency,
  GroupUser,
  Ishtar365CommunicationService,
  Language,
  LoaderService,
  MicrosoftAuthenticationService,
  Ranking,
  SignalRService,
  TriggerType,
} from 'processdelight-angular-components';
import {
  Observable,
  Subject,
  catchError,
  delay,
  filter,
  first,
  forkJoin,
  interval,
  map,
  of,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import {
  additionUnits$,
  channelTypes$,
  config$,
  days$,
  frequencies$,
  groups$,
  languages$,
  license$,
  months$,
  portalGroups$,
  rankings$,
  taskTemplates$,
  translations$,
  triggerTypes$,
  userSettings$,
  users$,
} from '../data/data.observables';
import { AppConfig } from '../domain/models/app-config.model';
import { Day } from 'processdelight-angular-components';
import { Month } from 'processdelight-angular-components';
import { AppComponent } from 'src/app/app.component';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';
import { UserSettings } from '../domain/models/user-settings.model';
import { LibraryFacade } from '../store/library/library.facade';
import { MetadataFacade } from '../store/metadata/metadata.facade';
import { NotificationFacade } from '../store/notification/notification.facade';
import { TilePageFacade } from '../store/tilepage/tilepage.facade';
import { ApiService } from './api.service';
import { RouteBuilderService } from './route-builder.service';
import { StartServiceBusReceiver } from './servicebus-receiver';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class StartUpService implements OnDestroy {
  destroy$ = new Subject<void>();
  constructor(
    private store: Store<AppState>,
    private api: ApiService,
    private routeBuilder: RouteBuilderService,
    private tilepageFacade: TilePageFacade,
    private metadataFacade: MetadataFacade,
    private libraryFacade: LibraryFacade,
    private notificationFacade: NotificationFacade,
    private loader: LoaderService,
    private router: Router,
    private msal: MicrosoftAuthenticationService,
    private ishtarCommunicationService: Ishtar365CommunicationService,
    private signalR: SignalRService
  ) {}
  getLicense(): Observable<UserLicenseInfo> {
    return this.loader.startLoading$('Retrieving license information', () =>
      this.api.getLicense(this.msal.tenantId).pipe(
        tap((data) => license$.next(data)),
        catchError((error) => {
          this.router.navigate(['/401']);
          throw error;
        })
      )
    );
  }
  getUsers(): Observable<GroupUser[]> {
    return this.loader.startLoading$('Retrieving users', () =>
      this.api.getUsers().pipe(
        switchMap((data) =>
          data.length
            ? of(data).pipe(tap((data) => users$.next(data)))
            : interval(100).pipe(
                first(),
                switchMap(() => this.getUsers())
              )
        ),
        catchError(() =>
          interval(100).pipe(
            first(),
            switchMap(() => this.getUsers())
          )
        )
      )
    );
  }
  getGroups(): Observable<GroupUser[]> {
    return this.loader.startLoading$('Retrieving groups', () =>
      this.api.getGroups().pipe(
        tap((data) => {
          groups$.next(data);
        })
      )
    );
  }
  getConfig(): Observable<AppConfig> {
    return this.loader.startLoading$('Retrieving configuration', () =>
      this.api.getConfiguration().pipe(tap((data) => config$.next(data)))
    );
  }
  getUserSettings(): Observable<UserSettings> {
    return this.loader.startLoading$('Retrieving user settings', () =>
      this.api.getUserSettings().pipe(tap((data) => userSettings$.next(data)))
    );
  }
  getLanguages(): Observable<Language[]> {
    return this.api.getLanguages().pipe(tap((data) => languages$.next(data)));
  }
  getDays(): Observable<Day[]> {
    return this.api.getDays().pipe(tap((data) => days$.next(data)));
  }
  getMonths(): Observable<Month[]> {
    return this.api.getMonths().pipe(tap((data) => months$.next(data)));
  }
  getRankings(): Observable<Ranking[]> {
    return this.api.getRankings().pipe(tap((data) => rankings$.next(data)));
  }
  getFrequencies(): Observable<Frequency[]> {
    return this.api
      .getNotificationFrequencies()
      .pipe(tap((data) => frequencies$.next(data)));
  }
  getTriggerTypes(): Observable<TriggerType[]> {
    return this.api
      .getNotificationTriggerTypes()
      .pipe(tap((data) => triggerTypes$.next(data)));
  }
  getAdditionUnits(): Observable<AdditionUnit[]> {
    return this.api
      .getNotificationAdditionUnits()
      .pipe(tap((data) => additionUnits$.next(data)));
  }
  getChannelTypes(): Observable<ChannelType[]> {
    return this.api
      .getNotificationChannelTypes()
      .pipe(tap((data) => channelTypes$.next(data)));
  }
  getPortalGroups() {
    return this.api
      .getPortalGroups()
      .pipe(tap((data) => portalGroups$.next(data)));
  }

  getTranslations() {
    return this.api
      .getTranslations()
      .pipe(tap((data) => translations$.next(data)));
  }
  getTemplates() {
    return this.api
      .getTaskTemplates()
      .pipe(tap((data) => taskTemplates$.next(data)));
  }
  getTranslation(label: string): string {
    return translations$.value[label];
  }
  boot(): Observable<unknown> {
    timer(15 * 60 * 1000, 15 * 60 * 1000)
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.msal.signedIn.value),
        switchMap(() => this.api.sessionKeepAlive())
      )
      .subscribe();
    return this.getLicense().pipe(
      filter((license) => !!license),
      first(),
      switchMap((license) => {
        if (
          !license?.licenses.some(
            (l) => l.productName == AppComponent.domainName
          )
        ) {
          this.router.navigate(['/401']);
          throw new Error(this.getTranslation('errorLicenseApp'));
        }
        try {
          this.ishtarCommunicationService.init();
        } catch {
          // not in Ishtar.365
        }
        return this.getTranslations().pipe(
          filter((t) => !!Object.keys(t).length),
          first(),
          map(() => license)
        );
      }),
      switchMap((license) => {
        const tasks: Observable<unknown>[] = [
          this.getLanguages(),
          this.getUsers(),
          this.getGroups(),
          this.getConfig(),
          this.getUserSettings(),
          this.loader.startLoading$(
            this.getTranslation('retrieveTilePages'),
            () => this.tilepageFacade.getTilePages$()
          ),
          this.loader.startLoading$(
            this.getTranslation('retrieveMetataParameters'),
            () =>
              forkJoin([
                this.metadataFacade.getMetadataParams$(),
                this.metadataFacade.getMetadataPermissionConfigurations$(),
              ])
          ),
          this.loader.startLoading$(
            this.getTranslation('retrieveLibraryDetails'),
            () => this.libraryFacade.getLibraries$()
          ),
          license.licenses.some(
            (l) => l.productName == AppComponent.domainName && l.isAdmin
          )
            ? this.metadataFacade.getAdminMetadataParams$()
            : of(true),
        ];
        if (
          license?.licenses.some(
            (l) => l.productName === AppComponent.IshtarTasksAppName
          )
        )
          this.getTemplates().subscribe();

        return forkJoin(tasks);
      }),
      delay(10),
      tap(() => {
        if (
          license$.value?.licenses.some(
            (l) => l.productName == 'Ishtar.DMS' && l.isAdmin
          )
        ) {
          this.getAdditionUnits().subscribe();
          this.getTriggerTypes().subscribe();
          this.getFrequencies().subscribe();
          this.getDays().subscribe();
          this.getMonths().subscribe();
          this.getRankings().subscribe();
          this.getChannelTypes().subscribe();
          this.getPortalGroups().subscribe();
          this.notificationFacade.getNotifications$().subscribe();
          this.metadataFacade.getDMSWebpartPermissions$().subscribe();
          StartServiceBusReceiver(this.signalR, this.store);
        }
      }),
      switchMap(() => this.routeBuilder.initRoutes())
    );
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
