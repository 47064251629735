import { BaseEntity } from 'processdelight-angular-components';
import { LibraryItem } from './item.model';

export class LinkedItem extends BaseEntity {
  dmsItemId?: string;
  linkedItemId?: string;
  dmsItem?: LibraryItem;
  linkedItem?: LibraryItem;

  constructor(obj: Partial<LinkedItem>) {
    super(obj);
    Object.assign(this, obj);
    if (obj.dmsItem) this.dmsItem = new LibraryItem(obj.dmsItem);
    if (obj.linkedItem) this.linkedItem = new LibraryItem(obj.linkedItem);
  }
}
