import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { DomSanitizer } from '@angular/platform-browser';
import { MicrosoftAuthenticationService } from 'processdelight-angular-components';
import { combineLatest, first, map } from 'rxjs';
import { translations$ } from 'src/app/core/data/data.observables';
import { PermissionType } from 'src/app/core/domain/enums/permission-type.enum';
import { LibraryItem } from 'src/app/core/domain/models/item.model';
import { Library } from 'src/app/core/domain/models/library.model';
import { Permission } from 'src/app/core/domain/models/permission.model';
import { LibraryIconService } from 'src/app/core/services/library-icon.service';
import { LibraryFacade } from 'src/app/core/store/library/library.facade';
import { MetadataFacade } from 'src/app/core/store/metadata/metadata.facade';
import { TilePageFacade } from 'src/app/core/store/tilepage/tilepage.facade';

enum MoveItemError {
  NoPermissions,
  AlreadyInLibrary,
}

@Component({
  selector: 'app-move-items-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatListModule,
  ],
  templateUrl: './move-items-dialog.component.html',
  styleUrls: ['./move-items-dialog.component.scss'],
})
export class MoveItemsDialogComponent implements OnInit {
  confirmAction!: (items: LibraryItem[]) => void;
  library!: Library;

  items: LibraryItem[] = [];
  erroredItems: { item: LibraryItem; error: MoveItemError }[] = [];

  fileNameMap: { [key: string]: string } = {};

  constructor(
    public dialogRef: MatDialogRef<MoveItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      confirmAction: (items: LibraryItem[]) => void;
      library: Library;
      items: LibraryItem[];
    },
    private readonly libraryFacade: LibraryFacade,
    private readonly tilePageFacade: TilePageFacade,
    private readonly metadataFacade: MetadataFacade,
    private readonly msal: MicrosoftAuthenticationService,
    private readonly libraryIconService: LibraryIconService,
    private readonly domSanitizer: DomSanitizer
  ) {}

  getTranslation$(label: string) {
    return translations$.pipe(map((t) => t[label]));
  }

  getErroredDocumentsTranslation$() {
    return this.getTranslation$('moveDocumentsError').pipe(
      map((t) => t.replace('{{length}}', this.erroredItems.length.toString()))
    );
  }

  getMoveDocumentsConfirmationMessageTranslation$() {
    return this.getTranslation$('moveDocumentsConfirmationMessage').pipe(
      map((t) =>
        t
          .replace('{{length}}', this.items.length.toString())
          .replace('{{library}}', this.library.title)
      )
    );
  }

  ngOnInit(): void {
    this.confirmAction = this.data.confirmAction;
    this.library = this.data.library;
    combineLatest([
      this.libraryFacade.libraries$.pipe(first()),
      this.tilePageFacade.homePage$.pipe(first()),
      this.metadataFacade.fileNameParam$.pipe(first()),
    ]).subscribe(([libs, homepage, fileNameParam]) => {
      this.data.items.forEach((item) => {
        this.fileNameMap[item.id] =
          item.metadata.find((m) => m.metadataParameter.id == fileNameParam?.id)
            ?.value ?? '';
        let permissions: Permission[] = [];
        if (item.library)
          permissions =
            libs
              .find((l) => l.id == item.library?.id)
              ?.permissions.map((p) => p.permission) ?? [];
        else permissions = homepage?.permissions.map((p) => p.permission) ?? [];
        if (item.library?.id == this.library.id) {
          this.erroredItems.push({
            item,
            error: MoveItemError.AlreadyInLibrary,
          });
        } else if (
          (!permissions.length ||
            permissions.some(
              (p) =>
                (p.groupUser.user?.id == this.msal.userId ||
                  p.groupUser.group?.members?.some(
                    (m) => m.id == this.msal.userId
                  )) &&
                p.permissionType != PermissionType.Read
            )) &&
          (!this.library.permissions.length ||
            this.library.permissions.some(
              ({ permission }) =>
                (permission.groupUser.user?.id == this.msal.userId ||
                  permission.groupUser.group?.members?.some(
                    (m) => m.id == this.msal.userId
                  )) &&
                permission.uploadPermission
            ))
        ) {
          this.items.push(item);
        } else {
          this.erroredItems.push({
            item,
            error: MoveItemError.NoPermissions,
          });
        }
      });
    });
  }

  getIcon(item: LibraryItem) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.libraryIconService.provideIcon(item.fileLocation)!
    );
  }

  confirm() {
    this.confirmAction(this.items);
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
