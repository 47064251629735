import { inject } from '@angular/core';
import { Router, Routes } from '@angular/router';
import {
  MicrosoftAuthenticationGuard,
  signinRedirectGuard,
} from 'processdelight-angular-components';
import { map } from 'rxjs';
import { license$ } from './core/data/data.observables';
import { AppComponent } from './app.component';

export const appRoutes: Routes = [
  {
    path: 'shared',
    loadComponent: () =>
      import('./page/library/shared-items/shared-items.component').then(
        (m) => m.SharedItemsComponent
      ),
    canActivate: [MicrosoftAuthenticationGuard],
    data: {
      breadcrumbTitle: 'Shared items',
    },
  },
  {
    path: 'settings',
    loadComponent: () => import('./settings/settings.component'),
    loadChildren: () =>
      import('./settings/settings.component').then(
        (settings) => settings.settingsRoutes
      ),
    canActivate: [
      MicrosoftAuthenticationGuard,
      () => {
        const router = inject(Router);
        return license$.pipe(
          map((l) =>
            l?.licenses.some(
              (x) => x.productName == AppComponent.domainName && x.isAdmin
            )
              ? true
              : router.createUrlTree(['page'])
          )
        );
      },
    ],
  },
];

export const routes: Routes = [
  {
    path: '',
    canActivate: [signinRedirectGuard],
    loadChildren: () =>
      import('processdelight-angular-components').then((m) => m.MsalModule),
  },
  {
    path: '404',
    loadComponent: () =>
      import('./core/components/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  },
  {
    path: '**',
    redirectTo: '',
  },
];
