import { Day } from 'processdelight-angular-components/lib/domain/models/day';
import { Month } from 'processdelight-angular-components/lib/domain/models/month';
import { AppComponent } from 'src/app/app.component';
import { BehaviorSubject, Subject, combineLatest, map } from 'rxjs';
import { AppConfig } from '../domain/models/app-config.model';
import { UserLicenseInfo } from '../domain/models/user-license-info.model';
import { UserSettings } from '../domain/models/user-settings.model';
import {
  AdditionUnit,
  ChannelType,
  Frequency,
  GroupUser,
  Language,
  Lookup,
  Ranking,
  TriggerType,
} from 'processdelight-angular-components';
import { PortalGroup } from '../domain/models/portal-group.model';
import { InterestGroup } from '../domain/models/interest-group.model';

export const license$ = new BehaviorSubject<UserLicenseInfo | undefined>(
  undefined
);
export const users$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const groups$ = new BehaviorSubject<GroupUser[] | undefined>(undefined);
export const config$ = new BehaviorSubject<AppConfig>(new AppConfig({}));
export const userSettings$ = new BehaviorSubject<UserSettings>(
  new UserSettings({})
);
export const languages$ = new BehaviorSubject<Language[]>([]);
export const translations$ = new BehaviorSubject<any>({});

export const taskTemplates$ = new BehaviorSubject<Lookup[]>([]);

export const groupUsers$ = combineLatest([groups$, users$]).pipe(
  map(([groups, users]) => (groups ?? []).concat(users ?? []))
);

export const groupUsersFiltered$ = combineLatest([
  config$,
  groups$,
  users$,
]).pipe(
  map(([config, groups, users]) =>
    config.onlyGroups
      ? groups
      : [...(groups ?? ([] as GroupUser[]))]
          .sort((g1, g2) =>
            (g1.displayName ?? '').localeCompare(g2.displayName ?? '')
          )
          .concat(
            [...(users ?? ([] as GroupUser[]))].sort((u1, u2) =>
              (u1.displayName ?? '').localeCompare(u2.displayName ?? '')
            )
          )
  )
);

export const isAdmin$ = license$.pipe(
  map(
    (l) =>
      l?.licenses.some(
        (l) => l.productName == AppComponent.domainName && l.isAdmin
      ) ?? false
  )
);

export const days$ = new BehaviorSubject<Day[]>([]);
export const months$ = new BehaviorSubject<Month[]>([]);
export const rankings$ = new BehaviorSubject<Ranking[]>([]);
export const frequencies$ = new BehaviorSubject<Frequency[]>([]);
export const triggerTypes$ = new BehaviorSubject<TriggerType[]>([]);
export const additionUnits$ = new BehaviorSubject<AdditionUnit[]>([]);
export const channelTypes$ = new BehaviorSubject<ChannelType[]>([]);

export const portalGroups$ = new BehaviorSubject<PortalGroup[]>([]);
export const interestGroups$ = new BehaviorSubject<InterestGroup[]>([]);

export const externallyUpdatedLibraryItems$ = new Subject<string[]>();
