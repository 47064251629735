import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { map } from 'rxjs';
import { MetadataChoiceTranslation } from '../../domain/models/metadata-choice-translation.model';
import { MetadataChoice } from '../../domain/models/metadata-choice.model';
import { MetadataParam } from '../../domain/models/metadata-param.model';
import { BaseFacade } from '../base.facade';
import {
  createDMSWebpartInterestGroups,
  createDMSWebpartPermissions,
  createMetadataChoice,
  createMetadataChoiceTranslations,
  createMetadataParam,
  deleteDMSWebpartInterestGroups,
  deleteDMSWebpartPermissions,
  deleteMetadataChoice,
  deleteMetadataParam,
  getAdminMetadataParams,
  getDMSWebpartPermissions,
  getMetadataParams,
  getMetadataPermissionConfigurations,
  resetSlice,
  updateMetadataChoice,
  updateMetadataChoices,
  updateMetadataParam,
  updateMetadataPermissionConfiguration,
} from './metadata.actions';
import { metadataSelectors } from './metadata.selectors';
import { MetadataPermissionConfiguration } from '../../domain/models/metadata-permission-configuration.model';
import { DMSWebpartPermission } from '../../domain/models/dms-webpart-permission.model';
import { getDMSWebpartInterestGroups } from './metadata.actions';
import { DMSWebpartInterestGroup } from '../../domain/models/dms-webpart-interestgroup.model';

@Injectable({
  providedIn: 'root',
})
export class MetadataFacade extends BaseFacade {
  metadataParams$ = this._store.pipe(
    select(metadataSelectors.getMetadataParams),
    map((params) =>
      params.map(
        (param) =>
          new MetadataParam({
            ...param,
            choices: param.choices ? [...param.choices] : [],
          })
      )
    )
  );
  adminMetadataParams$ = this._store.pipe(
    select(metadataSelectors.getAdminMetadataParams)
  );
  fixedParams$ = this._store.pipe(select(metadataSelectors.getFixedParams));
  fileNameParam$ = this._store.pipe(select(metadataSelectors.getFileNameParam));

  getMetadataPermissionConfigurationsByParamId$ = (paramId: string) =>
    this._store.pipe(
      select(metadataSelectors.getMetadataPermissionConfigurationsByParamId),
      map((fn) => fn(paramId))
    );

  permissionCheckFn$ = this._store.pipe(
    select(metadataSelectors.permissionCheckFn)
  );

  dmsWebpartPermissions$ = this._store.pipe(
    select(metadataSelectors.getDMSWebpartPermissions)
  );
  dmsWebpartInterestGroups$ = this._store.pipe(
    select(metadataSelectors.getDMSWebpartInterestGroups)
  );

  getMetadataParams$() {
    return this.dispatchAction$(getMetadataParams);
  }
  getAdminMetadataParams$() {
    return this.dispatchAction$(getAdminMetadataParams);
  }

  createMetadataParam$(metadataParam: MetadataParam) {
    return this.dispatchAction$(createMetadataParam, { metadataParam });
  }
  updateMetadataParam$(metadataParam: MetadataParam) {
    return this.dispatchAction$(updateMetadataParam, { metadataParam });
  }
  deleteMetadataParam(metadataParamId: string) {
    this._store.dispatch(
      deleteMetadataParam({
        metadataParamId,
      })
    );
  }
  createMetadataChoice$(metadataChoice: MetadataChoice) {
    return this.dispatchAction$(createMetadataChoice, { metadataChoice });
  }
  updateMetadataChoice$(metadataChoice: MetadataChoice) {
    return this.dispatchAction$(updateMetadataChoice, { metadataChoice });
  }
  updateMetadataChoices$(metadataChoices: MetadataChoice[]) {
    return this.dispatchAction$(updateMetadataChoices, { metadataChoices });
  }
  createMetadataChoiceTranslations$(
    paramId: string,
    translations: MetadataChoiceTranslation[]
  ) {
    return this.dispatchAction$(createMetadataChoiceTranslations, {
      paramId,
      translations,
    });
  }

  deleteMetadataChoice$(metadataParamId: string, metadataChoiceId: string) {
    return this.dispatchAction$(deleteMetadataChoice, {
      metadataParamId,
      metadataChoiceId,
    });
  }

  getMetadataPermissionConfigurations$() {
    return this.dispatchAction$(getMetadataPermissionConfigurations);
  }

  updateMetadataPermissionConfiguration$(
    configuration: MetadataPermissionConfiguration
  ) {
    return this.dispatchAction$(updateMetadataPermissionConfiguration, {
      configuration,
    });
  }

  getDMSWebpartPermissions$() {
    return this.dispatchAction$(getDMSWebpartPermissions);
  }
  createDMSWebpartPermissions$(permissions: DMSWebpartPermission[]) {
    return this.dispatchAction$(createDMSWebpartPermissions, {
      permissions,
    });
  }
  deleteDMSWebpartPermissions$(permissionIds: string[]) {
    return this.dispatchAction$(deleteDMSWebpartPermissions, {
      permissionIds,
    });
  }
  getDMSWebpartInterestGroups$() {
    return this.dispatchAction$(getDMSWebpartInterestGroups);
  }
  createDMSWebpartInterestGroups$(interestGroups: DMSWebpartInterestGroup[]) {
    return this.dispatchAction$(createDMSWebpartInterestGroups, {
      interestGroups,
    });
  }
  deleteDMSWebpartInterestGroups$(interestGroupIds: string[]) {
    return this.dispatchAction$(deleteDMSWebpartInterestGroups, {
      interestGroupIds,
    });
  }

  resetSlice() {
    this._store.dispatch(resetSlice());
  }
}
